import {Box, Card, CardBody, CardFooter, CardHeader, Flex} from '@chakra-ui/react';
import React, {useState} from 'react';
import {sendAudioHover} from '../../vuplex';
import Landscape from './Landscape';
import _ from 'lodash';

const WorldCardBlank = ({
  children,
  contentGap,
  contentHeight = '8em',
  footerContent,
  headerContent,
  height = '10em',
  hoverContent,
  isHelpHighlighted,
  landscapeHeight = 100,
  landscapeTop = '90px',
  onClick,
  world,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const bg = world.isLobby
    ? '#8dbbdb'
    : world.rules.isWitw
      ? 'playerPink'
      : 'explorerBlue';

  return (
    <>
      <Card
        _hover={{
          filter: `brightness(0.8)`,
        }}
        bg={bg}
        borderRadius="2xl"
        boxShadow={isHelpHighlighted ? '0px 0px 0px 8px #FFF155' : null}
        height={height}
        onClick={onClick}
        onPointerEnter={(e) => {
          sendAudioHover(e);
          setIsHovering(true);
        }}
        onPointerLeave={() => {
          setTimeout(() => setIsHovering(false), 100);
        }}
        overflow="hidden"
      >
        {/* Landscape Image */}
        <Box
          bg={bg}
          position="absolute"
          top={landscapeTop}
          w="full"
        >
          <Landscape
            height={landscapeHeight}
            isLobby={world.isLobby}
            isWitw={_.get(world, 'rules.isWitw')}
            name={world.name}
            width="100%"
          />
        </Box>

        <CardHeader p={1}>
          {headerContent}
        </CardHeader>

        <CardBody p={2} position="relative">
          {isHovering && hoverContent
            ? hoverContent
            : children}
        </CardBody>

        <CardFooter
          gap={4}
          p={2}
          paddingBottom={4}
          justifyContent="center"
        >
          {footerContent}
        </CardFooter>
      </Card>
    </>
  );


};

export default WorldCardBlank;
