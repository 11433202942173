import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Goood from './routes/Goood/Goood';
import Promotion from './routes/Promotion';
import WelcomePage from './routes/WelcomePage';
import WooorldInApp from './routes/WooorldInApp/WooorldInApp';

const App = () => ( // https://reactrouter.com/en/main
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<WelcomePage/>}/>
      <Route path="/promo" element={<Promotion/>}/>
      <Route path="/goood/*" element={<Goood/>}/>
      <Route path="/wooorld/*" element={<WooorldInApp/>}/>
    </Routes>
  </BrowserRouter>
);

export default App;
