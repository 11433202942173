import {Flex} from '@chakra-ui/react';
import React from 'react';
import {GiRoad} from 'react-icons/gi';
import {LuMountainSnow} from 'react-icons/lu';
import {MdOutlineSatellite} from 'react-icons/md';
import ButtonCircleIcon from '../../../components/ButtonCircleIcon';
import {mapStyles} from '../../../hooks/usePermissions';

export const mapTypes = { // https://developers.google.com/maps/documentation/javascript/maptypes#BasicMapTypes
  hybrid: 'hybrid',
  roadmap: 'roadmap',
  satellite: 'satellite ',
  terrain: 'terrain',
};

const MapTypeButton = ({icon, onClick, isSelected, permission, tooltip}) => (
  <ButtonCircleIcon
    icon={icon}
    isSelected={isSelected}
    onClick={onClick}
    permission={permission}
    shadow="dark-lg"
    tooltip={tooltip}
  />
);

const MinimapMapTypes = ({ children, mapTypeId, onMapTypeIdChange}) => {

  return (
    <Flex
      gap={2}
      marginRight={4}
      marginTop={3}
    >
      {children}
      <MapTypeButton
        icon={<MdOutlineSatellite size="1.5em"/>}
        isSelected={mapTypeId === mapTypes.hybrid}
        onClick={(e) => {
          onMapTypeIdChange(mapTypes.hybrid);
        }}
        permission={mapStyles}
        tooltip="Aerial Imagery"
      />
      <MapTypeButton
        icon={<LuMountainSnow size="1.5em"/>}
        isSelected={mapTypeId === mapTypes.terrain}
        onClick={(e) => {
          onMapTypeIdChange(mapTypes.terrain);
        }}
        permission={mapStyles}
        tooltip="Terrain"
      />
      <MapTypeButton
        icon={<GiRoad size="1.5em"/>}
        isSelected={mapTypeId === mapTypes.roadmap}
        onClick={(e) => {
          onMapTypeIdChange(mapTypes.roadmap);
        }}
        tooltip="Symbolic"
      />
    </Flex>
  );

};

export default MinimapMapTypes;
