import {Flex, Image} from '@chakra-ui/react';
import explorerIcon from '../../assets/freemium/Explorer + Pack Icon Simple.png';
import lock from '../../assets/freemium/lock.png';
import playerIcon from '../../assets/freemium/Player + Pack Icon Simple.png';
import {sendAudioClick, sendTooltip, sendTooltipOff, sendUpgrade} from '../../vuplex';

const UpgradeButton = ({children, descriptor, packageName, ...flexProps}) => {
  const h = '1.8em';
  const tooltip = `Upgrade to unlock`;

  const borderColor = packageName === 'explorer+' ? '#3DC5FF' : '#FF4DCD';
  const gradientColor = packageName === 'explorer+' ? '#1f343d' : '#36203D';
  const glowColor = packageName === 'explorer+' ? '#3DC5FF' : '#E441FF';
  return (
    <Flex
      {...flexProps}
      alignItems="center"
      bgGradient={`linear(0deg, ${gradientColor}, #7A7A7A)`}
      boxShadow={`0px 0px 20px 0px ${glowColor}`}
      borderRadius="3xl"
      border="solid 2px"
      borderColor={borderColor}
      // backgroundImage={`url('${upgradePillBg}')`}
      // backgroundPosition="center"
      // backgroundRepeat="no-repeat"
      // backgroundSize="100% 80%"
      color="white"
      p={1}
      onClick={(evt) => {
        sendUpgrade({
          descriptor,
          packageName,
        });
        sendAudioClick(evt);
      }}
      onPointerEnter={(evt) => {
        const {clientX, clientY} = evt;
        sendTooltip({
          message: tooltip,
          clientX,
          clientY,
        });
      }}
      onPointerMove={(evt) => {
        const {clientX, clientY} = evt;
        sendTooltip({
          message: tooltip,
          clientX,
          clientY,
        });
      }}
      onPointerLeave={(evt) => {
        sendTooltipOff();
      }}
    >
      <Image src={lock} h={h}/>
      {children}
      <Image src={packageName === 'explorer+' ? explorerIcon : playerIcon} h={h}/>
    </Flex>
  );
};

export default UpgradeButton;
