import {Box} from '@chakra-ui/react';
import {APIProvider} from '@vis.gl/react-google-maps';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {sendDragWithinPage} from '../../../vuplex';
import PageTitle from '../PageTitle';
import {MapRenderer} from './MapRenderer';

const Minimap = ({}) => {
  const googleApiKey = useSelector(s => s.inApp.googleApiKey);

  useEffect(sendDragWithinPage, []);

  return (
    <>
      <PageTitle title="Minimap - Wooorld"/>
      {googleApiKey && (
        <Box
          h="100vh"
          w="100vw"
          sx={{
            '[role="dialog"] a': { // hide the "View on Google Maps" link
              display: 'none',
            }
          }}
        >
          <APIProvider apiKey={googleApiKey}>
            <MapRenderer/>
          </APIProvider>
        </Box>
      )}
    </>
  );
};

export default Minimap;
