import {CloseIcon, SearchIcon} from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import {keyframes} from '@emotion/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {FaMicrophone} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {disabledPremiumStripesBgLight} from '../../helpers/styles';
import {useEnterKey} from '../../hooks/useEnterKey';
import {usePermissions} from '../../hooks/usePermissions';
import {voiceInputClear, voiceInputRequest} from '../../reducers/inAppReducer';
import {textInputFieldClear, textInputFieldSet} from '../../reducers/uiReducer';
import {sendAudioClick, sendAudioHover, sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import InAppTooltip from './UnityReactComponents/InAppTooltip';

const InputBar = ({
  isAi,
  isAutofocus,
  isDisabled,
  contentLeft,
  contentRight,
  children,
  iconLeft = <SearchIcon/>,
  isBgTransparent,
  isMinimal,
  paddingX = 0,
  permission,
  permissionTooltip,
  stateKey,
  top = '-2px',
}) => {
  const {
    textInputField,
    textInputPlaceholder,
  } = useSelector(({ui}) => ui[stateKey]);
  const {voiceInput} = useSelector(({inApp}) => inApp);
  const [isAwaitingVoice, setIsAwaitingVoice] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  const hasPermission = usePermissions(permission);

  useEffect(() => {
    const {
      error,
      isLoading,
      response,
    } = voiceInput;

    if (!isAwaitingVoice) return; // prevent other inputBar from using this voiceInput

    if (error) {
      dispatch(textInputFieldClear({key: stateKey}));
      dispatch(voiceInputClear());
      toast({
        description: `I didn't catch that. Please try again.`,
        title: '🙉',
        status: 'error',
      });
      setIsAwaitingVoice(false);
    } else if (isLoading) {
      dispatch(textInputFieldClear({key: stateKey}));
    } else if (response) {
      dispatch(textInputFieldSet({key: stateKey, val: _.replace(response, '.', '')}));
      setIsAwaitingVoice(false);
    }
  }, [isAwaitingVoice, voiceInput]);

  const handleClickVoiceInput = (evt) => {
    if (!hasPermission || voiceInput.isLoading) return; // not using isDisabled here to ensure color match with left icon
    sendAudioClick(evt);
    dispatch(voiceInputRequest());
    setIsAwaitingVoice(true);
  };

  const pulse = keyframes`
    from {
      color: darkred;
    }
    to {
      color: red;
    }
  `;

  useEnterKey(/*blur textInput*/);

  return ( // TODO: extract Input from stickyTop
    <Flex
      bg={isBgTransparent ? 'transparent' : 'bg'}
      gap={2}
      direction="column"
      justifyContent="center"
      paddingX={paddingX}
      paddingY={2}
      position="sticky"
      top={top}
      zIndex="sticky"
    >
      <Flex w="full">
        <Flex w="12.5%" justifyContent="flex-start">
          {contentLeft}
        </Flex>

        <InputGroup
          display="flex"
          // grow={1}
          size="md"
          w="75%"
        >
          {!isMinimal && (
            <InputLeftElement
              color={isDisabled || !hasPermission ? 'black24' : 'unset'}
              opacity={isDisabled || !hasPermission ? .7 : 1}
            >
              {iconLeft}
            </InputLeftElement>
          )}

          <Input
            {...isMinimal && {
              border: 'none',
              boxShadow: 'none!important',
              // focusBorderColor: 'none',
              textAlign: 'center',
            }}
            _disabled={{
              opacity: .7,
            }}
            _placeholder={{
              color: 'black24',
              fontStyle: 'italic',
              opacity: '1',
            }}
            autoFocus={isAutofocus && !voiceInput.response}
            bg={hasPermission ? 'white' : disabledPremiumStripesBgLight}
            border={isAi ? 'solid 2px #FF85A2AA!important' : null}
            borderRadius="3xl"
            colorScheme="wooorldBlue"
            isDisabled={isDisabled || !hasPermission}
            onClick={(e) => {
              sendAudioClick(e);
            }}
            onPointerEnter={(e) => {
              sendDragWithinPage();
              sendAudioHover(e)
            }}
            onPointerLeave={() => {
              sendDragToScroll();
            }}
            onChange={({target: {value}}) => dispatch(textInputFieldSet({key: stateKey, val: value}))}
            placeholder={textInputPlaceholder}
            value={textInputField}
          />

          <InputRightElement
            color={isDisabled || !hasPermission ? 'black24' : 'unset'}
            opacity={!hasPermission ? .7 : 1}
            width="auto"
          >
            {textInputField && (
              <IconButton
                borderRadius="full"
                colorScheme="red"
                icon={<CloseIcon/>}
                onClick={(evt) => {
                  sendAudioClick(evt);
                  dispatch(textInputFieldClear({key: stateKey}));
                  dispatch(voiceInputClear());
                }}
                onPointerEnter={(e) => {
                  sendAudioHover(e)
                }}
                variant="ghost"
              />
            )}

            <InAppTooltip message="Click, then begin speaking">
              <IconButton
                // animation={`${pulse} 1.5s infinite alternate ease-in-out`}
                animation={voiceInput.isLoading ? `${pulse} 1.5s infinite alternate ease-in-out` : undefined}
                borderRadius="full"
                color={'black24'}
                _hover={{
                  color: 'wooorldBlue._',
                }}
                icon={<FaMicrophone size="1.5em"/>}
                isDisabled={isDisabled || !hasPermission}
                lineHeight="1.5em"
                onClick={handleClickVoiceInput}
                onPointerEnter={(e) => {
                  sendAudioHover(e)
                }}
                variant="buttonDiminished"
              />
            </InAppTooltip>
          </InputRightElement>
        </InputGroup>

        <Flex w="12.5%" basis="" justifyContent="flex-end">
          {contentRight}
        </Flex>
      </Flex>

      <Flex w="full">
        {children}
      </Flex>
    </Flex>

  );
};

export default InputBar;
