import {IconButton} from '@chakra-ui/react';
import React from 'react';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import {Carousel as ReactCarousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carousel = ({children, renderArrowNext, renderArrowPrev, renderItem}) => (
  <ReactCarousel // https://github.com/leandrowd/react-responsive-carousel
    centerMode
    centerSlidePercentage={100}
    infiniteLoop
    preventMovementUntilSwipeScrollTolerance
    swipeScrollTolerance={50}
    renderItem={renderItem}
    showStatus={false}
    showThumbs={false}
    renderArrowNext={renderArrowNext}
    renderArrowPrev={renderArrowPrev}
  >
    {children}
  </ReactCarousel>
);

export default Carousel;
