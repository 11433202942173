import {Button, Flex, Tag, Td, Text, Tr, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import OptionsMenu from '../../components/OptionsMenu';
import {pinDeleteRequest} from '../../reducers/pinsReducer';

const TableRowDisplay = ({
  fields = [],
  item,
  onDeleteClick,
  onEditClick,
}) => {
  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);

  return (
    <Tr>
      {fields.map(({key, valToLabel = v => v}) => {
        const label = valToLabel(item[key]);
        return (
          <Td key={key}>
            <Flex gap={1}>
              {Array.isArray(label)
                ? label.map(l => <Tag key={l}>{l}</Tag>)
                : label}
            </Flex>
          </Td>
        );
      })}
      <Td>
        <Button
          colorScheme="wooorldBlue"
          marginRight={1}
          onClick={() => onEditClick(item._id)}
          size="xs"
        >
          🖊️
        </Button>

        {onDeleteClick && (
          <Button
            colorScheme="red"
            onClick={() => setIsDeleteMenuOpen(true)}
            size="xs"
          >
            🗑
          </Button>
        )}

        {isDeleteMenuOpen && (
          <OptionsMenu isOpenPermanently>
            <Text color="white">Delete this item?</Text>
            <Button
              colorScheme="red"
              justifyContent="left"
              onClick={(e) => {
                onDeleteClick(item._id);
              }}
              position="relative"
              size="sm"
            >
              confirm
            </Button>

            <Button
              justifyContent="left"
              onClick={(e) => {
                setIsDeleteMenuOpen(false);
              }}
              position="relative"
              size="sm"
            >
              cancel
            </Button>
          </OptionsMenu>
        )}

      </Td>
    </Tr>
  );
};

export default TableRowDisplay;
