import {ButtonGroup} from '@chakra-ui/react';
import ButtonMinor from './ButtonMinor';

// TODO: https://framerbook.com/animation/example-animations/34-swapping-elements/
const RadioButtonGroup = ({
  options,
  onChange,
  value: currentValue,
}) => {
  return ( // https://chakra-ui.com/docs/components/button/props#button-group-props
    <ButtonGroup
      flexWrap="wrap"
      isAttached
    >
      {options.map(({activeBg, label, value, ...others}) => {

        const isActive = currentValue === value;

        return (
          <ButtonMinor
            {...others}
            bg={isActive ? activeBg : '#333'}
            border={isActive ? `solid 2px #7A7A7A` : 'none'}
            color="white!important"
            isActive={isActive}
            isRound
            key={value}
            onClick={() => {
              onChange(value);
            }}
            w="6em"
          >
            {label}
          </ButtonMinor>
        );
      })}
    </ButtonGroup>
  );
};

export default RadioButtonGroup;
