import _ from 'lodash';
export const addRequestCases = (builder, reqThunk, statePath, {
  fulfilledReducer = undefined,
  pendingReducer = undefined,
  rejectedReducer = undefined,
} = {}) => {
  builder
    .addCase(reqThunk.pending, pendingReducer || ((state) => {
      const s = _.get(state, statePath);
      s.isLoading = true;
      s.error = null;
    }))
    .addCase(reqThunk.fulfilled, fulfilledReducer || ((state, action) => {
      const s = _.get(state, statePath);
      s.isLoading = false;
      s.response = action.payload;
    }))
    .addCase(reqThunk.rejected, rejectedReducer || ((state, action) => {
      const s = _.get(state, statePath);
      s.isLoading = false;
      s.error = action.error;
    }));

  return builder;
};

export const requestDefaultState = {
  error: null,
  response: null,
  isLoading: false,
};
