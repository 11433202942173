import {Badge, Box, Button, Flex, Text, useToast} from '@chakra-ui/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {useLobbyHelp} from '../../hooks/useLobbyHelp';
import {worldsAdminDeleteRequest} from '../../reducers/gooodReducer';
import {sendAudioClick, sendWorldJoin} from '../../vuplex';
import WorldCardBlank from '../WooorldInApp/WorldCardBlank';
import SafetyButton from './SafetyButton';
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


const WorldBadge = ({children, color = 'white', tooltip}) => (
  <Badge
    bg="blackAlpha.300"
    borderRadius="2xl"
    color={color}
    fontWeight={100}
    paddingX={2}
    paddingY="0.1em"
    textTransform="none"
  >
    {children}
  </Badge>
);

const WorldCard = ({world}) => {
  const {
    _id,
    blockedUserIds = [],
    createdAt,
    isFull,
    isLobby,
    joinCode,
    rules,
    name,
    ownerId,
    participants, // {_id: alias, ...}
    userCount,
  } = world;

  const {
    isPrivate,
    isSolo,
    isWitw
  } = rules || {};
  const currentWorld = useSelector(s => s.inApp.world) || {};
  const userId = useSelector(s => s.inApp.userId);
  const userIdsBlockedByUser = useSelector(s => s.worlds.userIdsBlockedByUser);

  const hasBlockedUser = _.some(_.keys(participants), p => userIdsBlockedByUser[p]);
  const isBlocked = _.includes(blockedUserIds, userId);
  const isCurrentWorld = currentWorld._id === _id;
  const isDisabled = isCurrentWorld || isFull || isBlocked;
  const isHelpStepExploreWitw = useLobbyHelp(isWitw ? 6 : 5) && !isLobby;

  const handleClick = (evt) => {
    if (isDisabled) return;

    sendWorldJoin({
      worldId: _id,
    });

    sendAudioClick(evt);
  };



  const headerContent = (
    <Flex gap={1} justifyContent="right">
      {hasBlockedUser && (
        <WorldBadge tooltip="Someone you blocked is in this world">
          ⚠
        </WorldBadge>
      )}

      {!isLobby && (
        <WorldBadge color={isFull ? '#fcd' : 'white'}>
          {isFull ? '🙅‍' : '🙋‍'} {userCount}/8
        </WorldBadge>
      )}

      {!isLobby && (
        <WorldBadge
          tooltip={
            isSolo
              ? `This is a Solo World, only for you`
              : isPrivate
                ? `This is a Private World, open to invited users only`
                : `This is a Public World, open to anyone`
          }
        >
          {`${isSolo ? '🔴 Solo' : isPrivate ? '🟠 Private' : '🟢 Public'}`}
        </WorldBadge>
      )}

      {joinCode && (
        <WorldBadge>
          {joinCode}
        </WorldBadge>
      )}
    </Flex>
  );


  const createdDate = dayjs(createdAt);
  const now = dayjs();
  const age = dayjs.duration(now.diff(createdDate))
  const isOlderThanADay = age.as('days') > 1;

  const dispatch = useDispatch();
  const toast = useToast();

  const onDeleteClick = async () => {
    dispatch(worldsAdminDeleteRequest(_id))
      .then(({error}) => {
        toast(error
          ? {
            description: `"${name}" has not been deleted. Please try again.`,
            status: 'error',
            title: 'Failed to delete world.',
          }
          : {
            description: `"${name}" has been deleted`,
            status: 'success',
            title: 'Tour deleted.',
          });
      });
  };

  return (
    <WorldCardBlank
      headerContent={headerContent}
      footerContent={
        <Box h="4em">
          <WorldBadge color={isOlderThanADay ? '#fcd' : 'white'}>
            <Flex alignItems="center" justifyContent="space-between">
              {age.format('DD[d] HH[h] mm[m]')}

              {isOlderThanADay ? (
                <Button
                  colorScheme="red"
                  onClick={onDeleteClick}
                  size="sm"
                >
                  Delete
                </Button>
              ) : (
                <SafetyButton
                  armedColorScheme="red"
                  onClick={onDeleteClick}
                  size="sm"
                >
                  Delete
                </SafetyButton>
              )}

            </Flex>

          </WorldBadge>
        </Box>

      }
      height="14em"
      landscapeHeight={130}
      landscapeTop="50px"
      onClick={handleClick}
      world={world}
    >
      <Text
        align="center"
        color="black.24"
        textShadow={`0px 1px 4px #000000aa`}
        w="full"
      >
        {isLobby ? 'The Lobby' : name}
      </Text>

      <Text
        align="left"
        bg="whiteAlpha.500"
        color="black24"
        fontSize="xs"
        noOfLines={3}
        borderRadius="xl"
        p={2}
        h="70px"
      >
        {_.toPairs(participants)// {id: alias, ...} => [[id, alias], ...]
          .sort(([idA, aliasA], [idB, aliasB]) =>
            idA === ownerId
              ? -1
              : aliasA > aliasB
                ? 1
                : 0)
          .map(([id, alias], idx, coll) => {
            const isBlockedByUser = userIdsBlockedByUser[id];

            return (
              <Text
                as="span" color={isBlockedByUser ? '#FFB3AE' : 'black'}
                key={id}
                marginRight={1}
                whiteSpace="nowrap"
              >
                {ownerId === id && '👑'}
                {isBlockedByUser && '🚫'}
                {alias}
                {(idx < coll.length - 1) && ','}
                &nbsp;
              </Text>
            );
          })}
      </Text>
    </WorldCardBlank>
  );
};

export default WorldCard;
