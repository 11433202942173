import {Avatar, Card, CardBody, CardFooter, CardHeader, Flex, Heading, Text} from '@chakra-ui/react';
import {noop} from 'lodash';
import React, {memo, useState} from 'react';
import {useSelector} from 'react-redux';
import {sendAudioClick, sendAudioHover} from '../../vuplex';
import PermissionBadge from './PermissionBadge';
import PinFolderOptionsMenu from './PinFolderOptionsMenu';

const PinFolderCard = ({
  folder,
  height,
  isSelected,
  labelSize = 'md',
  onClick = noop,
  width,
}) => {
  const {
    _id,
    isOwnedByUser,
    isSolo,
    label,
    ownerActivityStatus,
    ownerAlias,
    ownerProfilePhotoURL,
    pinCount,
    presetName,
    sharedInWorld,
  } = folder;
  const [isHovering, setIsHovering] = useState(false);

  const worldId = useSelector(s => s.inApp.world._id);
  const isCreateNewButton = !_id;
  const bg = isOwnedByUser
    ? isHovering
      ? '#404B5C'
      : '#404B5CCC'
    : isHovering
      ? '#6B6B6B'
      : '#6B6B6BCC';
  const showOptionsMenu = !isCreateNewButton && isOwnedByUser && !presetName && isSelected !== false;

  return (
    <Card
      bg={bg}
      borderRadius="2xl"
      color="#fff"
      flexShrink={0}
      fontSize="lg"
      h={height || '8em'}
      onClick={(evt) => {
        sendAudioClick(evt);
        onClick(folder._id);
      }}
      opacity={isCreateNewButton ? '75%' : null}
      overflow="hidden"
      onPointerEnter={(evt) => {
        setIsHovering(true);
        sendAudioHover(evt);
      }}
      onPointerLeave={() => {
        setTimeout(() => setIsHovering(false), 100);
      }}
      shadow={isSelected ? `outline` : null}
      w={width || '13.5em'}
    >
      <CardHeader p={2}>

        <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          <Flex alignItems="center">
            <Avatar
              borderColor={ownerActivityStatus === 'online' ? 'lightgreen' : 'null'}
              name={ownerAlias}
              showBorder
              size="sm"
              src={ownerProfilePhotoURL}
            />
            <Heading
              color="bg"
              marginLeft={1}
              maxW="6em"
              fontWeight="normal"
              size="sm"
              noOfLines={1}
            >{ownerAlias}</Heading>
          </Flex>

          <Flex gap={1} alignItems="center">
            <PermissionBadge
              isOwnedByUser={isOwnedByUser}
              isPrivate={isSolo}
              isShared={sharedInWorld === worldId}
              ownerAlias={ownerAlias}
              size="1.5em"
            />
            {showOptionsMenu && <PinFolderOptionsMenu folder={folder}/>}
          </Flex>

        </Flex>
      </CardHeader>

      <CardBody p={1} alignItems="center" display="flex" justifyContent="center">
        <Text align="center" fontSize={labelSize} noOfLines={2}>{label}</Text>
      </CardBody>

      <CardFooter p={0} paddingX={1} marginTop={0} h="3em">

        <Flex alignItems="center" justifyContent="space-between" w="full">
          <Text fontSize="md" flexShrink={0} marginLeft={2} noOfLines={1}>
            {pinCount || 0} {pinCount === 1 ? 'place' : 'places'}
          </Text>

          <Flex justifyContent="flex-end" gap={0} w="full">
          </Flex>
        </Flex>

      </CardFooter>
    </Card>
  );


};

export default memo(PinFolderCard);
