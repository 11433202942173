import _ from 'lodash';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {HiDotsHorizontal} from 'react-icons/hi';
import {useLocation} from 'react-router-dom';
import InAppTooltip from '../routes/WooorldInApp/UnityReactComponents/InAppTooltip';
import {sendAudioClick, sendAudioHover} from '../vuplex';

const OptionsMenu = ({children, isOpenPermanently}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();
  const [isHover, setIsHover] = useState(false);

  // hack to close popover when navigating away
  const {pathname} = useLocation();
  useEffect(() => {
    onClose();
  }, [pathname]);

  const handleClose = () => {
    onClose();
    setIsHover(false);
  };

  return isHover || isOpen || isOpenPermanently
    ? (
      <Popover
        computePositionOnMount // Note 🚨: We don't recommend using this in a popover/menu intensive UI or page as it might affect scrolling performance.
        isOpen={isOpenPermanently || isOpen}
        onClose={handleClose}
        // boundary={}
        strategy="fixed"
      >
        <InAppTooltip message="More options">
          <PopoverTrigger>
            <Box
              p={1}
              onClick={(e) => {
                sendAudioClick(e);
                onToggle();
                e.preventDefault();
                e.stopPropagation();
              }}
              onPointerEnter={(e) => {
                sendAudioHover(e);
              }}
              onPointerLeave={(e) => {
                _.delay(() => setIsHover(false), 100);
              }}
            > {/*wrapping in Box prevents ref error*/}
              <HiDotsHorizontal
                color={isHover ? '#33bbff' : null}
              />
            </Box>
          </PopoverTrigger>
        </InAppTooltip>

        <PopoverContent bg="#666" w="auto" borderRadius="xl">
          <PopoverArrow bg="#666"/>
          <PopoverBody p={1}>
            <Stack
              onClick={(e) => {
                handleClose();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {children}
            </Stack>
          </PopoverBody>
        </PopoverContent>

      </Popover>
    )
    : (
      <Box
        onPointerEnter={(e) => {
          setIsHover(true);
        }}
        p={1}
      >
        <HiDotsHorizontal/>
      </Box>

    );
};

export default OptionsMenu;
