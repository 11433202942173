import {Button, Checkbox, Flex} from '@chakra-ui/react';
import React, {useState} from 'react';

const SafetyButton = ({
  armedColorScheme = 'wooorldBlue',
  children,
  isDisabled,
  label = 'Send',
  onClick,
  size,
  ...buttonProps
}) => {
  const [isArmed, setIsArmed] = useState(false);

  const handleClick = () => {
    setIsArmed(false);
    onClick();
  };

  return (
    <Flex gap={1}>
      <Checkbox
        isChecked={isArmed}
        onChange={() => setIsArmed(!isArmed)}
        size={size}
        type="checkbox"
      >
        I am sure
      </Checkbox>

      <Button
        colorScheme={isArmed ? armedColorScheme : 'ghost'}
        isDisabled={!isArmed || isDisabled}
        onClick={handleClick}
        {...buttonProps}
      >
        {children || label}
      </Button>
    </Flex>
  );
};

export default SafetyButton;
