import {Box, Code, Flex, FormControl, FormLabel, Input, Select, Stack, Switch} from '@chakra-ui/react';
import * as dayjs from 'dayjs';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {devConsoleEntriesListRequest} from '../../reducers/gooodReducer';
import DevConsoleCallStackDrawer from '../WooorldInApp/DevConsoleCallStackDrawer';
import InputBar from '../WooorldInApp/InputBar';
import PageTitle from '../WooorldInApp/PageTitle';

const scrollToBottom = () => window.scrollTo(0, document.body.scrollHeight);


const dateToLocal = d => dayjs(d).format('YYYY-MM-DDTHH:mm');
const dateToLocalDisplay = d => dayjs(d).format('YYYY-MM-DD HH:mm:ss.SSS');


const GooodDevConsole = ({}) => {
  const [showEntries, setShowEntries] = useState({
    Dictation: true,
    DictationPartial: false,
    Log: true,
    Warning: true,
    Error: true,
  });

  const {
    error,
    response,
    isLoading,
  } = useSelector(s => s.goood.devConsoleEntries);

  const entries = response || [];

  const [stackTraceEntry, setStackTraceEntry] = useState(null);
  const [tagSelected, setTagSelected] = useState('');
  const [tags, setTags] = useState([]);
  const textInputField = useSelector(s => s.ui.txtInputDevConsole.textInputField);

  const [dateBegin, setDateBegin] = useState(dayjs().add(-1, 'days'))
  const [dateEnd, setDateEnd] = useState(dayjs())

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(devConsoleEntriesListRequest({
      dateBegin,
      dateEnd,
    }));
  }, [dateBegin, dateEnd]);


  const tabsHeight = '2em';

  return (
    <Box w="full">
      <PageTitle title="Dev Console - Goood"/>

      <InputBar stateKey="txtInputDevConsole">
        <Stack w="full">
          <Flex justifyContent="space-between">
            <Input
              placeholder='Begin'
              size='md'
              type='datetime-local'
              onChange={(e) => {
                setDateBegin(new Date(e.target.value));
              }}
              value={dateToLocal(dateBegin)}
            />
            <Input
              placeholder='End'
              size='md'
              type='datetime-local'
              onChange={(e) => {
                setDateEnd(new Date(e.target.value));
              }}
              value={dateToLocal(dateEnd)}
            />
          </Flex>


          <Flex gap={2} paddingTop={2} justifyContent="space-between">

            <Select
              colorScheme="wooorldBlue"
              onChange={({target: {value}}) => setTagSelected(value)}
              placeholder="filter by tag"
              value={tagSelected}
            >
              {tags.map(t => <option key={t} value={t}>{t}</option>)}
            </Select>


            <Flex gap={3}>
              {
                ['Log', 'Warning', 'Error', 'Dictation', 'DictationPartial'].map(entryType => {
                  const colorScheme = { // https://v2.chakra-ui.com/docs/styled-system/theme#colors
                    Error: 'red',
                    Log: 'cyan',
                    Warning: 'yellow',
                    Dictation: 'green',
                    DictationPartial: 'teal'
                  }[entryType];

                  return (
                    <FormControl key={entryType} display="flex" alignItems="center" w="auto">
                      <FormLabel color={`${colorScheme}.700`} htmlFor={entryType} mb={0} mr={1}>
                        {entryType}
                      </FormLabel>
                      <Switch
                        colorScheme={colorScheme}
                        id={entryType}
                        isChecked={showEntries[entryType]}
                        onChange={({target: {checked}}) => {
                          setShowEntries({
                            ...showEntries,
                            [entryType]: checked,
                          });
                        }}
                      />
                    </FormControl>
                  );
                })
              }
            </Flex>


          </Flex>
        </Stack>

      </InputBar>


      <Stack marginTop={tabsHeight}>
        {entries
          .map((e, i) => ({...e, ix: i}))
          .filter(({Message}) => !tagSelected || _.startsWith(Message, `[${tagSelected}]`))
          .filter(({LogType, Message}) => showEntries[LogType] && new RegExp(textInputField, 'i').test(Message))
          .map((entry) => {
            const {LogType, Message, StackTrace, ix} = entry;

            return (
              <Code
                bg={{
                  Error: '#FAA',
                  Dictation: '#AFA',
                  DictationPartial: '#AFC',
                  Log: '#AEF',
                  Warning: '#FEA'
                }[LogType]}
                key={ix}
                onClick={() => {
                  setStackTraceEntry(entry);
                }}
                cursor="pointer"
              >
                [{dateToLocalDisplay(entry.createdOn)}]-> {Message.replaceAll(/\<color=#.{6}\>|\<\/color\>/g, '')}
              </Code>
            );
          })}
      </Stack>


      <DevConsoleCallStackDrawer
        entry={stackTraceEntry && {
          ...stackTraceEntry,
          ix: dateToLocalDisplay(stackTraceEntry.createdOn)
        }}
        onClose={() => {
          setStackTraceEntry(null);
        }}
        size="2xl"
      />

    </Box>
  );


};

export default GooodDevConsole;
