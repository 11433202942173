import {useEffect} from 'react';
import {sendTooltip, sendTooltipOff} from '../vuplex';

export const useInAppTooltip = (message) => {
  useEffect(() => () => {
    sendTooltipOff();
  }, []);

  return {
    onPointerEnter: (evt) => {
      const {clientX, clientY} = evt;
      if (message) sendTooltip({
        message,
        clientX,
        clientY,
      });
    },
    onPointerMove: (evt) => {
      const {clientX, clientY} = evt;
      if (message) sendTooltip({
        message,
        clientX,
        clientY,
      });
    },
    onPointerLeave: (evt) => {
      sendTooltipOff();
    },
  };
};
