import {Card, CardBody, CardHeader, Flex, Heading, IconButton, Input, useToast} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {MdRefresh} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {broadcastMessageRequest, usersOnlineCountRequest} from '../../reducers/gooodReducer';
import SafetyButton from './SafetyButton';

const Broadcast = () => {
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const {isLoading: isUsersLoading, response: userCount} = useSelector(s => s.goood.usersOnlineCount);
  const {isLoading} = useSelector(s => s.goood.broadcast);

  useEffect(() => {
    dispatch(usersOnlineCountRequest());
  }, []);

  const sendMsg = () => {
    dispatch(broadcastMessageRequest(msg)).then(({error}) => {
      toast(error
        ? {
          description: `Message could not be sent. Please try again.`,
          status: 'error',
          title: 'Broadcast failed.',
        }
        : {
          description: msg,
          status: 'success',
          title: 'Message sent to all online users.',
        });
    })
  };

  return !!userCount && (
    <Card borderRadius="3xl" w="full">
      <CardHeader>
        <Heading size="sm">
          Send a message to all {userCount} logged-in users
          &nbsp;
          <IconButton
            icon={<MdRefresh/>}
            isRound
            onClick={() => dispatch(usersOnlineCountRequest())}
          />
        </Heading>

      </CardHeader>
      <CardBody>
        <Flex gap={2}>
          <Input maxW="lg" onChange={({target: {value}}) => setMsg(value)} value={msg}/>
          <SafetyButton
            isLoading={isLoading}
            isDisabled={msg.length < 5 || isUsersLoading}
            onClick={sendMsg}
          />
        </Flex>
      </CardBody>
    </Card>
  )
};

export default Broadcast;
