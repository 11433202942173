import {Box, Card, CardBody, Flex, IconButton, Tag, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import {sendAudioClick} from '../../vuplex';
import Carousel from './Carousel';

const PlaceReviews = ({reviews, ...others}) => {
  return (
    <Flex
      {...others}
      sx={{
        '.carousel-root': {
          width: '100%'
        },
        '.carousel .control-dots': {
          marginBottom: 1,
        }
      }}
    >
      <Carousel

        // renderItem={(item, {isSelected, isPrevious}) => {
        //   return isSelected ? item : null;
        // }}
        renderArrowNext={(onClick, hasNext) =>
          hasNext && (
            <IconButton
              border="solid 1px"
              borderColor="black24"
              bottom={2}
              icon={<MdChevronRight size="1.5em"/>}
              isRound
              onClick={(evt) => {
                sendAudioClick(evt);
                onClick();
              }}
              position="absolute"
              right={4}
              variant="buttonMinor"
              zIndex={2}
            />
          )}
        renderArrowPrev={(onClick, hasPrev) =>
          hasPrev && (
            <IconButton
              border="solid 1px"
              borderColor="black24"
              bottom={2}
              icon={<MdChevronLeft size="1.5em"/>}
              isRound
              onClick={(evt) => {
                sendAudioClick(evt);
                onClick();
              }}
              position="absolute"
              right={16}
              variant="buttonMinor"
              zIndex={2}
            />
          )}
      >
        {reviews.map((review, idx) => {
          const {author_name, rating, relative_time_description, text} = review;

          return (
            <Card borderRadius="2xl" h="12em" key={idx}>
              <CardBody textAlign="left">
                <Text noOfLines={4}>{text}</Text>
                <Box marginTop={2}>
                  {_.range(parseInt(rating, 10)).map((idx) => <span key={idx}>⭐</span>)}
                </Box>
                <Tag fontWeight={700} p={0} variant="ghost">{author_name} &nbsp;</Tag>
                <Tag p={0} variant="ghost">{relative_time_description}</Tag>
              </CardBody>
            </Card>
          );
        })}
      </Carousel>
    </Flex>
  );
};

export default PlaceReviews;
