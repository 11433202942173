import {Heading} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {pinsPanoIdSearchRequest, pinsPanoIdSearchResultsClear, pinUnsavedTempSet} from '../../reducers/pinsReducer';
import PinsList from './PinsList';

const PinsPanoIdSearchView = ({}) => {
  const unsavedTempPin = useSelector(s => s.pins.pinUnsavedTemp);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {panoId} = useParams();

  useEffect(() => {
    if (!panoId) return;
    dispatch(pinsPanoIdSearchRequest({
      panoId, isNew: true,
    }));
  }, [panoId]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <>
      <ButtonMinor
        position="absolute"
        m={4}
        onClick={() => {
          dispatch(pinUnsavedTempSet({}));
          dispatch(pinsPanoIdSearchResultsClear());
          navigate(-1);
        }}
      >
        Go back
      </ButtonMinor>

      <Heading textAlign="center" size="xl" noOfLines={1} p={5}>
        Saved Places with this 360 &nbsp;
      </Heading>

      <PinsList
        columns={2}
        isFiltered={true}
        onRequestPins={(payload, dispatch) => pinsPanoIdSearchRequest({
          ...payload,
          panoId,
        }, dispatch)}
        stateKey="pinsPanoIdSearch"
        textInputStateKey="txtInputPlacesLists" // only used in pinsListSearch
      />
    </>
  );


};

export default PinsPanoIdSearchView;
