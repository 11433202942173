import {Box} from '@chakra-ui/react';
import React from 'react';

const InfiniteListPlaceholder = ({children}) => {
  const borderTopRadius = '24px';

  return (
    <Box w="full" fontSize=".8rem">
      <Box
        bgGradient={`linear(135deg, #C5EAEF, #D2C1EE)`}
        borderTopRadius={borderTopRadius}
        minH="75vh"
        p={2}
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
};

export default InfiniteListPlaceholder;
