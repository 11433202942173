import {useEffect, useState} from 'react';

/**
 * // https://thomasderleth.de/keeping-react-state-and-local-storage-in-sync
 * @param key
 * @param initialValue
 * @returns {[any,((value: any) => void)]}
 */
const useLocalStorageState = (key, initialValue = undefined) => {
  const [value, setValue] = useState(() => {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  });

  useEffect(() => {
    if (value === undefined) window.localStorage.removeItem(key);
    else window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorageState;
