import {Slider, SliderThumb, SliderTrack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useVuplex} from '../../../hooks/useVuplex';
import {sendMapNormalizedScale} from '../../../vuplex';
import _ from 'lodash';

const sendScale = _.throttle((scaleNormalized) => {
  sendMapNormalizedScale({scaleNormalized});
}, 60);

const MinimapScaleSlider = () => {

  const [scale, setScale] = useState(0);

  useVuplex({
    mapScale: ({scaleNormalized}) => {
      setScale(scaleNormalized);
    },
  }, []);

  return (
    <Slider
      aria-label="slider-ex-3"
      orientation="vertical"
      isReversed
      marginLeft={2}
      h="25em"
      min={0.1}
      max={0.6}
      step={0.001}
      size="lg"
      value={scale}
      onChange={(val) => {
        setScale(val);
        sendScale(val);
      }}
    >
      <SliderTrack
        shadow="dark-lg"

      />
      <SliderThumb h="4em" w="3em"
                   shadow="dark-lg"

      />
    </Slider>);
};

export default MinimapScaleSlider;
