import {Card, CardBody, Flex, Heading, Link, Tag, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {apiPost} from '../../api';
import PlacesLoadingIndicator from './PlacesLoadingIndicator';

function Separator() {
  return null;
}

const Birds = ({}) => {

  const {
    center: {
      latitude, longitude,
    },
    radiusKm,
    zoom,
  } = useSelector(state => state.inApp.mapLocation);

  const [pins, setPins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [completion, setCompletion] = useState();

  // const pushPins = pins.map(({label, location: {Latitude, Longitude}}) => ({
  //   center: {
  //     latitude: Latitude,
  //     longitude: Longitude,
  //   },
  //   options: {
  //     title: label,
  //   },
  // }));

  const refresh = (map) => {

  };

  const [regionCode, setRegionCode] = useState();

  useEffect(() => {
    const fn = async () => {
      console.log(`birds within ${radiusKm} of ${latitude},${longitude}`);
      setIsLoading(true);

      const res = await apiPost(`/geodata/birds`, {
        latitude,
        longitude,
        radiusKm,
      });

      console.log('results birds', res);

      setResults(res.data);
      setRegionCode(_.get(res.data, [0, 'locId']));
    };

    fn().catch().finally(() => setIsLoading(false));

  }, [latitude, longitude, radiusKm]);

  return (
    <Flex direction="row" gap={1} justifyContent="space-evenly" wrap="wrap">
      {/*<button onClick={handleExplainButtonClick}>Explain this place</button>*/}
      {/*{completion && (<p style={{padding: '2em'}}>*/}
      {/*  {completion.data.text}*/}
      {/*</p>)}*/}

      {/*{regionCode && <iframe className="iframe" src={`https://search.macaulaylibrary.org/catalog?searchField=hotspot&regionCode=L652085`}/>}*/}


      <Heading size="md" w="full">
        {results.length
          ? 'Species observed in this area in the past 30 days:'
          : (
            <Text>
              No bird species observed within {radiusKm.toPrecision(4)}km of
              <Tag>{latitude},{longitude}</Tag>
              in the past 30 days.
            </Text>)
        }
      </Heading>

      <PlacesLoadingIndicator isLoading={isLoading}/>

      {results.map((res) => {
        const {comName, howMany, speciesCode, sciName} = res;
        // console.log(res);
        return (
          <Card key={speciesCode}>
            <CardBody>
              <Link href={`https://ebird.org/species/${speciesCode}`}>
                <Text fontSize="lg">{comName}<br/></Text>
                <Text fontSize="sm">({sciName})</Text>

                <Tag
                  borderRadius="full"
                  size="sm"
                  position="absolute"
                  right={1}
                  bottom={1}
                  variant="subtle">
                  x{howMany || 1}
                </Tag>
              </Link>

            </CardBody>

          </Card>);
      })}
    </Flex>
  );
};

export default Birds;
