const roleNames = {
  alphaTester: 'alphaTester',
  ambassador: 'ambassador',
  assistant1: 'assistant1',
  assistant2: 'assistant2',
  assistant3: 'assistant3',
  assistant3Trial1Day: 'assistant3Trial1Day',
  assistant3Trial6Month: 'assistant3Trial6Month',
  betaTester: 'betaTester',
  dev: 'dev',
  devAdmin: 'devAdmin',
  explorerPlus: 'explorerPlus',
  explorerPlusFounder: 'explorerPlusFounder',
  moderator: 'moderator',
  playerPlus: 'playerPlus',
  playerPlusFounder: 'playerPlusFounder',
  premium2024: 'premium2024',
  standard: 'standard',
  supporter1: 'supporter1',
  supporter2: 'supporter2',
  supporter3: 'supporter3',
  tester: 'tester',
};

module.exports = {
  goood: {
    tours: {
      filters: {
        complete: 'complete',
        createdByUser: 'createdByUser',
        deleted: 'deleted',
        likedByUser: 'likedByUser',
      }
    },
    users: {
      filters: {
        alphaTester: 'alphaTester',
        explorer: 'explorer',
        founder: 'founder',
        isDev: 'isDev',
        online: 'online',
        player: 'player',
      }
    },
    worlds: {
      filters: {
      },
      sorts: {
        oldest: 'oldest',
        newest: 'newest',
        userCount: 'userCount',
      }
    }
  },
  roles: {
    allRoleNames: Object.values(roleNames),
    roleAssignmentSources: {
      admin: 'admin', // admin manually add/remove
      iapConsumable: 'iapConsumable', // user purchased
      iapDurable: 'iapDurable', // user purchased
      iapSubscription: 'iapSubscription', // user purchased
      metaSubscriptionBypass: 'metaSubscriptionBypass', // user purchased
      system: 'system', // system assigned
    },
    roleNames,
  }
}
