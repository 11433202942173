import {ExternalLinkIcon} from '@chakra-ui/icons';
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Link as Hyperlink,
  Text,
  useToast
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {FaTrash} from 'react-icons/fa';
import {MdCheckCircle, MdOutlineCheckCircle, MdThumbDown, MdThumbUp} from 'react-icons/md';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {mixpanelEventsUrl} from '../../helpers/params';
import {toursAdminDeleteRequest} from '../../reducers/gooodReducer';
import {sendAudioClick} from '../../vuplex';

const TourPromptAdminCard = ({
  _id,
  alias,
  createdOn,
  isDeleted,
  isDislikedByUser,
  isLikedByUser,
  isOwnedByUser,
  likesCount,
  ownerId,
  ownerActivityStatus,
  panoCount = 0,
  profilePhotoURL,
  prompt: originalPrompt,
  promptInterpreted,
  reactions,
  tags = [],
  wooorldRecommended,
}) => {

  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  return (
    <Card
      bg={isDeleted
        ? '#DAA'
        : isOwnedByUser
          ? isHovering
            ? '#E5FEE9'
            : '#E5FEE977'
          : isHovering
            ? 'white'
            : 'whiteAlpha.700'
      }
      borderRadius="2xl"
      // h="11em"
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}
    >
      <CardHeader p={2}>
        <Flex flex="1" gap="1" alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          <Flex alignItems="center">
            <Avatar
              borderColor={ownerActivityStatus === 'online' ? 'lightgreen' : 'null'}
              name={alias}
              showBorder
              size="xs"
              src={profilePhotoURL}
            />
            <Heading marginLeft={1} fontWeight="normal" size="xs" noOfLines={1}>{alias}</Heading>
          </Flex>
          <Flex>
            {panoCount === 5 && <MdCheckCircle color="#64B23E" size="1.5em"/>}
          </Flex>
        </Flex>

        <Hyperlink href={mixpanelEventsUrl(ownerId)} isExternal>
          {ownerId} <ExternalLinkIcon mx="2px"/>
        </Hyperlink>
      </CardHeader>

      <CardBody
        align="center"
        paddingY={0}
      >
        <Link onClick={sendAudioClick} to={_id}>
          <Text fontSize="sm">
            {promptInterpreted}
            <br/>
            <Text as="span" color="wooorldBlue._">{originalPrompt}</Text>
          </Text>
        </Link>

      </CardBody>

      <CardFooter paddingY={1}>

        <Flex alignItems="center" justifyContent="space-between" w="full">

          {!isDeleted && (
            <IconButton
              colorScheme="red"
              icon={<FaTrash/>}
              marginLeft={1}
              size="xs"
              isRound
              top={-1}
              onClick={() => {
                dispatch(toursAdminDeleteRequest({promptId: _id})).then(({error}) => {
                  toast(error
                    ? {
                      description: `"${promptInterpreted}" has not been deleted. Please try again.`,
                      status: 'error',
                      title: 'Failed to delete tour.',
                    }
                    : {
                      description: `"${promptInterpreted}" has been deleted`,
                      status: 'success',
                      title: 'Tour deleted.',
                    });
                });
              }}
            />
          )}

          <Flex gap={2} justifyContent="flex-end" w="full">
            <Text noOfLines={1}>wooorld score: {wooorldRecommended}</Text>
            |
            <Text noOfLines={1}>{likesCount} {likesCount === 1 ? 'like' : 'likes'}</Text>

            {/* 👍 */}
            {isLikedByUser && (
              <MdThumbUp
                color="#33bbff"
                size="1.25em"
              />
            )}

            {/* 👎 */}
            {isDislikedByUser && (
              <MdThumbDown
                color="red"
                size="1.25em"
              />
            )}
          </Flex>
        </Flex>

      </CardFooter>
    </Card>
  );
};

export default TourPromptAdminCard;
