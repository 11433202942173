import {Box, Button, Heading, StackDivider, Text, useToast} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import InfiniteList from '../../components/InfiniteList';
import SearchInput from '../../components/SearchInput';
import {
  worldsAdminDeleteAllUnoccupiedLobbiesRequest,
  worldsAdminDeleteAllUnoccupiedRequest,
  worldsAdminFilterSet,
  worldsAdminListClear,
  worldsAdminListRequest,
  worldsAdminSearchSet,
  worldsAdminSortSet
} from '../../reducers/gooodReducer';
import Filters from '../WooorldInApp/Filters';
import PageTitle from '../WooorldInApp/PageTitle';
import SafetyButton from './SafetyButton';
import WorldCardAdmin from './WorldCardAdmin';

const WorldsList = () => {
  const {
    error,
    filter,
    filterOptions,
    isLoading,
    response,
    search,
    sort,
    sortOptions,
  } = useSelector(s => s.goood.worldsAdmin);
  const {hasNextPage, page, worlds} = response || {};

  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    if (!response && !isLoading && !error) {
      dispatch(worldsAdminListRequest({}));
    }
  }, [response, isLoading, error]);

  const handleFilterClick = (filterClicked) => () => {
    dispatch(worldsAdminFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(worldsAdminSortSet(sortClicked));
  };

  const fetchNextPage = () => dispatch(worldsAdminListRequest({}));
  const isFiltered = /*filter !== '' ||*/ sort !== 'oldest';

  const {userId} = useParams();
  const navigate = useNavigate();

  return (<>
    <PageTitle title="Worlds - Goood"/>

    <Box w="full">
      <Heading
        align="center"
        as="h2"
        fontWeight={400}
        margin="1.5rem"
        position="relative"
        size="md"
      >
        <SearchInput
          onChangeDebounced={val => dispatch(worldsAdminSearchSet(val))}
          value={search}
        />

        <SafetyButton
          armedColorScheme="red"
          onClick={() => {
            dispatch(worldsAdminDeleteAllUnoccupiedRequest())
              .then((res) => {
                toast(res.error
                  ? {
                    status: 'error',
                    title: 'Delete unoccupied worlds request failed.',
                  }
                  : {
                  description: `${res.payload.participantRemovedCount} offline participants removed; ${res.payload.deletedCount} worlds deleted`,
                    status: 'success',
                    title: 'Delete unoccupied worlds request succeeded.',
                  });
                dispatch(worldsAdminListClear());
              });
          }}
          size="sm"
        >
          Delete All Unoccupied Worlds
        </SafetyButton>

        <SafetyButton
          armedColorScheme="red"
          onClick={() => {
            dispatch(worldsAdminDeleteAllUnoccupiedLobbiesRequest())
              .then((res) => {
                toast(res.error
                  ? {
                    status: 'error',
                    title: 'Delete unoccupied lobbies request failed.',
                  }
                  : {
                  description: `${res.payload.participantRemovedCount} offline participants removed; ${res.payload.deletedCount} lobbies deleted`,
                    status: 'success',
                    title: 'Delete unoccupied lobbies request succeeded.',
                  });
              });
          }}
          size="sm"
        >
          Delete All Unoccupied Lobbies
        </SafetyButton>

        <Box position="absolute" right={0} top={0}>
          <Filters>
            <Text color="lightgrey">Filter</Text>
            {filterOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = filter === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleFilterClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
            <StackDivider border="solid grey"/>
            <Text color="lightgrey">Sort by</Text>
            {sortOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = sort === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleSortClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
          </Filters>

        </Box>

      </Heading>

      <InfiniteList
        columns={3}
        error={error}
        hasNextPage={hasNextPage}
        isFiltered={isFiltered || !!search}
        isLoading={isLoading}
        itemHeight="11.5em"
        items={worlds}
        onFetchNextPage={fetchNextPage}
        page={page}
        renderItem={(world, idx) => <WorldCardAdmin key={idx} world={world}/>}
      />
    </Box>
  </>);

};

export default WorldsList;
