import {CloseIcon} from '@chakra-ui/icons';
import {Flex, Heading, Stack, Text, useToast} from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {MdDirectionsRun, MdLightbulbOutline, MdOutlinePanoramaPhotosphere, MdReportProblem} from 'react-icons/md';
import {useDispatch} from 'react-redux';
import ButtonMinor from '../../../../components/ButtonMinor';
import {sendAssistantRequest, sendExploreSearch, sendNavTo, sendPanoLoad, sendReportSketch} from '../../../../vuplex';
import {PanoUnloadButton} from './PanoUnloadButton';
import {useMapsLibrary} from '@vis.gl/react-google-maps';

const ToastContent = ({
  brushStroke,
  latitude,
  longitude,
  onClose,
  placeId,
  skipReverseGeocode,
  streetviewLib,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState('');
  useEffect(() => {
    if (brushStroke || skipReverseGeocode) return;
    axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`, {
      headers: {
        'User-Agent': `${userId}/1.0`,
      },
    })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((e) => {
        console.error(e);
      });

  }, []);

  const {
    address,
    display_name,
  } = data || {};

  const {
    city,
    country,
    province,
    state,
  } = address || {};

  const {
    timeToDeletion,
    userId,
    userAlias,
  } = brushStroke || {};

  return (
    <Flex
      alignItems="start"
      gap={3}
    >
      <PanoUnloadButton/>

      <Flex
        alignItems="flex-start"
        bg="#007AFF"
        borderRadius="2xl"
        color="white"
        gap={2}
        maxWidth="500px"
        p={2}
      >
        <Stack borderRight="solid 1px" borderColor="whiteAlpha.500">
          <Heading
            color="white"
            noOfLines={1}
            size="md"
            textAlign="center"
          >
            {brushStroke && `Drawn by: ${userAlias}`}
            {!brushStroke && display_name}
          </Heading>

          <Heading
            color="white"
            size="md"
            textAlign="center"
            w="full"
          >
            {brushStroke && timeToDeletion}
            {!brushStroke && _.compact([city, state || province, country]).join(', ')}
          </Heading>

          <Text color="whiteAlpha.600" textAlign="center">
            {latitude.toFixed(6)}, {longitude.toFixed(6)}
          </Text>

          <Flex justifyContent="center" gap={4} paddingTop={4}>
            <ButtonMinor
              onClick={() => {
                sendNavTo({
                  lat: latitude,
                  lon: longitude,
                  // zoom: map.getZoom() - zoomOffset,
                });
              }}
              tooltip="Teleport to this location"
            >
              <MdDirectionsRun/>
              &nbsp;
              Go There
            </ButtonMinor>

            {!brushStroke && (
              <ButtonMinor
                onClick={() => {
                  if (!window.vuplex) { // TODO: proper module for Goood
                    const pano = new streetviewLib.StreetViewPanorama(
                      document.getElementById("pano"),
                      {
                        position: {
                          lat: latitude,
                          lng: longitude,
                        }
                      }
                    );
                    console.log(pano);
                    return;
                  }

                  sendPanoLoad({lat: latitude, lon: longitude});
                  onClose();
                }}>
                <MdOutlinePanoramaPhotosphere/>
                &nbsp;
                Load 360
              </ButtonMinor>
            )}

            {display_name && (
              <ButtonMinor
                bg="linear-gradient(234deg, rgba(188,221,252,1) 0%, rgba(179,244,249,1) 15%, rgba(158,193,219,1) 33%, rgba(249,181,193,1) 61%, rgba(249,193,227,1) 82%)"
                onClick={() => {
                  sendAssistantRequest({locationName: display_name});
                  onClose();
                }}>
                <MdLightbulbOutline/>
                &nbsp;
                Fun Fact
              </ButtonMinor>
            )}

            {brushStroke && (
              <ButtonMinor
                bg="darkred"
                color="white"
                onClick={() => {
                  onClose();
                  // close panel before taking a screenshot
                  _.delay(() => sendReportSketch({userId}), 500);
                }}>
                <MdReportProblem/>
                &nbsp;
                Report
              </ButtonMinor>
            )}

            {placeId && (
              <ButtonMinor
                onClick={() => {
                  sendExploreSearch({lat: latitude, lon: longitude, placeId});
                }}>
                View in Explore
              </ButtonMinor>
            )}

          </Flex>

          {/*<Skeleton isLoaded={!isLoading}>*/}
          {/*  <Box>*/}
          {/*    */}
          {/*  </Box>*/}
          {/*</Skeleton>*/}
        </Stack>

        <ButtonMinor bg="none" borderRadius={0} onClick={onClose}>
          <CloseIcon color="white"/>
        </ButtonMinor>
      </Flex>
    </Flex>
  );
};

const MinimapClickActionToast = ({
  brushStroke,
  latitude,
  longitude,
  placeId,
  onClose = _.noop,
  onClosedByUser = _.noop,
  skipReverseGeocode,
}) => {
  const dispatch = useDispatch();
  const streetView = useMapsLibrary('streetView');


  const toast = useToast();
  const toastIdRef = useRef();

  useEffect(() => {
    const closeCurrentToast = () => toast.close(toastIdRef.current);

    if (placeId || latitude || longitude) {
      toast.closeAll();

      toastIdRef.current = toast({
        onCloseComplete: onClose,
        render: () => (
          <ToastContent
            brushStroke={brushStroke}
            latitude={latitude}
            longitude={longitude}
            placeId={placeId}
            onClose={() => {
              closeCurrentToast();
              onClosedByUser();
            }}
            skipReverseGeocode={skipReverseGeocode}
            streetviewLib={streetView}
          />),
        duration: null,
        // icon: <MdVrpano color="#33bbff" size="1.5em"/>,
        isClosable: true,
        position: 'bottom',
      });
    } else {
      closeCurrentToast();
    }

    return closeCurrentToast;
  }, [brushStroke, latitude, longitude, placeId, onClose]);

  // useEffect(() => {
  //   if (panoId) {
  //     dispatch(pinsPanoIdSearchRequest({
  //       isNew: true,
  //       panoId,
  //     }));
  //   }
  // }, [panoId]);

  return null;
};

export default MinimapClickActionToast;
