import {Button, CircularProgress, Input, Modal} from '@chakra-ui/react';
import React, {Fragment, useState} from 'react';

const axios = require('axios');
const {getServerIp} = require('../helpers/params');

const server = getServerIp();


const Promotion = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`${server}/api/promo/key`, {email});
      setIsLoading(false);
      setError('');
      setCode(res.data.key);
    } catch (e) {
      setIsLoading(false);
      setError(e.response.data);
    }
  };

  return (<div id="account-creation-form" className="flex-centered">
    <div className="form-container">
      <h1 className="bold flex-centered">Wooorld Promotion</h1>

      {code && (
        <h3 className="margin1">
          Your promo code is: <pre className="bold">{code}</pre>
          <p className="margin1">Please redeem at checkout in the&nbsp;
            <a href="https://www.oculus.com/experiences/quest/4360608937312293" target="_blank" rel="noreferrer">
              Meta Quest Store
            </a>
          </p>
        </h3>)}

      {error && <h3>{error}</h3>}

      <form className="flex-column" onSubmit={handleSubmit}>
        {!code &&
          <Input
            required
            id="email"
            type="email"
            style={{marginTop: '1em'}}
            label="Email address"
            value={email}
            error={!!error}
            // helperText={error}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />}

        {!code &&
          <Button
            variant="contained"
            onClick={handleSubmit}
          >
            Claim Promo Code
          </Button>}

        <Modal
          isOpen={isLoading}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="flex-centered max-size"><CircularProgress/></div>
        </Modal>
      </form>
    </div>
  </div>);

};

export default Promotion;
