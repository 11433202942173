import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {goood as gooodConsts} from 'common';
import _ from 'lodash';
import {
  getAccessToken,
  getBingMapsApiKey,
  getDemoWorldId,
  getRefreshToken,
  gooodPost,
  setAccessToken,
  setBingMapsApiKey,
  setDemoWorldId,
  setRefreshToken
} from '../gooodApi';
import user from '../routes/Goood/User';
import {addRequestCases, requestDefaultState} from './reducerUtils';


const payloadCreatorCreateUpdate = (route) => async (form) => {
  const {data} = await gooodPost(route, form);
  return data;
};

const payloadCreatorById = (route) => async (itemId) => {
  const {data} = await gooodPost(route, {_id: itemId});
  return data;
};

const payloadCreatorList = (route) => async (payload) => {
  const {data} = await gooodPost(route, payload);
  return data;
};

const {
  tours: {filters: tourFilters},
  users: {filters: usersFilters},
  worlds: {sorts: worldsSorts},
} = gooodConsts;

const toursAdminInitialState = {
  ...requestDefaultState,
  filter: null,
  filterOptions: [
    {label: '♾️ All', value: null},
    {label: '✅ Complete', value: tourFilters.complete},
    {label: '😎 My Tours', value: tourFilters.createdByUser},
    {label: '💖 My Favorites', value: tourFilters.likedByUser},
    {label: '🚮 Deleted', value: tourFilters.deleted},
  ],
  search: '',
  sort: 'likes',
  sortOptions: [
    {label: '🦉 Recommended', value: 'recommended'},
    {label: '💞 Most Liked', value: 'likes'},
    {label: '👎 Most Disliked', value: 'dislikes'},
    {label: '🕒 Newest', value: 'newest'},
  ],
};

const usersAdminInitialState = {
  ...requestDefaultState,
  filter: usersFilters.online,
  filterOptions: [
    // {label: '⛳ Players', value: usersFilters.player},
    // {label: '🅰 Alpha Testers', value: usersFilters.alphaTester},
    // {label: '💼 Explorers', value: usersFilters.explorer},
    {label: '🟢 Online', value: usersFilters.online},
    {label: '♾️ All', value: null},
    {label: '🎖 Founders', value: usersFilters.founder},
    {label: '👩‍💻 Developers', value: usersFilters.isDev},
  ],
  search: '',
  sort: 'updated',
  sortOptions: [
    // {label: 'Recommended', value: 'recommended'},
    {label: '🚨 Most Reported', value: 'reports'},
    {label: '🪑 Most Timeouts', value: 'timeouts'},
    {label: '🐣 Newest', value: 'newest'},
    {label: '👣 Recently Updated', value: 'updated'},
  ],
};

const worldsAdminInitialState = {
  ...requestDefaultState,
  deleteAllUnoccupiedLobbiesRequest: requestDefaultState,
  deleteAllUnoccupiedRequest: requestDefaultState,
  deleteRequest: requestDefaultState,
  filter: null,
  filterOptions: [],
  search: '',
  sort: worldsSorts.oldest,
  sortOptions: [
    {label: '🐣 Newest', value: worldsSorts.newest},
    {label: '🐔 Oldest', value: worldsSorts.oldest},
    {label: '👯‍♂️ User Count', value: worldsSorts.userCount},
  ],
};

const initialState = {
  auth: requestDefaultState, // { response: { accessToken, refreshToken, bingMapsApiKey }, ... }
  broadcast: requestDefaultState,
  devConsoleEntries: requestDefaultState,
  toursAdmin: toursAdminInitialState,
  permissionsAdmin: {
    badges: requestDefaultState,
    badgeCreateOrUpdate: requestDefaultState,
    badgeDelete: requestDefaultState,
    credits: requestDefaultState,
    creditCreateOrUpdate: requestDefaultState,
    creditBalance: requestDefaultState,
    creditDelete: requestDefaultState,
    creditSpend: requestDefaultState,
    permissionCreateOrUpdate: requestDefaultState,
    permissionDelete: requestDefaultState,
    permissions: requestDefaultState,
    props: requestDefaultState,
    propCreateOrUpdate: requestDefaultState,
    propDelete: requestDefaultState,
    roleCreateOrUpdate: requestDefaultState,
    roleDelete: requestDefaultState,
    roles: requestDefaultState,
    user: requestDefaultState,
    userRolesUpdate: requestDefaultState,
    users: {
      ...usersAdminInitialState,
      filter: usersFilters.isDev,
    },
  },
  userMessage: requestDefaultState,
  userTimeout: requestDefaultState,
  usersOnlineCount: requestDefaultState,
  usersAdmin: usersAdminInitialState,
  worldsAdmin: worldsAdminInitialState,
};


export const broadcastMessageRequest = createAsyncThunk(
  'goood/broadcastMessageRequest',
  async (message) => {
    const {data} = await gooodPost(`/broadcast-message`, {
      message
    });
    return data;
  },
);

export const userMessageRequest = createAsyncThunk(
  'goood/userMessageRequest',
  async ({message, userId}) => {
    const {data} = await gooodPost(`/warn`, {
      message,
      userId,
    });
    return data;
  },
);

export const userTimeoutRequest = createAsyncThunk(
  'goood/userTimeoutRequest',
  async ({durationHours, issuedTo, reason}) => {
    const {data} = await gooodPost(`/suspend`, {
      durationHours, issuedTo, reason,
    });
    return data;
  },
);

export const loginGoogleRequest = createAsyncThunk(
  'goood/loginGoogleRequest',
  async ({
    credential,
  }) => {
    const {data} = await gooodPost(`/login-google`, {token: credential});

    // TODO: should be a more graceful way to handle this with sessionState
    const {
      accessToken,
      bingMapsApiKey,
      demoWorldId,
      refreshToken,
    } = data;
    setAccessToken(accessToken);
    setBingMapsApiKey(bingMapsApiKey);
    setDemoWorldId(demoWorldId);
    setRefreshToken(refreshToken);
    return data;
  },
);

export const devConsoleEntriesListRequest = createAsyncThunk(
  'goood/devConsoleEntriesList',
  async ({dateBegin, dateEnd}, {getState}) => {
    const {data} = await gooodPost(`/devConsoleEntries/list`, {
      dateBegin,
      dateEnd,
      // filter,
      // limit: 18,
      // page: _.get(toursAdmin, 'response.nextPage') || 1,
      // search,
      // sort,
    });
    return data;
  },
);

export const toursAdminListRequest = createAsyncThunk(
  'goood/tourAdminListRequest',
  async ({}, {getState}) => {
    const {goood: {toursAdmin}} = getState();
    const {filter, search, sort} = toursAdmin;
    const {data} = await gooodPost(`/tours/list`, {
      filter,
      limit: 18,
      page: _.get(toursAdmin, 'response.nextPage') || 1,
      search,
      sort,
    });
    return data;
  },
);

export const toursAdminDeleteRequest = createAsyncThunk(
  'goood/toursAdminDeleteRequest',
  async ({promptId}, thunkAPI) => {
    const {data} = await gooodPost(`/tours/delete`, {promptId});
    return data;
  },
);

export const usersAdminListRequest = createAsyncThunk(
  'goood/usersAdminListRequest',
  async ({}, {getState}) => {
    const {goood: {usersAdmin}} = getState();
    const {filter, search, sort} = usersAdmin;
    const {data} = await gooodPost(`/users/list`, {
      filter,
      limit: 18,
      page: _.get(usersAdmin, 'response.nextPage') || 1,
      search,
      sort,
    });
    return data;
  },
);

export const usersOnlineCountRequest = createAsyncThunk(
  'goood/usersOnlineCountRequest',
  async ({} = {}) => {
    const {data} = await gooodPost(`/users/onlineCount`, {});
    return data;
  },
);



export const worldsAdminListRequest = createAsyncThunk(
  'goood/worldsAdminListRequest',
  async ({}, {getState}) => {
    const {goood: {worldsAdmin}} = getState();
    const {filter, search, sort} = worldsAdmin;
    const {data} = await gooodPost(`/worlds/list`, {
      filter,
      limit: 18,
      page: _.get(worldsAdmin, 'response.nextPage') || 1,
      search,
      sort,
    });
    return data;
  },
);

export const worldsAdminDeleteRequest = createAsyncThunk(
  'goood/worldsAdminDeleteRequest',
  payloadCreatorById(`/worlds/delete`));

export const worldsAdminDeleteAllUnoccupiedRequest = createAsyncThunk(
  'goood/worldsAdminDeleteAllUnoccupiedRequest',
  async () => {
    const {data} = await gooodPost(`/worlds/deleteAllUnoccupied`, {});
    return data;
  });

export const worldsAdminDeleteAllUnoccupiedLobbiesRequest = createAsyncThunk(
  'goood/worldsAdminDeleteAllUnoccupiedLobbiesRequest',
  async () => {
    const {data} = await gooodPost(`/worlds/deleteAllUnoccupiedLobbies`, {});
    return data;
  });


/* Permissions
* TODO: move to its own reducer
* */



export const userGetRequest = createAsyncThunk(
  'goood/userGetRequest',
  payloadCreatorById(`/user-get`));

export const userRolesUpdateRequest = createAsyncThunk(
  'goood/userRolesUpdateRequest',
  payloadCreatorCreateUpdate(`/users/updateRoles`));

export const userRolesListRequest = createAsyncThunk(
  'goood/userRolesListRequest',
  async ({} = {}, {getState}) => {
    const {goood: {permissionsAdmin: {users}}} = getState();
    const {filter, search, sort} = users;

    const {data} = await gooodPost(`/users/list`, {
      filter,
      limit: 18,
      page: _.get(users, 'response.nextPage') || 1,
      search,
      sort,
    });
    return data.users;
  },
);


export const badgeCreateOrUpdateRequest = createAsyncThunk(
  'goood/badgeCreateOrUpdateRequest',
  payloadCreatorCreateUpdate(`/badges/createOrUpdate`));

export const badgeDeleteByIdRequest = createAsyncThunk(
  'goood/badgeDeleteRequest',
  payloadCreatorById(`/badges/delete`));

export const badgesListRequest = createAsyncThunk(
  'goood/badgesListRequest',
  payloadCreatorList(`/badges/list`),
);


export const creditBalanceRequest = createAsyncThunk(
  'goood/creditBalanceRequest',
  payloadCreatorCreateUpdate(`/credits/balance`));

export const creditCreateOrUpdateRequest = createAsyncThunk(
  'goood/creditCreateOrUpdateRequest',
  payloadCreatorCreateUpdate(`/credits/createOrUpdate`));

export const creditDeleteByIdRequest = createAsyncThunk(
  'goood/creditDeleteRequest',
  payloadCreatorById(`/credits/delete`));

export const creditsListRequest = createAsyncThunk(
  'goood/creditsListRequest',
  payloadCreatorList(`/credits/list`),
);

export const creditsAccrualRun = createAsyncThunk(
  'goood/creditsAccrualRun',
  async ({
    date, userId,
  }) => {
    const {data} = await gooodPost('/credits/runAccruals', {
      date, userId,
    });
    return data;
  }
);
export const creditsRolesGrant = createAsyncThunk(
  'goood/creditsRolesGrant',
  async ({
    userId,
  }) => {
    const {data} = await gooodPost('/credits/grantFromRoles', {
      userId,
    });
    return data;
  }
);

export const creditsSpendRequest = createAsyncThunk(
  'goood/creditsSpendRequest',
  async ({
    amount, creditType, userId,
  }) => {
    const {data} = await gooodPost('/credits/spend', {
      amount, creditType, userId,
    });
    return data;
  }
);


export const permissionsListRequest = createAsyncThunk(
  'goood/permissionsListRequest',
  payloadCreatorList(`/permissions/list`));

export const permissionCreateOrUpdateRequest = createAsyncThunk(
  'goood/permissionCreateOrUpdateRequest',
  payloadCreatorCreateUpdate(`/permissions/createOrUpdate`));

export const permissionDeleteByIdRequest = createAsyncThunk(
  'goood/permissionDeleteRequest',
  payloadCreatorById(`/permissions/delete`));


export const roleCreateOrUpdateRequest = createAsyncThunk(
  'goood/roleCreateOrUpdateRequest',
  payloadCreatorCreateUpdate(`/roles/createOrUpdate`));

export const roleDeleteByIdRequest = createAsyncThunk(
  'goood/roleDeleteRequest',
  payloadCreatorById(`/roles/delete`, 'roleId'));

export const rolesListRequest = createAsyncThunk(
  'goood/rolesListRequest',
  payloadCreatorList(`/roles/list`),
);


export const propCreateOrUpdateRequest = createAsyncThunk(
  'goood/propCreateOrUpdateRequest',
  payloadCreatorCreateUpdate(`/props/createOrUpdate`));

export const propDeleteByIdRequest = createAsyncThunk(
  'goood/propDeleteRequest',
  payloadCreatorById(`/props/delete`, 'propId'));

export const propsListRequest = createAsyncThunk(
  'goood/propsListRequest',
  payloadCreatorList(`/props/list`),
);


const fulfilledReducerCreateOrUpdate = (itemStatePath, listStatePath) => (state, action) => {
  const {_id: itemIdIfNotNew} = action.meta.arg;

  const item = _.get(state, itemStatePath);
  item.isLoading = false;

  const list = _.get(state, listStatePath);

  if (list.response) {
    const i = _.findIndex(list.response, listItem => listItem._id === itemIdIfNotNew);
    if (i >= 0) list.response[i] = action.payload;
    else list.response.push(action.payload);
  }
};

const fulfilledReducerDelete = (itemStatePath, listStatePath) => (state, action) => {
  const deletedId = action.meta.arg;

  const item = _.get(state, itemStatePath);
  item.isLoading = false;

  const list = _.get(state, listStatePath);

  if (list.response) {
    const i = _.findIndex(list.response, p => p._id === deletedId);
    if (i >= 0) list.response.splice(i, 1);
  }
};

export const gooodSlice = createSlice({
  name: 'goood',
  initialState,
  reducers: {
    authClear: (state, {}) => {
      state.auth = requestDefaultState;
      state.toursAdmin = toursAdminInitialState;
      state.users = requestDefaultState;
    },
    authSet: (state, {
      accessToken,
      refreshToken,
      bingMapsApiKey,
      demoWorldId,
    }) => {
      state.auth.response = {
        accessToken,
        bingMapsApiKey,
        demoWorldId,
        refreshToken,
      };
    },
    authSetFromSessionStorage: (state, {}) => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();
      const bingMapsApiKey = getBingMapsApiKey();
      const demoWorldId = getDemoWorldId();
      if (accessToken && refreshToken && bingMapsApiKey) {
        state.auth.response = {
          accessToken,
          bingMapsApiKey,
          demoWorldId,
          refreshToken,
        };
      }
    },
    toursAdminListClear: (state, {}) => {
      state.toursAdmin.isLoading = false;
      state.toursAdmin.response = null;
    },
    toursAdminFilterSet: (state, {payload}) => {
      state.toursAdmin.filter = payload;
      state.toursAdmin.response = null;
    },
    toursAdminSearchSet: (state, {payload}) => {
      state.toursAdmin.search = payload;
      state.toursAdmin.response = null;
    },
    toursAdminSortSet: (state, {payload}) => {
      state.toursAdmin.sort = payload;
      state.toursAdmin.response = null;
    },
    usersAdminListClear: (state, {}) => {
      state.usersAdmin.isLoading = false;
      state.usersAdmin.response = null;
    },
    usersAdminFilterSet: (state, {payload}) => {
      state.usersAdmin.filter = payload;
      state.usersAdmin.response = null;
    },
    usersAdminSearchSet: (state, {payload}) => {
      state.usersAdmin.search = payload;
      state.usersAdmin.response = null;
    },
    usersAdminSortSet: (state, {payload}) => {
      state.usersAdmin.sort = payload;
      state.usersAdmin.response = null;
    },

    usersPermissionsAdminFilterSet: (state, {payload}) => {
      state.permissionsAdmin.users.filter = payload;
      state.permissionsAdmin.users.response = null;
    },
    usersPermissionsAdminSearchSet: (state, {payload}) => {
      state.permissionsAdmin.users.search = payload;
      state.permissionsAdmin.users.response = null;
    },
    usersPermissionsAdminSortSet: (state, {payload}) => {
      state.permissionsAdmin.users.sort = payload;
      state.permissionsAdmin.users.response = null;
    },

    worldsAdminListClear: (state, {}) => {
      state.worldsAdmin.isLoading = false;
      state.worldsAdmin.response = null;
    },
    worldsAdminFilterSet: (state, {payload}) => {
      state.worldsAdmin.filter = payload;
      state.worldsAdmin.response = null;
    },
    worldsAdminSearchSet: (state, {payload}) => {
      state.worldsAdmin.search = payload;
      state.worldsAdmin.response = null;
    },
    worldsAdminSortSet: (state, {payload}) => {
      state.worldsAdmin.sort = payload;
      state.worldsAdmin.response = null;
    },
  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    addRequestCases(builder, broadcastMessageRequest, 'broadcast');

    addRequestCases(
      builder,
      badgeCreateOrUpdateRequest,
      'permissionsAdmin.badgeCreateOrUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate('permissionsAdmin.badgeCreateOrUpdate', 'permissionsAdmin.badges'),
      });
    addRequestCases(
      builder,
      badgeDeleteByIdRequest,
      'permissionsAdmin.badgeDelete',
      {
        fulfilledReducer: fulfilledReducerDelete('permissionsAdmin.badgeCreateOrUpdate', 'permissionsAdmin.badges'),
      });

    addRequestCases(builder, badgesListRequest, 'permissionsAdmin.badges');


    addRequestCases(builder, creditBalanceRequest, 'permissionsAdmin.creditBalance');
    addRequestCases(
      builder,
      creditCreateOrUpdateRequest,
      'permissionsAdmin.creditCreateOrUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate('permissionsAdmin.creditCreateOrUpdate', 'permissionsAdmin.credits'),
      });
    addRequestCases(
      builder,
      creditDeleteByIdRequest,
      'permissionsAdmin.creditDelete',
      {
        fulfilledReducer: fulfilledReducerDelete('permissionsAdmin.creditCreateOrUpdate', 'permissionsAdmin.credits'),
      });
    addRequestCases(builder, creditsListRequest, 'permissionsAdmin.credits');
    addRequestCases(builder, creditsAccrualRun, 'permissionsAdmin.credits');
    addRequestCases(builder, creditsRolesGrant, 'permissionsAdmin.credits');
    addRequestCases(builder, creditsSpendRequest, 'permissionsAdmin.credits');

    addRequestCases(
      builder,
      permissionCreateOrUpdateRequest,
      'permissionsAdmin.permissionCreateOrUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate('permissionsAdmin.permissionCreateOrUpdate', 'permissionsAdmin.permissions'),
      });
    addRequestCases(
      builder,
      permissionDeleteByIdRequest,
      'permissionsAdmin.permissionDelete',
      {
        fulfilledReducer: fulfilledReducerDelete('permissionsAdmin.permissionCreateOrUpdate', 'permissionsAdmin.permissions'),
      });
    addRequestCases(builder, permissionsListRequest, 'permissionsAdmin.permissions');

    addRequestCases(
      builder,
      propCreateOrUpdateRequest,
      'permissionsAdmin.propCreateOrUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate('permissionsAdmin.propCreateOrUpdate', 'permissionsAdmin.props'),
      });
    addRequestCases(
      builder,
      propDeleteByIdRequest,
      'permissionsAdmin.propDelete',
      {
        fulfilledReducer: fulfilledReducerDelete('permissionsAdmin.propCreateOrUpdate', 'permissionsAdmin.props'),
      });
    addRequestCases(builder, propsListRequest, 'permissionsAdmin.props');

    addRequestCases(
      builder,
      roleCreateOrUpdateRequest,
      'permissionsAdmin.roleCreateOrUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate('permissionsAdmin.roleCreateOrUpdate', 'permissionsAdmin.roles'),
      });
    addRequestCases(
      builder,
      roleDeleteByIdRequest,
      'permissionsAdmin.roleDelete',
      {
        fulfilledReducer: fulfilledReducerDelete('permissionsAdmin.roleCreateOrUpdate', 'permissionsAdmin.roles'),
      });
    addRequestCases(builder, rolesListRequest, 'permissionsAdmin.roles');

    addRequestCases(builder, loginGoogleRequest, 'auth');
    addRequestCases(builder, toursAdminListRequest, 'toursAdmin', {
      fulfilledReducer: (state, action) => {
        const {toursAdmin} = state;
        toursAdmin.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `tourPrompts`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        toursAdmin.response = {
          ...action.payload,
          tourPrompts: [
            ...action.payload.page === 1 ? [] : _.get(toursAdmin, 'response.tourPrompts', []),
            ...action.payload.tourPrompts,
          ],
        };
      }
    });
    addRequestCases(builder, toursAdminDeleteRequest, 'toursAdmin', {
      fulfilledReducer: (state, action) => {
        const {promptId} = action.meta.arg;
        const {toursAdmin} = state;

        toursAdmin.isLoading = false;
        toursAdmin.response.tourPrompts.find(p => p._id === promptId).isDeleted = true;
        // if (toursAdmin.filter !== 'deleted')
        //   toursAdmin.response.tourPrompts = toursAdmin.response.tourPrompts.filter(p => p._id !== promptId);
      }
    });

    addRequestCases(builder, usersAdminListRequest, 'usersAdmin', {
      fulfilledReducer: (state, action) => {
        const {usersAdmin} = state;
        usersAdmin.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `users`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        usersAdmin.response = {
          ...action.payload,
          users: [
            ...action.payload.page === 1 ? [] : _.get(usersAdmin, 'response.users', []),
            ...action.payload.users,
          ],
        };
      }
    });

    addRequestCases(builder, devConsoleEntriesListRequest, 'devConsoleEntries');


    addRequestCases(builder, userGetRequest, 'permissionsAdmin.user');
    addRequestCases(builder, userMessageRequest, 'userMessage');
    addRequestCases(builder, userRolesListRequest, 'permissionsAdmin.users');
    addRequestCases(
      builder,
      userRolesUpdateRequest,
      'permissionsAdmin.userRolesUpdate',
      {
        fulfilledReducer: fulfilledReducerCreateOrUpdate(
          'permissionsAdmin.userRolesUpdate',
          'permissionsAdmin.users'),
      });
    addRequestCases(builder, userTimeoutRequest, 'userTimeout');
    addRequestCases(builder, usersOnlineCountRequest, 'usersOnlineCount');

    addRequestCases(
      builder,
      worldsAdminDeleteRequest,
      'worldsAdmin.deleteRequest',
      {
        fulfilledReducer: (state, action) => {
          const deletedId = action.meta.arg;

          const list = _.get(state, 'worldsAdmin.response.worlds');

          if (list) {
            const i = _.findIndex(list, p => p._id === deletedId);
            if (i >= 0) list.splice(i, 1);
          }
        },
      });

    addRequestCases(
      builder,
      worldsAdminDeleteAllUnoccupiedRequest,
      'worldsAdmin.deleteAllUnoccupiedRequest');

    addRequestCases(
      builder,
      worldsAdminDeleteAllUnoccupiedLobbiesRequest,
      'worldsAdmin.deleteAllUnoccupiedLobbiesRequest');

    addRequestCases(builder, worldsAdminListRequest, 'worldsAdmin', {
      fulfilledReducer: (state, action) => {
        const {worldsAdmin} = state;
        worldsAdmin.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `worlds`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        worldsAdmin.response = {
          ...action.payload,
          worlds: [
            ...action.payload.page === 1 ? [] : _.get(worldsAdmin, 'response.worlds', []),
            ...action.payload.worlds,
          ],
        };
      }
    });
  }
});

export const {
  authClear,
  authSet,
  authSetFromSessionStorage,
  toursAdminFilterSet,
  toursAdminListClear,
  toursAdminSearchSet,
  toursAdminSortSet,
  usersAdminFilterSet,
  usersAdminListClear,
  usersAdminSearchSet,
  usersAdminSortSet,
  usersPermissionsAdminFilterSet,
  usersPermissionsAdminSearchSet,
  usersPermissionsAdminSortSet,
  worldsAdminFilterSet,
  worldsAdminListClear,
  worldsAdminSearchSet,
  worldsAdminSortSet,
} = gooodSlice.actions;

export default gooodSlice.reducer;
