import {IconButton} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {MdPlace} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {useInAppTooltip} from '../../hooks/useInAppTooltip';
import {sendAudioClick, sendNavTo} from '../../vuplex';

const MapNavButton = ({
  isExploreNearby,
  isPinCoordinatesSetByAi,
  isPinCoordinatesSetByUser,
  latitude,
  longitude,
  name,
  onClick,
  panoId,
  size,
  zoom,
}) => {

  const {
    center: {
      latitude: currLat,
      longitude: currLon,
    },
    zoom: currZoom,
  } = useSelector(s => s.inApp.mapLocation);

  const handleClick = () => {
    sendNavTo({
      isExploreNearby,
      lat: latitude,
      lon: longitude,
      panoId,
      zoom,
    });

    if (_.isFunction(onClick)) onClick();
  };

  const isCurrentLocation = (!zoom || currZoom === zoom) && (currLat || 0).toFixed(6) === (latitude || 0).toFixed(6) && (currLon || 0).toFixed(6) === (longitude || 0).toFixed(6);

  const tooltip = useInAppTooltip(isCurrentLocation ? `Map is currently here` : `Navigate map to ${name}`);

  return (
    <IconButton
      {...tooltip}
      border="solid 1px"
      borderColor={isPinCoordinatesSetByUser
        ? '#33bbff'
        : isPinCoordinatesSetByAi
          ? '#FF85A2'
          : '#BFBFBF'}
      icon={<MdPlace size="1.5em"/>}
      isDisabled={isCurrentLocation}
      isRound
      onClick={(evt) => {
        sendAudioClick(evt);
        handleClick();
      }}
      size={size}
      variant="buttonMinor"
    />
  );
};

export default MapNavButton;
