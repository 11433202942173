import {Box, Center, Heading} from '@chakra-ui/react';
import React from 'react';

const ContentSeparatorCta = ({
  children,
}) => (
  <Center flexFlow="column" flexWrap="nowrap" p={3}>
    <Box borderTop="solid 1px #BFBFBF" w="15em"/>
    <Heading as="h3" textAlign="center" fontWeight={500} size="md" paddingY={3} w="12em">{children}</Heading>
  </Center>
)

export default ContentSeparatorCta;
