import {
  Alert,
  AlertIcon, Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center, Flex,
  Heading, Image, Link,
  Skeleton,
  Stack,
  Text
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {autoCompleteSpendRequest, detailsRequest} from '../../reducers/placesReducer';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {sendNavTo, sendTextToSpeech, sendTextToSpeechStop} from '../../vuplex';
import GoogleAttribution from './GoogleAttribution';
import GooglePlaceAttachments from './GooglePlaceAttachments';
import InAppMapPin from './UnityReactComponents/InAppMapPin';
import PlacePhotos from './PlacePhotos';
import PlaceReviews from './PlaceReviews';
import Tags from './Tags';
import TourStopAttachments from './TourStopAttachments';
import TourStopCard from './TourStopCard';

const GooglePlaceDetails = ({}) => {
  // const [isLoaded, setIsLoaded] = useState(false);
  const {error, isLoading, response: details} = useSelector(({places}) => places.details);
  const dispatch = useDispatch();

  const {placeId} = useParams();
  const location = useLocation();

  const {isAutocomplete, isExploreNearby, mapNav, description, panoId} = location.state || {};

  const speak = (text) => {
    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeech(text);
  };

  useEffect(() => () => sendTextToSpeechStop(), []);

  useEffect(() => {
    dispatch(detailsRequest({placeId, isAutocomplete})).then(({payload}) => {
      if (payload) {
        if (isAutocomplete) dispatch(autoCompleteSpendRequest());

        if (mapNav) {
          const {geometry: {location: {lat, lng}}} = payload;
          sendNavTo({
            lat: lat,
            lon: lng,
            panoId,
          });
        }

        const {
          editorial_summary: {overview} = {},
          name,
        } = payload;

        speak(`${name}. ${overview || ''}`);
      }

    });
  }, [placeId]);

  const {
    editorial_summary: {overview} = {},
    formatted_address: address,
    geometry: {
      location: {lat, lng},
      // viewport: {
      //   northeast: {
      //     lat: 48.86355484999999,
      //     lng: 2.342908599999999,
      //   },
      //   southwest: {
      //     lat: 48.85824504999999, lng: 2.330669800000001,
      //   },
      // }
    } = {location: {}},
    icon,
    icon_background_color,
    name,
    photos = [],
    reviews = [],
    types: [placeType, /* there may be more than 1 */] = [],
    types = [],
  }
    = details || {};

  return (
    <Skeleton isLoaded={!isLoading}>
      {error && (
        <Center>
          <Alert borderRadius="2xl" m={4} status="error" w="30em">
            <AlertIcon/>
            Something went wrong.
          </Alert>
        </Center>
      )}

      {!error && (
        <>
          <Flex p={2} gap={4} minH="100vh">
            <Flex direction="column" gap={4} shrink={0}>
              <PlacePhotos photos={photos}/>
              <GooglePlaceAttachments />
            </Flex>

            <Stack flexGrow={2} overflow="hidden">
              {overview && (
                <Card
                  backgroundImage={photos[0] && photos[0].photoUrl}
                  backgroundPosition="center"
                  backgroundSize="cover"
                  borderRadius="2xl"
                  marginBottom={4}
                  overflow="hidden"
                  w="full"
                >
                  <CardHeader bg="whiteAlpha.800">
                    <Heading as="h2" size="sm" onClick={() => speak(`${name}, ${address}`)}>{address}</Heading>
                    <Text onClick={() => speak(overview)}>{overview}</Text>
                  </CardHeader>

                  <CardBody
                    p={`${photos[0] ? 10 : 1}rem 0rem 1rem 0rem`}
                  >
                  </CardBody>

                  <CardFooter bg="whiteAlpha.800">
                    <Tags tags={types}/>
                  </CardFooter>

                </Card>
              )}

              <PlaceReviews reviews={reviews}  />
            </Stack>
          </Flex>
          <Box p={2} marginTop={10}>
            <GoogleAttribution/>
          </Box>
        </>
      )}

      {!isExploreNearby && ( // prevent duplicate pins from results list
        <InAppMapPin
          color={icon_background_color}
          iconUrl={icon}
          label={name}
          latitude={lat}
          longitude={lng}
          placeType={placeType}
        />
      )}
    </Skeleton>
  )
};

export default GooglePlaceDetails;
