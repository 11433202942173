import {useEffect, useState} from 'react';
import {sendAudioHover, sendTooltip, sendTooltipOff} from '../vuplex';

export const useHoverState = () => {
  const [isHovering, setIsHovering] = useState(false);

  const hoverProps = {
    onPointerEnter: (e) => {
      sendAudioHover(e);
      setIsHovering(true);
    },
    onPointerLeave: (evt) => {
      setIsHovering(false);
    },
  };

  return [
    isHovering,
    hoverProps,
  ];
};
