import React, {useState} from 'react';

const Item = ({
  className,
  isCollapsible,
  isCollapsedInitially,
  renderBody,
  renderTitle,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsible && isCollapsedInitially);

  return (<div className={className || 'item'}>
    <h3>
      {isCollapsible && (
        <button style={{padding: 0}} onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? '🔽' : '🔼'}
        </button>)}
      {renderTitle()}
    </h3>
    {!isCollapsed && <>
      <hr/>
      {renderBody()}
    </>}
  </div>);
};

export default Item;
