import {CloseIcon, SearchIcon} from '@chakra-ui/icons';
import {IconButton, Input, InputGroup, InputLeftElement, InputRightElement} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useState} from 'react';
import {sendDragToScroll, sendDragWithinPage} from '../vuplex';

const updateSearchDebounced = _.debounce((value, onChange) => onChange(value), 800);

const SearchInput = ({
  allowPeriod = false,
  onChangeDebounced,
  value = '',
}) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  return (
    <InputGroup
      transition="width 0.3s ease"
      display="flex"
      width={isSearchExpanded || !!value ? '11em' : '4em'}
    >
      <InputLeftElement color="black24" pointerEvents="none">
        <SearchIcon/>
      </InputLeftElement>

      <Input
        _disabled={{
          opacity: .7,
        }}
        _placeholder={{
          color: 'black24',
          fontStyle: 'italic',
          opacity: '1',
        }}
        bg="white"
        borderRadius="3xl"
        colorScheme="wooorldBlue"
        onFocus={() => setIsSearchExpanded(true)}
        onBlur={() => setIsSearchExpanded(false)}
        onChange={({target: {value}}) => {
          const nextVal = allowPeriod ? value : _.replace(value, '.', '');
          setInputValue(nextVal);

          if (value)
            updateSearchDebounced(nextVal, onChangeDebounced);
          else {
            updateSearchDebounced.cancel();
            onChangeDebounced('');
          }
        }}
        onPointerEnter={sendDragWithinPage}
        onPointerLeave={sendDragToScroll}
        value={inputValue}
      />

      {inputValue && (
        <InputRightElement>

          <IconButton
            borderRadius="full"
            colorScheme="red"
            icon={<CloseIcon/>}
            onClick={(evt) => {
              setInputValue('');
              onChangeDebounced('');
            }}
            variant="ghost"
          />
        </InputRightElement>
      )}

    </InputGroup>
  );
};

export default SearchInput;
