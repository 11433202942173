import {Box, Card, CardBody, CardHeader, Image, Text} from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useVuplex} from '../../hooks/useVuplex';
import {sendActionPanelClose} from '../../vuplex';
import MinimapClickActionToast from './Minimap/Toast/MinimapClickActionToast';
import PanoNoneForLocationToast from './Minimap/Toast/PanoNoneForLocationToast';
import PageTitle from './PageTitle';

const ActionPanel = ({}) => {
  const [props, setProps] = useState(null);
  const [wikiData, setWikiData] = useState(null);

  useEffect(() => {
    document.body.style.background = 'none';
  }, []);

  const prevJsonProps = useRef();

  useVuplex({
    actionPanel: (jsonProps) => {
      setProps(JSON.parse(jsonProps));
    },
    actionPanelClose: () => {
      setProps({
        latitude: null,
        longitude: null,
        placeId: null,
        wikipedia: null,
      });
    },
    laserAction: (data) => {
      setProps(data);
    },
  });

  useEffect(() => {
    if (!props) return;
    if (!props.wikipedia) {
      setWikiData(null);
      return;
    }

    const {
      photoUrl,
      title,
    } = props.wikipedia;

    const fetchWiki = async () => {
      const res = await axios.get(`https://en.wikipedia.org/api/rest_v1/page/summary/${title}`, {
        headers: {
          'Api-User-Agent': `Wooorld/v2.0(info@wooorld.io)`,
        }
      })
        .catch(err => {

        });

      setWikiData({
        ...res.data,
        photoUrl: _.get(res, 'data.thumbnail.source', photoUrl),
      });
    }

    fetchWiki();
  }, [props]);


  if (!props) return null;
  console.log(props);

  return (
    <>
      <PageTitle title="ActionPanel - Wooorld"/>
      <Box
        h="100vh"
        w="100vw"
      >
        <MinimapClickActionToast
          brushStroke={props.timeToDeletion && props}
          latitude={props.latitude}
          longitude={props.longitude}
          placeId={props.placeId}
          onClose={sendActionPanelClose}
          skipReverseGeocode={props.skipReverseGeocode}
        />
        <PanoNoneForLocationToast onClose={sendActionPanelClose}/>
        {wikiData && (
          <Card borderRadius="2xl">
            {wikiData.photoUrl && (
              <CardHeader
                backgroundImage={wikiData.photoUrl}
                backgroundPosition="center"
                backgroundSize="cover"
                h="10em"
              />
            )}

            <CardBody>
              <Text noOfLines={5}>
                {wikiData.extract}
              </Text>
            </CardBody>

          </Card>)}
      </Box>
    </>
  );


};

export default ActionPanel;
