import {Spacer} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {textInputPlaceholderSet} from '../../../reducers/uiReducer';
import Birds from '../Birds';
import GooglePlaces from '../GooglePlaces';
import InputBar from '../InputBar';
import TourTripToggle from '../TourTripToggle';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Wikipedia from '../Wikipedia';

const TripPlanner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(textInputPlaceholderSet({key: 'txtInputExplore', val: 'Search places'}));
  }, [])



  return (
    <>
      <TourTripToggle />

      <Routes>
        <Route path="1" element={<Step1 />}/>
        <Route path="2" element={<Step2 />}/>
        <Route path="3" element={<Step3 />}/>
        <Route path="trip/*" element={null}/>
        <Route path="*" element={<Navigate  to="1" replace={true}/>}/>
      </Routes>

    </>
  );
}

export default TripPlanner;
