import {Box, Tag} from '@chakra-ui/react';
import React from 'react';

const Tags = ({tags}) => {
  return (
    <Box>
      {tags.map((t, idx) => <Tag variant="outline" margin={1} key={idx}>{t.replaceAll('_', ' ')}</Tag>)}
    </Box>
  );
};

export default Tags;
