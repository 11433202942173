import {ChakraProvider, defineStyle, defineStyleConfig, extendTheme} from '@chakra-ui/react';
import '@fontsource-variable/jetbrains-mono';
import '@fontsource/roboto';
import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import App from './App';
import {setStore} from './gooodApi';
import reportWebVitals from './reportWebVitals';
import {store} from './store';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const noShadow = {
  _focus: {
    shadow: 'none',
  },
  _focusVisible: {
    shadow: 'none',
  },
};

const buttonDiminished = defineStyle({
  ...noShadow,
  background: 'none',
  color: 'grey',

  _hover: {
    filter: `brightness(0.8)`,
  },

  // let's also provide dark mode alternatives
  // _dark: {
  //   background: 'orange.300',
  //   color: 'orange.800',
  // }
});

const buttonMinor = defineStyle({
  ...noShadow,
  background: 'white',
  color: 'black24',

  _hover: {
    filter: `brightness(0.8)`,
    shadow: 'none',

    _disabled: {
      bg: 'inactiveGrey',
    }
  },

  _disabled: {
    bg: 'inactiveGrey',
    opacity: 0.7,
  }

// let's also provide dark mode alternatives
// _dark: {
//   background: 'orange.300',
//   color: 'orange.800',
// }
});

export const buttonTheme = defineStyleConfig({
  variants: {buttonDiminished, buttonMinor},
});

// https://chakra-ui.com/docs/styled-system/customize-theme
const theme = extendTheme({
  colors: {
    betaGrey: '#808080',
    bg: '#E6E6E6',
    black24: '#3D3D3D',
    greyAccent: '#BFBFBF',
    explorerBlue: '#59C8FF',
    inactiveGrey: '#7A7A7A',
    playerPink: '#FFC3CE',
    privateOrange: '#E48524',
    publicGreen: '#6BC143',
    soloGrey: '#999999',
    tourOrange: '#FF9933',
    wooorldBlue: // https://smart-swatch.netlify.app/#33bbff
      {
        _: '#33bbff',
        50: '#d9f8ff',
        100: '#ace6ff',
        200: '#7bd4ff',
        300: '#49c3ff',
        400: '#1ab2ff',
        500: '#0099e6',
        600: '#0077b4',
        700: '#005582',
        800: '#003451',
        900: '#001221',
      },
    wooorldLavender: // https://smart-swatch.netlify.app/#d9d3ee
      {
        50: '#efecfd',
        100: '#cfc8ea',
        200: '#b0a3d9',
        300: '#907fca',
        400: '#705abb',
        500: '#5841a2',
        600: '#43337e',
        700: '#30245a',
        800: '#1d1537',
        900: '#0b0616',
      },
  },
  components: {Button: buttonTheme},
  fonts: {
    body: `'Roboto', sans-serif`,
    mono: `'JetBrains Mono Variable', sans-serif`,
  },
  styles: {
    global: {
      body: {
        bg: '#E6E6E6',
        color: 'black24',
      },
      html: {
        color: 'black24',
        height: '100%',
        '&::-webkit-scrollbar': {
          display: 'none', // Android webview has no scrollbar on document so hide it in editor as well
        },
        overflowY: 'scroll',
      },
      'h1, h2, h3, h4': {
        color: 'black24',
      },
      '.chakra-popover__popper': {
        zIndex: '10000!important', // for tour help popover fighting with sticky inputbar
      },
    }
  }
});


setStore(store);

createRoot(document.getElementById('root'))
  .render(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App/>
      </ChakraProvider>
    </Provider>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
