import React from 'react';
import mixpanel from 'mixpanel-browser';

mixpanel.init('7d28a92e17d86dfba14c28f45cc4821a'); // this is a public identifier, no security concern

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App

    const { name, message, stack } = error;

    mixpanel.track(`React Rendering Error 🕸️`, {
      errorName: name,
      errorMessage: message,
      errorStack: stack,
      componentStack: info.componentStack,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
