import {AbsoluteCenter, Badge, Box, IconButton} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHoverState} from '../../../hooks/useHoverState';
import LikesBadge from '../LikesBadge';

const colorActive = '#00b1ff';
const colorInactive = '#666';

const PanoIconSvg = ({
  isActive,
  isHovering,
  isPrivate,
  isShared,
  size,
  onClick,
  onPointerEnter,
  onPointerLeave,
}) => {

  const color = isActive ? colorActive : colorInactive;

  const colorPermission = isShared
    ? '#FFC002'
    : isPrivate ? '#FE8181'
      : '#5EC374';

  const gradientId = `panoIconGradient-${color}`;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <defs>
        <linearGradient
          id="linear-gradient-pano-icon"
          x1="24"
          x2="24"
          y1="23.24"
          y2="0.92"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" stopOpacity="0.9"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id={gradientId}
          x1="24"
          x2="24"
          y1="15.9"
          y2="2.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={color} stopOpacity="0.9"></stop>
          <stop offset="1" stopColor={color} stopOpacity="0.1"></stop>
        </linearGradient>
      </defs>
      <ellipse
        cx="24"
        cy="24"
        data-name="Inactive Ground"
        opacity="0.2"
        rx="2.25"
        ry="0.75"
      ></ellipse>
      <g>
        <path
          fill="url(#linear-gradient-pano-icon)"
          d="M24 23.24s-.11-.01-.16-.08l-6.55-9.42a8.206 8.206 0 010-9.33c1.55-2.23 3.99-3.5 6.7-3.5s5.16 1.28 6.71 3.5a8.206 8.206 0 010 9.33l-6.55 9.42a.19.19 0 01-.16.08z"
        ></path>
        <path
          fill={isHovering ? colorActive : color}
          d="M24 1.27c2.6 0 4.93 1.22 6.42 3.35a7.843 7.843 0 010 8.93L24 22.78l-6.42-9.23a7.843 7.843 0 010-8.93C19.06 2.49 21.4 1.27 24 1.27m0-.7c-2.65 0-5.3 1.22-6.99 3.65a8.518 8.518 0 000 9.72l6.55 9.41c.11.15.28.23.44.23s.34-.08.44-.23l6.55-9.41a8.518 8.518 0 000-9.72C29.3 1.78 26.65.57 24 .57z"
        ></path>
      </g>
      <circle
        cx="24"
        cy="9.13"
        r="6.77"
        fill={`url(#${gradientId})`}
        data-name="360 BG"
        {...{
          onClick,
          onPointerEnter,
          onPointerLeave,
        }}
        pointerEvents="all"
      ></circle>
      <g fill="#fff" pointerEvents="none">
        <path
          d="M24.77 6.55l-2.81 4.87c-.25.44.06.98.57.98h5.63c.5 0 .82-.55.57-.98l-2.81-4.87a.657.657 0 00-1.14 0z"></path>
        <path
          d="M21.04 8.68l-1.79 3.1c-.16.28.04.63.36.63h3.58c.32 0 .52-.35.36-.63l-1.79-3.1a.414.414 0 00-.72 0z"></path>
      </g>
      <circle cx="20.81" cy="5.88" r="0.6" fill="#fff"></circle>

      <g data-name="Active Check Group" pointerEvents="none">
        <circle
          cx="29.5"
          cy="3.58"
          r="3.01"
          fill={isActive ? colorActive : colorPermission}
          data-name="Active Circle"
        ></circle>
        {isActive && (
          <path
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            d="M27.74 3.48L28.88 4.77 31.26 2.39"
            data-name="Active Check"
          ></path>
        )}
      </g>
    </svg>
  );
};
const noop = () => {};

const PanoIcon = ({
  isActive,
  isPrivate,
  isShared,
  onClick,
  onPointerEnter = noop,
  onPointerLeave = noop,
  pin,
  size,
  streetViewId,
}) => {
  const [isHovering, hoverProps] = useHoverState();

  const panoId = useSelector(s => s.inApp.panoId);
  const isLoaded = streetViewId === panoId;

  const [showPinCard, setShowPinCard] = useState(false);
  const pinCardCloseTimeoutId = useRef();
  const pinCardOpenTimeoutId = useRef();

  const handlePointerEnter = (e) => {
    hoverProps.onPointerEnter(e);
    onPointerEnter();
    clearTimeout(pinCardCloseTimeoutId.current);
    pinCardOpenTimeoutId.current = setTimeout(() => {
      setShowPinCard(true);
    }, 10);
  };

  const handlePointerLeave = (evt) => {
    hoverProps.onPointerLeave(evt);
    onPointerLeave();
    clearTimeout(pinCardOpenTimeoutId.current);
    pinCardCloseTimeoutId.current = setTimeout(() => {
      setShowPinCard(false);
    }, 300);
  };

  const {label, likes} = pin || {};

  return !isLoaded && (
    <>
      <IconButton
        _hover={{bg: 'none'}}
        bg="none"
        icon={
          <PanoIconSvg
            {...{
              isActive,
              isHovering: showPinCard,
              isPrivate,
              isShared,
              size: (showPinCard || isActive) ? size : '4em',
            }}
            onClick={onClick}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
          />}
      />

      <AbsoluteCenter top="2.75em" pointerEvents="none">
        {
          label && showPinCard
            ? (
              <Badge
                bg={colorActive}
                borderRadius="full"
                color="#fff"
                fontSize="1em"
                fontWeight="normal"
                paddingX={2}
                lineHeight="1.2em"
                textTransform="none"
              >
                {label}
              </Badge>
            )
            : (
              <Box transform="scale(0.75)">
                <LikesBadge bg={colorActive} color="#fff" likeCount={likes} size="sm"/>
              </Box>
            )
        }
      </AbsoluteCenter>
    </>
  );
};

export default PanoIcon;
