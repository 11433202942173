import {ChevronDownIcon, ChevronUpIcon, SearchIcon} from '@chakra-ui/icons';
import {Box, Flex, InputGroup, InputLeftElement, InputRightElement, Text} from '@chakra-ui/react';
import {keyframes} from '@emotion/react';
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from '@choc-ui/chakra-autocomplete';
import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {FaMicrophone} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import HasPermission from '../../components/HasPermission';
import {exploreGoogle} from '../../hooks/usePermissions';
import {useRetryOnAccessTokenRefresh} from '../../hooks/useRetryOnAccessTokenRefresh';
import {autoCompleteBalanceRequest, autoCompleteSpendRequest} from '../../reducers/placesReducer';
import {sendAudioClick, sendVoiceInputRequest} from '../../vuplex';
import UpgradeButton from './UpgradeButton';


const searchDebounced = _.debounce(async ({input, setResults, setIsLoading, userId}) => {
  setIsLoading(true);
  const res = await axios.get(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(input)}&format=jsonv2`, {
    headers: {
      'User-Agent': `${userId}/1.0`,
    }
  });
  console.log(res.data);
  setResults(res.data);
  setIsLoading(false);
}, 1200);

const pulse = keyframes`
  from {
    color: darkred;
  }
  to {
    color: red;
  }
`;

const OpenStreetMapAutoComplete = ({
  onSelect = () => {},
  ...inputGroupProps
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(s => s.inApp.userId);
  const autocompleteCredits = useSelector((s) => s.places.autoComplete.credits);
  const canAutocomplete = autocompleteCredits > 0;
  const placeholder = `Search ${autocompleteCredits <= 5 ? ` (${autocompleteCredits} left today)` : ''}`;
  useRetryOnAccessTokenRefresh(() => dispatch(autoCompleteBalanceRequest()));

  const permissions = useSelector(s => s.inApp.permissions);
  useEffect(() => {
    console.log('perms changed, refreshing balance')
    dispatch(autoCompleteBalanceRequest());
  }, [permissions]);


  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);


  const [isVoicePending, setIsVoicePending] = useState(false);

  const [hasSpentThisSearch, setHasSpentThisSearch] = useState(false);

  return (<>
    <AutoComplete // https://github.com/anubra266/choc-autocomplete
      autoFocus={false}
      closeOnBlur
      disableFilter
      focusInputOnSelect={false}
      isLoading={isLoading}
      listAllValuesOnFocus
      openOnFocus
      restoreOnBlurIfEmpty
      selectOnFocus
      suggestWhenEmpty

      onSelectOption={({item, selectMethod, isNewInput}) => {
        // console.log(item, selectMethod, isNewInput);
        const {originalValue: {lat, lon, display_name, name}} = item;

        sendAudioClick({clientX: 0.5, clientY: 0.5});
        if (!hasSpentThisSearch) {
          dispatch(autoCompleteSpendRequest());
          setHasSpentThisSearch(true);
        }

        onSelect({
          display_name,
          lat: parseFloat(lat),
          lon: parseFloat(lon),
          name,
        });
      }}
    >
      {({isOpen, onOpen, onClose}) => {

        return (
          <>
            <InputGroup {...inputGroupProps}>
              <InputLeftElement color="black24" pointerEvents="none">
                <SearchIcon/>
              </InputLeftElement>

              <AutoCompleteInput
                background="white!important"
                opacity="1!important"
                borderRadius="full"
                isDisabled={!canAutocomplete}
                onChange={(e) => {
                  setHasSpentThisSearch(false);
                  searchDebounced({
                    input: e.currentTarget.value,
                    setResults,
                    setIsLoading,
                    userId,
                  });
                }}
                placeholder={placeholder}
                variant="filled"
              />

              <InputRightElement w={20}>
                <Flex gap={0} alignItems="center">
                  <ButtonMinor
                    animation={isVoicePending ? `${pulse} 1.5s infinite alternate ease-in-out` : undefined}
                    bg="white!important"
                    filter="none!important" // prevent focus state turning it grey
                    h="2.2em" // prevent busting out the top when input is focused
                    isDisabled={!canAutocomplete}
                    isRound={false}
                    onClick={async () => {
                      setIsVoicePending(true);
                      const res = await sendVoiceInputRequest().catch(() => {
                        console.error('failed to get voice input');
                      });
                      setIsVoicePending(false);
                      if (!res) return;
                      searchDebounced({
                        input: res,
                        setResults,
                        setIsLoading,
                        userId,
                      });
                      searchDebounced.flush(); // .flush() invokes immediately
                      onOpen();
                    }}
                    opacity="1!important"
                    p={0}
                    tooltip="Click, then begin speaking"
                  >
                    <FaMicrophone
                      size="1.25em"
                    />
                  </ButtonMinor>

                  {/*<InAppTooltip message="Click, then begin speaking">*/}

                  {/*  <IconButton*/}
                  {/*    // animation={`${pulse} 1.5s infinite alternate ease-in-out`}*/}
                  {/*    animation={voiceInput.isLoading ? `${pulse} 1.5s infinite alternate ease-in-out` : undefined}*/}
                  {/*    borderRadius="full"*/}
                  {/*    color={'black24'}*/}
                  {/*    _hover={{*/}
                  {/*      color: 'wooorldBlue._',*/}
                  {/*    }}*/}
                  {/*    icon={<FaMicrophone size="1.5em"/>}*/}
                  {/*    isDisabled={isDisabled || !hasPermission}*/}
                  {/*    lineHeight="1.5em"*/}
                  {/*    onClick={handleClickVoiceInput}*/}
                  {/*    onPointerEnter={(e) => {*/}
                  {/*      sendAudioHover(e);*/}
                  {/*    }}*/}
                  {/*    variant="buttonDiminished"*/}
                  {/*  />*/}
                  {/*</InAppTooltip>*/}

                  <ButtonMinor
                    bg="none!important"
                    isDisabled={!canAutocomplete}
                    onClick={() => {
                      if (isOpen) onClose();
                      else onOpen();
                    }}
                    p={0}
                  >
                    {isOpen
                      ? <ChevronUpIcon/>
                      : <ChevronDownIcon/>}
                  </ButtonMinor>
                </Flex>


              </InputRightElement>
            </InputGroup>

            <AutoCompleteList>
              {results.map(({
                display_name,
                lat,
                lon,
                name,
                place_id,
              }, idx) => (
                <AutoCompleteItem
                  key={idx}
                  color="black24"
                  label={display_name}
                  value={{lat, lon, display_name, name}}
                  alignItems="center"
                  fontSize="1.4em"
                >
                  {display_name}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>

            {!canAutocomplete && ( // standard has 5 autocomplete. show upgrade message if standard and used up credits
              <HasPermission
                permission={exploreGoogle} // hack to determine they don't have explorer+
                fallbackContent={
                  <Box position="absolute" left="5em" transform="scale(1.5);" top="4.5em">
                    <UpgradeButton descriptor="Explore InputBar" packageName={'explorer+'}>
                      <Text paddingX={1}>Want more search? Upgrade!</Text>
                    </UpgradeButton>
                  </Box>
                }
              />
            )}
          </>
        );
      }}
    </AutoComplete>

  </>);
};

export default OpenStreetMapAutoComplete;
