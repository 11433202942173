import {AbsoluteCenter, Box} from '@chakra-ui/react';
import {AdvancedMarker, useAdvancedMarkerRef, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useRef, useState} from 'react';
import {GoDotFill} from 'react-icons/go';
import {useVuplex} from '../../../hooks/useVuplex';

const MinimapLaserPointerMarker = () => {
  const [refCallback, marker] = useAdvancedMarkerRef();
  const [showMarker, setShowMarker] = useState(false);
  const beamRef = useRef();

  const timeoutId = useRef();

  const map = useMap();
  const maps = useMapsLibrary('maps');

  useVuplex({
    laserPointer: ({avatarLatitude, avatarLongitude, latitude, longitude, distance, color}) => {
      if (!marker || !map || !maps) return;

      if (!timeoutId.current) {
        setShowMarker(true);
        if (beamRef.current) beamRef.current.setMap(map);
      }
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        setShowMarker(false);
        timeoutId.current = null;
        if (beamRef.current) beamRef.current.setMap(null);
      }, 1000);

      const markerPosition = {lat: latitude, lng: longitude};
      marker.position = markerPosition;

      if (!beamRef.current) {
        beamRef.current = new maps.Polyline({
          strokeColor: color,
          strokeOpacity: 0.5,
          strokeWeight: 2,
          geodesic: true,
          map: map,
        });
      }

      beamRef.current.setPath([
        {
          lat: avatarLatitude,
          lng: avatarLongitude,
        },
        markerPosition,
      ]);

      beamRef.current.strokeColor = color;

      const style = _.get(marker, 'element.firstChild.firstChild.style');
      if (!style) {
        console.warn('unexpected marker dom structure');
        return;
      }
      style.color = color;
    },
  }, [map, maps, marker]);


  return (
    <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
      onClick={(e) => {}}
      ref={refCallback}
    >
      <AbsoluteCenter pointerEvents="none">
        {showMarker && <GoDotFill size="2em"/>}
      </AbsoluteCenter>
    </AdvancedMarker>

  );
};

export default MinimapLaserPointerMarker;
