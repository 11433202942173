import {Card, Flex} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {apiPost} from '../../api';
import InfiniteList from '../../components/InfiniteList';
import {folderOpen} from '../../reducers/pinsReducer';
import PinFolderCard from './PinFolderCard';
import PlacesLoadingIndicator from './PlacesLoadingIndicator';
import WikipediaCard from './WikipediaCard';

const Wikipedia = ({}) => {

  const {
    center: {
      latitude, longitude,
    },
    radiusKm,
    zoom,
  } = useSelector(state => state.inApp.mapLocation);

  const isToolOn = useSelector(s => s.inApp.isToolOn);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState({});

  const fetchWikiNearby = async () => {
    setIsLoading(true);
    setError(undefined);
    const radiusMeters = Math.max(1000, Math.min(10000, Math.floor(radiusKm * 1000)));
    // const radius = Math.min(10000, Math.floor(20000 / Math.log2(zoom))); // in meters
    console.log(`wiki within ${radiusMeters} of ${latitude},${longitude}`);

    const res = await apiPost(`/geodata/wikiNearby`, {
      latitude,
      longitude,
      radiusMeters,
    }).catch(e => {
      setError(e);
      setIsLoading(false);
    }) || {};

    setIsLoading(false);
    setResults(res.data);
  };

  useEffect(() => {
    if (isToolOn) fetchWikiNearby();
    else {
      setIsLoading(false);
      setResults({});
    }
  }, [isToolOn, latitude, longitude, radiusKm, zoom]);

  const wikiPages = _.map(_.get(results, 'query.pages'), (wikiPage, pageId) => ({
    wikiPage,
    pageId
  }));

  return isToolOn && (
    <InfiniteList
      {...{
        columns: 4,
        error,
        isLoading,
        items: wikiPages,
        itemHeight: '14em',
        page: 1,
      }}
      onFetchNextPage={fetchWikiNearby}
      renderItem={({wikiPage, pageId}) => (
        <WikipediaCard
          key={pageId}
          pageId={pageId}
          wikiPage={wikiPage}
        />)}
    />
  );
};

export default Wikipedia;
