import _ from 'lodash';
import {Button, Center, Checkbox, Heading, Stack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  tripCreateRequest,
  tripPlannerFormClear,
  tripPlannerFormUpdate,
  tripPlannerTripClear
} from '../../../reducers/toursReducer';

const items = ['Lodging', 'Meals', 'Things to do', 'Places to see'];

const Step3 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    form: {includedItems},
    trip: {
      isLoading,
      response,
    },
  } = useSelector(({tours}) => tours.tripPlanner);

  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (!includedItems) dispatch(tripPlannerFormUpdate({
      includedItems: items,
    }));
  }, [includedItems]);

  useEffect(() => {
    if (!isLoading && response) {
      if (isCreating)
        navigate(`../trip/${response.tripPrompt._id}`);
      else {
        dispatch(tripPlannerTripClear());
        dispatch(tripPlannerFormClear());
        navigate(`../1`);
      }
    }

  }, [isLoading, response, isCreating]);

  return (
    <Stack
      bgGradient="linear(to-b, #C1DAFF, #E0FFF9)"
      borderRadius="lg"
      p={5}
      spacing={6}
      textAlign="center"
    >
      <Heading p={4} size="md">Step 3/3 What to include?</Heading>

      <Stack alignItems="center">
        {items.map((item, idx) => (
          <Checkbox
            background="white"
            borderRadius="md"
            colorScheme="wooorldBlue"
            isChecked={_.includes(includedItems, item)}
            key={idx}
            onChange={() => {
              dispatch(tripPlannerFormUpdate({
                includedItems: _.xor(includedItems, [item])
              }));
            }}
            p={2}
            w="15em"
          >
            {item}
          </Checkbox>
        ))}
      </Stack>

      <Center>
        <Button
          colorScheme="wooorldBlue"
          isLoading={isLoading}
          onClick={() => {
            dispatch(tripCreateRequest({}));
            setIsCreating(true);
          }}
          size="lg"
          variant="solid"
        >
          ⚡ Create Itinerary
        </Button>
      </Center>

    </Stack>);
};

export default Step3;
