import {Card, Heading, IconButton, Text} from '@chakra-ui/react';
import * as dayjs from 'dayjs';
import React from 'react';
import {MdPlace} from 'react-icons/md';
import {useDispatch} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {textToSpeechStatusSet} from '../../../reducers/uiReducer';
import {sendTextToSpeech} from '../../../vuplex';

const TripDay = ({
  dayIndex,
  isOwnedByUser,
  itinerary = [],
  ...others
}) => {
  const dispatch = useDispatch();

  return (
    <Card p={4}>
      <Heading marginBottom={2} size="sm">Day {dayIndex + 1}</Heading>
      {itinerary.map(({
        activity,
        cost,
        googlePlace,
        location,
        placeName,
        time,
      }, idx) => (
        <Card
          onClick={() => {
            dispatch(textToSpeechStatusSet('pending'));
            const timeStr = dayjs(`2023-01-01 ${time}`, 'YYYY-MM-DD HH:mm').format('hA')
            sendTextToSpeech(`Day ${dayIndex + 1}, ${timeStr}. ${activity}`)
          }}
          p={2}
          key={idx}
        >
          <Text>{time} - {activity} ({cost})</Text>
          {googlePlace && (
            <NavLink
              className="linkButton"
              state={{
                description: placeName,
                mapNav: true,
              }}
              to={`/wooorld/tablet/tripStop/${googlePlace.place_id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton icon={<MdPlace/>}/>
            </NavLink>
          )}
        </Card>
      ))}
    </Card>);
};

export default TripDay;
