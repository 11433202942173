import {Box, Center, IconButton, Image, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import HasPermission from '../../components/HasPermission';
import {exploreGoogle, usePermissions} from '../../hooks/usePermissions';
import {sendAudioClick} from '../../vuplex';
import Carousel from './Carousel';
import UpgradeButton from './UpgradeButton';

const PlacePhotos = ({firstPhotoOverride, photos}) => {
  const hasPermission = usePermissions(exploreGoogle);

  const allPhotos = firstPhotoOverride && firstPhotoOverride !== _.get(photos, '0.photoUrl') ? [{
    photoUrl: firstPhotoOverride,
  }, ...photos] : photos;

  return allPhotos.length ? (
    <Box
      borderRadius="2xl"
      h="17em"
      overflow="hidden"
      position="relative"
    >
      <Box
        borderRadius="2xl"
        overflow="hidden"
        p={0}
        sx={{
          '.carousel .control-dots': {
            bottom: '1.5em',
            margin: 0,
            transform: 'scale(0.5)'
          },
        }}
        w="17em"
      >
        <Carousel
          renderItem={(item, {isSelected, isPrevious}) => {
            return isSelected ? item : null;
          }}
          renderArrowNext={(onClick, hasNext) =>
            hasNext && (
              <IconButton
                _disabled={{bg: 'none'}}
                bg="none"
                bottom="1em"
                color="white"
                icon={<MdChevronRight size="2em"/>}
                isDisabled={!hasPermission}
                isRound
                onClick={(evt) => {
                  sendAudioClick(evt);
                  onClick(evt);
                }}
                position="absolute"
                right={2}
                variant="buttonMinor"
                zIndex={2}
              />
            )}
          renderArrowPrev={(onClick, hasPrev) =>
            hasPrev && (
              <IconButton
                _disabled={{bg: 'none'}}
                bg="none"
                bottom="1em"
                color="white"
                icon={<MdChevronLeft size="2em"/>}
                isDisabled={!hasPermission}
                isRound
                left={2}
                onClick={(evt) => {
                  sendAudioClick(evt);
                  onClick(evt);
                }}
                position="absolute"
                variant="buttonMinor"
                zIndex={2}
              />
            )}
        >
          {allPhotos.map(({photoUrl, html_attributions}, idx) => (
            <React.Fragment key={idx}>
              <Image
                borderRadius="2xl"

                fit="cover"
                h="17em"
                p="none"
                w="17em"
                // src={photoUrl}
                src={hasPermission ? photoUrl : allPhotos[0].photoUrl} // just in case they can swipe to next photo

                // w="unset!important"
              />

              <Center>
                <Text
                  // bg="blackAlpha.700"
                  borderRadius="xl"
                  bottom={0}
                  color="greyAccent"
                  textShadow={`0px 1px 2px #000a`}
                  dangerouslySetInnerHTML={{__html: hasPermission ? html_attributions : allPhotos[0].html_attributions}}
                  fontSize="12px"
                  p="0.2em"
                  paddingX={2}
                  pointerEvents="none" // html_attributions typically has a link to google maps which kills the experience
                  position="absolute"
                />
              </Center>

            </React.Fragment>
          ))}
        </Carousel>
      </Box>

      <HasPermission
        fallbackContent={
          <Center>
            <UpgradeButton
              bottom="1em"
              descriptor="PlacePhotos"
              packageName="explorer+"
              position="absolute"
              zIndex="sticky"
            />
          </Center>
        }
        permission={exploreGoogle}
      />
    </Box>
  ) : null;
};

export default PlacePhotos;
