import React from 'react';
import {MdList, MdPersonPinCircle, MdPlace, MdWrongLocation} from 'react-icons/md';
import InAppTooltip from './UnityReactComponents/InAppTooltip';

const PermissionBadge = ({
  isPin,
  isPrivate,
  isShared,
  isOwnedByUser,
  ownerAlias,
  size,
}) => {
  const color = isShared
    ? '#FFC002'
    : isPrivate ? '#FE8181'
      : '#5EC374';


  const props = {color, size};

  const listOwnership = `owned by ${isOwnedByUser ? 'you' : ownerAlias}`;

  const tooltipMessage = isPin
    ? isShared
      ? 'Place is visible to you and others in this world'
      : isPrivate
        ? 'Place is only visible to you'
        : 'Place is visible to everyone in app'
    : isShared
      ? `List ${listOwnership}, contents visible to you and others in this world`
      : isPrivate
        ? `List ${listOwnership}, contents only visible to you`
        : `List ${listOwnership}, contents visible to everyone in app`;

  return (
    <InAppTooltip message={tooltipMessage}>
      {
        isPin
          ? isShared
            ? <MdPersonPinCircle {...props} />
            : isPrivate
              ? <MdWrongLocation {...props}/>
              : <MdPlace {...props}/>
          : isShared // list
            ? <MdList {...props} />
            : isPrivate
              ? <MdList {...props}/>
              : <MdList {...props}/>
      }
    </InAppTooltip>
  );
};
// shared #FFC002

export default PermissionBadge;
