import {Box, Center, Slide, Spinner} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

const BottomLoadingIndicator = ({isLoading}) => {
  const [showSpinner, setShowSpinner] = useState(isLoading);
  useEffect(() => {
    if (isLoading) setShowSpinner(true);
    else setTimeout(() => setShowSpinner(false), 500);
  }, [isLoading]);

  return (
    <Slide
      direction="bottom"
      style={{zIndex: 10}}
      in={showSpinner}
    >
      <Box
        bg="whiteAlpha.600"
        p={2}
        w="full"
      >
        <Center>
          <Spinner
            color="wooorldBlue._"
            emptyColor="gray.200"
            size="xl"
            speed="0.65s"
            thickness="3px"
          />
        </Center>
      </Box>
    </Slide>
  );
};

export default BottomLoadingIndicator;
