import gooodReducer from './gooodReducer';
import inAppReducer from './inAppReducer';
import pinsReducer from './pinsReducer';
import placesReducer from './placesReducer';
import toursReducer from './toursReducer';
import uiReducer from './uiReducer';
import worldsReducer from './worldsReducer';

export default {
  goood: gooodReducer,
  inApp: inAppReducer,
  pins: pinsReducer,
  places: placesReducer,
  tours: toursReducer,
  ui: uiReducer,
  worlds: worldsReducer,
};
