import React from 'react';
import {useInAppTooltip} from '../../../hooks/useInAppTooltip';

const InAppTooltip = ({children, message}) => {
  const tooltipProps = useInAppTooltip(message);

  return (
    <span
      // title={message}/* for developing directly in browser, mouseover should show a native alt text */
      {...tooltipProps}
    >
      {children}
    </span>
  );
};

export default InAppTooltip;
