import {Box, Button, Card, CardBody, Center, Flex, Heading, IconButton, Image, Stack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BsWikipedia} from 'react-icons/bs';
import {MdPlace} from 'react-icons/md';
import {NavLink, useNavigate} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {useInAppTooltip} from '../../hooks/useInAppTooltip';
import {sendAudioClick, sendAudioHover, sendNavTo} from '../../vuplex';
import InAppMapPin from './UnityReactComponents/InAppMapPin';
import InAppTooltip from './UnityReactComponents/InAppTooltip';
import MapNavButton from './MapNavButton';

const GenericCard = ({
  backgroundColor,
  backgroundColorHeader,
  description,
  detailsPath,
  iconUrl,
  imageFit = 'cover',
  isPhotoBackground,
  latitude,
  longitude,
  name,
  onHoverPin,
  photoUrl,
  pinTooltip,
  placeType,
  ...others
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isPinHovering, setIsPinHovering] = useState(false);

  const navigate = useNavigate();
  const showDescription = () => {
    navigate(detailsPath, {
      state: {description: name, isExploreNearby: true, mapNav: false},
    });
  };

  const wikiLinkTooltip = useInAppTooltip(`Learn more about ${name}`);

  return (
    <Card
      bg={backgroundColor}
      {...isPhotoBackground && photoUrl && {
        backgroundImage: `url('${photoUrl}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      borderRadius="2xl"
      // height={220}
      onPointerEnter={(e) => {
        sendAudioHover(e);
        setIsHovering(true);
      }}
      onPointerLeave={() => {
        setTimeout(() => setIsHovering(false), 100);
      }}
      overflow="hidden"
      shadow={isPinHovering ? `outline` : null}
      // width="23%"
      {...others}
    >
      <CardBody p={0} overflow="hidden">
        {!isPhotoBackground && (
          <Image
            bg={backgroundColorHeader}
            // fallbackSrc="smiley.png"
            src={photoUrl || iconUrl}
            fit={imageFit}
            h="3rem"
            w="100%"
          />
        )}

        {isPhotoBackground
          ? !isHovering
            ? (
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                h="full"
              >
                <Box
                  bg="blackAlpha.600"
                  color="white"
                  fontWeight={400}
                  noOfLines={2}
                  p={2}
                  textAlign="center"
                >
                  {name}
                </Box>
              </Flex>
            )
            : (
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                h="full"
              >
                <Flex p={0} grow={1} gap={4} alignItems="center" justifyContent="center">
                  <NavLink
                    state={{description: name, mapNav: false, isExploreNearby: true}}
                    to={detailsPath}
                  >
                    <IconButton
                      {...wikiLinkTooltip}
                      icon={<BsWikipedia size="1.5em"/>}
                      isRound
                      onClick={sendAudioClick}
                      size="lg"
                      variant="buttonMinor"
                    />
                  </NavLink>
                  {latitude && longitude && (
                    <MapNavButton
                      isExploreNearby
                      latitude={latitude}
                      longitude={longitude}
                      name={name}
                      size="lg"
                    />)}
                </Flex>
                {description && (
                  <Flex
                    bg="blackAlpha.600"
                    color="white"
                    fontWeight={400}
                    noOfLines={2}
                    p={2}
                    textAlign="center"
                  >
                    {description}
                  </Flex>
                )}
              </Flex>
            )
          : (
            <>
              <Stack p={2} spacing={3}>
                <Heading
                  fontWeight={400}
                  noOfLines={2}
                  paddingBottom=".1em"
                  size="sm"
                >
                  {name}
                </Heading>
                {description}
              </Stack>
              {isHovering && (
                <Flex
                  backgroundColor="blackAlpha.800"
                  position="absolute"
                  direction="column"
                  h="full"
                  w="full"
                  top={0}
                >
                  <Center flex={1}>
                    <InAppTooltip message={`Learn more about ${name}`}>
                      <NavLink
                        state={{description: name, mapNav: false, isExploreNearby: true}}
                        to={detailsPath}
                      >
                        <ButtonMinor>Learn More</ButtonMinor>
                      </NavLink>
                    </InAppTooltip>
                  </Center>
                  {latitude && longitude && (
                    <Center flex={1}>
                      <MapNavButton
                        isExploreNearby
                        latitude={latitude}
                        longitude={longitude}
                        name={name}
                      />
                    </Center>)}
                </Flex>
              )
              }
            </>

          )}


      </CardBody>

      <InAppMapPin
        color={backgroundColor}
        iconUrl={iconUrl}
        isHovering={isHovering}
        label={name}
        latitude={latitude}
        longitude={longitude}
        onClick={() => {
          showDescription();
        }}
        onHoverEnter={() => {
          setIsPinHovering(true);
          if (onHoverPin) onHoverPin();
        }}
        onHoverExit={() => setIsPinHovering(false)}
        placeType={placeType}
        tooltip={pinTooltip}
      />
    </Card>
  );
};

export default GenericCard;
