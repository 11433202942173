import {Box, Flex, SimpleGrid} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {FaPeopleRoof} from 'react-icons/fa6';
import {MdPersonAdd, MdPersonAddDisabled} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import ContentSeparatorCta from '../../components/ContentSeparatorCta';
import {useLobbyHelp} from '../../hooks/useLobbyHelp';
import {useVuplex} from '../../hooks/useVuplex';
import {helpStepSet, userIdsBlockedByUserSet, worldsSet} from '../../reducers/worldsReducer';
import {sendBlockedUserIdsRequest, sendLobbyJoin, sendWorldInvite, sendWorldsRequest} from '../../vuplex';
import PageTitle from './PageTitle';
import WorldCard from './WorldCard';
import WorldCreationCard from './WorldCreationCard';
import WorldJoinWithCode from './WorldJoinWithCode';
// import {testWorlds} from '../../helpers/testWorlds';

const Worlds = ({}) => {
  const currentWorld = useSelector(s => s.inApp.world) || {};
  const worlds = useSelector(s => s.worlds.worlds);
  const dispatch = useDispatch();

  const isCurrentWorldNotInList = currentWorld.isLobby || _.get(currentWorld.rules, 'isSolo') || _.get(currentWorld.rules, 'isPrivate');

  useVuplex({
    blockedUsers: ({blockedUserIds}) => {
      dispatch(userIdsBlockedByUserSet(blockedUserIds));
    },
    lobbyHelp: ({helpStep}) => {
      dispatch(helpStepSet(helpStep));
    },
    worldList: ({worlds}) => {
      dispatch(worldsSet(worlds));
    },
  });

  useEffect(() => {
    sendBlockedUserIdsRequest();
    sendWorldsRequest();
    const intervalId = setInterval(sendWorldsRequest, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const isHelpStep3 = useLobbyHelp(3);

  return (
    <>
      <PageTitle title="Worlds - Wooorld"/>

      <Flex p={4} justifyContent="space-between">
        { // Back to Lobby
          !currentWorld.isLobby ? (
              <ButtonMinor
                leftIcon={<FaPeopleRoof/>}
                onClick={() => {
                  sendLobbyJoin();
                }}
              >
                Back to Lobby
              </ButtonMinor>
            )
            : <Box/> // just for flex space-between layout
        }

        { // Invite People
          !_.get(currentWorld, 'rules.isSolo') && !currentWorld.isFull && (
            <ButtonMinor
              disabledReason={currentWorld.isFull ? `This ${currentWorld.isLobby ? 'lobby' : 'world'} is full.` : null}
              isRound
              leftIcon={currentWorld.isFull ? <MdPersonAddDisabled/> : <MdPersonAdd/>}
              onClick={() => {
                sendWorldInvite();
              }}
            >
              Invite People
            </ButtonMinor>
          )}
      </Flex>

      <SimpleGrid
        columns={2}
        rowGap={2}
        spacing={12}
        p={8}
        paddingX={12}
        position="relative"
      >
        <WorldCreationCard/>
        <WorldCreationCard isWitw/>
      </SimpleGrid>

      <Flex p={4} justifyContent="flex-end" marginBottom="-4em">
        <WorldJoinWithCode />
      </Flex>

      <ContentSeparatorCta>
        or join an existing world:
      </ContentSeparatorCta>



      <SimpleGrid
        autoRows="max-content"
        bgGradient={isHelpStep3 ? null : `linear(135deg, #C5EAEF, #D2C1EE)`}
        bg={isHelpStep3 ? '#FFF155' : null}
        borderRadius="2xl"
        columns={3}
        rowGap={2}
        // minH="11.5em"
        spacing={2}
        p={2}
        position="relative"
      >
        {isCurrentWorldNotInList && <WorldCard world={currentWorld}/>}
        {worlds.map(world => <WorldCard key={world._id} world={world}/>)}
      </SimpleGrid>
    </>
  );


};

export default Worlds;
