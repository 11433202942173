import {Tag, TagLeftIcon} from '@chakra-ui/react';
import {FaThumbsDown, FaThumbsUp} from 'react-icons/fa';

const LikesBadge = ({
  bg,
  color,
  isDislikedByUser,
  isLikedByUser,
  likeCount,
  size,
}) => likeCount
  ? (
    <Tag
      bg={bg}
      border={isLikedByUser || isDislikedByUser ? 'solid 1px' : 'none'}
      borderColor={isDislikedByUser ? 'red' : isLikedByUser ? 'wooorldBlue._' : 'none'}
      borderRadius="full"
      color={color}
      size={size}
      variant="subtle"
    >
      <TagLeftIcon as={likeCount > 0 ? FaThumbsUp : FaThumbsDown} color={likeCount < 0 ? 'red' : undefined} />
      {likeCount}
    </Tag>

  )
  : null;

export default LikesBadge;
