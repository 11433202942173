import _ from 'lodash';
import {useEffect} from 'react';
import {useComponentId} from '../../../hooks/useComponentId';
import {useVuplex} from '../../../hooks/useVuplex';
import {getVuplexMessageHandlers, sendPin, sendPinEvent, sendPinUnmount} from '../../../vuplex';

const InAppMapPin = ({
  color,
  iconUrl,
  isHovering, // hovered in web
  label,
  latitude,
  longitude,
  onClick,
  onHoverEnter, // hovered in app
  onHoverExit, // hovered in app
  placeType,
  tooltip,
}) => {
  const componentId = useComponentId();

  const isGif = /\.gif$/i.test(iconUrl);

  useEffect(() => {

    sendPin({
      color,
      componentId,
      label,
      latitude,
      longitude,
      placeType,
      tooltip,

      ...isGif ? {} : { // Unity can't handle .gif
        iconUrl,
      },
    });

    return () => sendPinUnmount(componentId);
  }, [componentId, latitude, longitude]);

  useEffect(() => {
    sendPinEvent({
      componentId,
      event: isHovering ? 'hoverEnter' : 'hoverExit',
    });
  }, [isHovering]);

  useVuplex({
    onClick: (callerComponentId) => {
      if (componentId === callerComponentId && _.isFunction(onClick)) onClick();
    },
    onHoverEnter: (callerComponentId) => {
      if (componentId === callerComponentId && _.isFunction(onHoverEnter)) onHoverEnter();
    },
    onHoverExit: (callerComponentId) => {
      if (componentId === callerComponentId && _.isFunction(onHoverExit)) onHoverExit();
    },
  }, [onClick]);

  return null;
};

export default InAppMapPin;
