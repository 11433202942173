import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader, Checkbox,
  Code,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton, Input, Select,
  Stack,
  Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {BiListPlus} from 'react-icons/bi';
import {IoIosBasketball} from 'react-icons/io';
import {useSelector} from 'react-redux';
import {apiPost} from '../../api';
import {useVuplex} from '../../hooks/useVuplex';
import {
  sendBasketball,
  sendDevConsoleClearRequest,
  sendDevConsoleDictationActivate,
  sendDevConsoleEntriesRequest, sendDevConsoleMethodsRequest
} from '../../vuplex';
import DevConsoleCallStackDrawer from './DevConsoleCallStackDrawer';
import InAppWebBrowser from './UnityReactComponents/InAppWebBrowser';
import InputBar from './InputBar';
import PageTitle from './PageTitle';
import WebBookmarkPoimandresMarket from './WebBookmarkPoimandresMarket';
import WebBrowserLauncher from './WebBrowserLaunchButton';

const scrollToBottom = () => window.scrollTo(0, document.body.scrollHeight);


const DevConsole = ({}) => {
  const [showEntries, setShowEntries] = useState({
    Dictation: true,
    DictationPartial: false,
    Log: true,
    Warning: true,
    Error: true,
  });
  const [autoScroll, setAutoScroll] = useState(true);
  const [browsers, setBrowsers] = useState([]);
  const [entries, setEntries] = useState([]);
  const [isGodModeOn, setIsGodModeOn] = useState(false);
  const [methods, setMethods] = useState([]);
  const [stackTraceEntry, setStackTraceEntry] = useState(null);
  const [tagSelected, setTagSelected] = useState('');
  const [tags, setTags] = useState([]);
  const textInputField = useSelector(s => s.ui.txtInputDevConsole.textInputField);

  useVuplex({
    devMethods: (methods) => {
      setMethods(methods);
    },
    logEntries: ({logEntries}) => {
      setEntries((currEntries) => [
        ...currEntries,
        ...logEntries,
      ]);
    },
    logEntry: ({logEntry}) => {
      setEntries((currEntries) => [
        ...currEntries,
        logEntry,
      ]);
    },
    tags: ({tags}) => {
      setTags(tags);
    },
  });

  useEffect(() => {
    sendDevConsoleEntriesRequest(entries.length);
  }, []);

  useEffect(() => {
    if (autoScroll) scrollToBottom();
  }, [autoScroll, entries]);

  // TODO: turn off autoscroll when scrolling
  useEffect(() => {
    const onScroll = (e) => {
      // console.log('scroll', e)
      // TODO: autoScroll = false when user scrolls
      //  but no easy way to determine if scroll originated from user or autoscroll
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const toast = useToast();


  const handleSaveClick = async () => {
    const res = await apiPost(`/users/devConsoleSave`, entries).catch(err => {
      toast({
        description: err.message,
        title: `Failed to save entries`,
        status: 'error',
      });
    });

    toast({
      description: `${entries.length} entries saved to DB`,
      status: 'success',
    });

  };

  const tabsHeight = '2em';

  return (
    <>
      <PageTitle title="Dev Console - Wooorld"/>


      <Tabs onChange={(i) => {
        setAutoScroll(i === 0);
      }}>
        <TabList
          position="fixed"
          h={tabsHeight}
          w="full"
          p={2}
          top={0}
          bg="bg"
        >
          <Tab>Console</Tab>
          <Tab>Browsers</Tab>
          <Tab>Methods</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <InputBar stateKey="txtInputDevConsole" top={tabsHeight}>
              <Stack w="full">
                <Flex justifyContent="space-between">
                  <Flex gap={2} justifyContent="flex-start">
                    <Button
                      onClick={() => {
                        setIsGodModeOn(!isGodModeOn);
                      }}
                    >
                      😇 {isGodModeOn ? 'Close' : 'Open'} Goood
                    </Button>

                    {isGodModeOn && (
                      <InAppWebBrowser
                        url={`https://${window.location.host}/goood`}
                      />)}

                    <Button
                      onClick={sendDevConsoleDictationActivate}
                    >
                      🗣 Start Dictation
                    </Button>
                  </Flex>


                  <Flex gap={2} justifyContent="flex-end">
                    <Button
                      onClick={(e) => {
                        sendDevConsoleEntriesRequest(entries.length);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      🤗 Get
                    </Button>

                    <Button
                      onClick={handleSaveClick}
                    >
                      💾 Save
                    </Button>

                    <Button
                      onClick={(e) => {
                        sendDevConsoleClearRequest();
                        setEntries([]);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      ❌ Clear
                    </Button>


                    <FormControl display="flex" alignItems="center" w="auto">
                      <FormLabel htmlFor="autoScroll" mb={0} mr={1}>
                        Scroll
                      </FormLabel>
                      <Switch
                        id="autoScroll"
                        isChecked={autoScroll}
                        onChange={({target: {checked}}) => {
                          setAutoScroll(checked);
                          if (checked) scrollToBottom();
                        }}
                      />
                    </FormControl>
                  </Flex>
                </Flex>


                <Flex gap={2} paddingTop={2} justifyContent="space-between">

                  <Select
                    colorScheme="wooorldBlue"
                    onChange={({target: {value}}) => setTagSelected(value)}
                    placeholder="filter by tag"
                    value={tagSelected}
                  >
                    {tags.map(t => <option key={t} value={t}>{t}</option>)}
                  </Select>


                  <Flex gap={3}>
                    {
                      ['Log', 'Warning', 'Error', 'Dictation', 'DictationPartial'].map(entryType => {
                        const colorScheme = { // https://v2.chakra-ui.com/docs/styled-system/theme#colors
                          Error: 'red',
                          Log: 'cyan',
                          Warning: 'yellow',
                          Dictation: 'green',
                          DictationPartial: 'teal'
                        }[entryType];

                        return (
                          <FormControl key={entryType} display="flex" alignItems="center" w="auto">
                            <FormLabel color={`${colorScheme}.700`} htmlFor={entryType} mb={0} mr={1}>
                              {entryType}
                            </FormLabel>
                            <Switch
                              colorScheme={colorScheme}
                              id={entryType}
                              isChecked={showEntries[entryType]}
                              onChange={({target: {checked}}) => {
                                setShowEntries({
                                  ...showEntries,
                                  [entryType]: checked,
                                });
                              }}
                            />
                          </FormControl>
                        );
                      })
                    }
                  </Flex>


                </Flex>
              </Stack>

            </InputBar>


            <Stack marginTop={tabsHeight}>
              {entries
                .map((e, i) => ({
                  ...e,
                  ix: i,
                  Message: e.Message.replaceAll(/\<color=#.{6}\>|\<\/color\>/g, ''),
                }))
                .filter(({Message}) => !tagSelected || _.startsWith(Message, `[${tagSelected}]`))
                .filter(({LogType, Message}) => showEntries[LogType] && new RegExp(textInputField, 'i').test(Message))
                .map((entry) => {
                  const {LogType, Message, StackTrace, ix} = entry;

                  return (
                    <Code
                      bg={{
                        Error: '#FAA',
                        Dictation: '#AFA',
                        DictationPartial: '#AFC',
                        Log: '#AEF',
                        Warning: '#FEA'
                      }[LogType]}
                      key={ix}
                      onClick={() => {
                        setStackTraceEntry(entry);
                      }}
                    >
                      [{ix}]-> {Message}
                    </Code>
                  );
                })}
            </Stack>

          </TabPanel>
          <TabPanel paddingTop={tabsHeight}>
            <Stack>
              <Card bg="azure">
                <CardHeader>
                  <Heading size="sm">Launch a realtime browser</Heading>
                </CardHeader>
                <CardBody>
                  <Stack>
                    {browsers.map(key => (
                      <WebBrowserLauncher
                        key={key}
                        onClose={() => {
                          setBrowsers(browsers => _.without(browsers, key));
                        }}
                      />))
                    }
                  </Stack>
                </CardBody>

                <CardFooter>
                  <Flex w="full" justifyContent="space-between">
                    <IconButton
                      icon={<BiListPlus size="lg"/>}
                      onClick={() => {
                        setBrowsers(browsers => [...browsers, _.uniqueId('browser')]);
                      }}
                    />
                  </Flex>
                </CardFooter>
              </Card>

              <Card bg="linen">
                <CardHeader>
                  <Heading size="sm">Other Tchotchkes</Heading>
                </CardHeader>

                <CardBody>
                  <Flex gap={5}>
                    <IconButton color="orange" icon={<IoIosBasketball size="lg"/>} onClick={sendBasketball}/>
                    <WebBookmarkPoimandresMarket/>
                  </Flex>
                </CardBody>

                <CardFooter>

                </CardFooter>
              </Card>
            </Stack>


            <DevConsoleCallStackDrawer
              entry={stackTraceEntry}
              onClose={() => {
                setStackTraceEntry(null);
              }}
            />

          </TabPanel>
          <TabPanel paddingTop={tabsHeight}>
            <Button
              onClick={sendDevConsoleMethodsRequest}
            >
              ⌨ Methods
            </Button>

            (Not yet implemented)

            <Stack>
              {methods.map((method, mKey) => (
                <Card key={mKey}>
                  <CardHeader>
                    <Heading size="sm">{method.name}</Heading>
                    {method.instruction && <Text>{method.instruction}</Text>}
                  </CardHeader>
                  {method.parameters.length > 0 && (
                    <CardBody>
                      {method.parameters.map((param, pKey) => (
                        <FormControl key={pKey}>
                          <FormLabel>{param.name}</FormLabel>
                          {{
                            Boolean: <Checkbox/>,
                            Int32: <Input type="number" step={1}/>,
                            Single: <Input type="number"/>,
                            String: <Input/>,
                          }[param.type]}
                        </FormControl>
                      ))}
                    </CardBody>
                  )}

                  <CardFooter>
                    <Button
                      // onClick={}
                    >
                      Execute
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );


};

export default DevConsole;
