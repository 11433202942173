import {RepeatIcon} from '@chakra-ui/icons';
import {Box, Button, Center, Slide} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';

const BottomErrorIndicator = ({ isError, onRetry }) => {
  useEffect(() => {
    if (isError) setTimeout(onRetry, 10000);
  }, [isError]);


  return (
    <Slide
      direction="bottom"
      style={{zIndex: 10}}
      in={isError}
    >
      <Box
        bg="whiteAlpha.800"
        w="full"
      >
        <Center>
          <Button
            colorScheme="red"
            leftIcon={<RepeatIcon/>}
            onClick={(e) => {
              onRetry();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Retry
          </Button>
        </Center>
      </Box>
    </Slide>
  );
}

export default BottomErrorIndicator;
