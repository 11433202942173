import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteList from '../../components/InfiniteList';
import PinCard from './PinCard';

// noinspection JSCheckFunctionSignatures
const PinsList = ({
  columns,
  isFiltered,
  onRequestPins,
  stateKey,
  textInputStateKey,
}) => {
  const {
    error,
    isLoading,
    response: pinsSearchResponse,
  } = useSelector(s => s.pins[stateKey]);

  const {textInputField} = useSelector(s => s.ui[textInputStateKey]) || {};
  const {hasNextPage, page, pins = []} = pinsSearchResponse || {};

  const unsavedTempPin = useSelector(s => s.pins.pinUnsavedTemp);
  const isUnsavedPinOwned = unsavedTempPin.streetViewId && !!pins.find(p => p.isOwnedByUser);


  const dispatch = useDispatch();

  return (
    <InfiniteList
      {...{
        columns,
        error,
        isFiltered,
        isLoading,
        hasNextPage,
        page,
        items: unsavedTempPin.streetViewId && !isUnsavedPinOwned ? [unsavedTempPin, ...pins] : pins,
      }}
      onFetchNextPage={() => dispatch(onRequestPins({
        isNew: !hasNextPage,
        searchString: textInputField
      }))}
      renderItem={(pin) =>
        <PinCard
          key={pin._id}
          pin={!pin._id ? { // handle s.pins.pinUnsavedTemp
            ...pin,
            label: '',
            isUnsavedPinOwned,
          } : pin}
        />}
    />
  );
};

export default PinsList;
