import {ChevronDownIcon, CloseIcon} from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text
} from '@chakra-ui/react';
import React from 'react';

// https://github.com/chakra-ui/chakra-ui/issues/1293#issuecomment-1599296636
const MultiSelect = ({ title, options = [], onChange = () => {}, values = [] }) => {
  const handleOptionSelect = (selected) => {
    const list = Array.isArray(selected)
      ? selected
      : [...values, selected];

    onChange(list);
  };

  const handleClear = () => {
    onChange([]);
  };

  return (
    <Flex direction={"column"}>
      <Flex gap="4" align={"center"}>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            colorScheme="blue"
            variant={"outline"}
            rightIcon={<ChevronDownIcon />}
            size="md"
            maxW={"2xs"}
          >
            <Flex align="center" gap="2">
              <Text noOfLines={1}>{title}</Text>

              <Badge bg="blue.600" color="white">
                {values.length}
              </Badge>
            </Flex>
          </MenuButton>
          <MenuList minWidth="xs" maxW={"sm"}>
            <MenuOptionGroup
              value={values}
              onChange={handleOptionSelect}
              type="checkbox"
            >
              <Flex direction={"column"} gap="2" px="4" py="2">
                <Flex justify={"space-between"} align="center">
                  <Flex gap="2" align={"center"}>
                    <Text size="md" noOfLines={1} fontWeight={"medium"}>
                      {title}
                    </Text>
                  </Flex>
                  <Flex flexShrink={0} gap="2">
                    <Button
                      size="xs"
                      variant="link"
                      colorScheme="blue"
                      onClick={() => handleOptionSelect(options.map(o => o.value))}
                    >
                      Select All
                    </Button>

                    <Button
                      size="xs"
                      variant="link"
                      colorScheme="blue"
                      onClick={handleClear}
                    >
                      Clear
                    </Button>
                  </Flex>
                </Flex>
              </Flex>

              <Divider />

              {options.map(({label, value}) => (
                <MenuItemOption
                  key={value}
                  value={value}
                  _focus={{ bg: "wooorldBlue.100" }}
                  _hover={{ bg: "wooorldBlue.100" }}
                  transition={"background 0.3s ease"}
                >
                  {label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        {values.length > 0 && (
          <IconButton
            aria-label="Clear"
            size="xs"
            colorScheme="blue"
            rounded={"full"}
            icon={<CloseIcon />}
            ml="-8"
            mt="-8"
            onClick={handleClear}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default MultiSelect;
