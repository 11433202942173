import React from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {useVuplex} from '../../hooks/useVuplex';
import {pinsSearchIdsSet} from '../../reducers/pinsReducer';
import FoldersView from './FoldersView';
import PageTitle from './PageTitle';
import PinsFoldersToggle from './PinsFoldersToggle';
import PinsPanoIdSearchView from './PinsPanoIdSearchView';
import PinsView from './PinsView';
import PinsVuplex from './PinsVuplex';

const Pins = ({}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useVuplex({
    placesSearchRequest: ({PlaceIds}) => {
      navigate('pins')
      console.log(PlaceIds)
      dispatch(pinsSearchIdsSet(PlaceIds));
    },
  })

  return (
    <>
      <PageTitle title="Places - Wooorld"/>

      <Routes>
        <Route path="/panoId/*" element={null}/>
        <Route path="*" element={<PinsFoldersToggle/>} />
      </Routes>

      <Routes>
        <Route path="/panoId/*" element={null}/>
        <Route path="edit" element={null}/>
      </Routes>


      <Routes>
        <Route path="/panoId/:panoId" element={<PinsPanoIdSearchView/>}/>
        <Route path="/pins/*" element={<PinsView/>}/>
        <Route path="/folders/*" element={<FoldersView/>}/>
        <Route path="*" element={<Navigate to="pins" replace={true}/>}/>
      </Routes>


      <PinsVuplex/>
    </>
  );
};

export default Pins;
