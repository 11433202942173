import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useVuplex} from './useVuplex';

export const useRetryOnAccessTokenRefresh = (thunk, dependencies = []) => {
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    const callThunk = async () => {
      const res = await thunk();
      if (res && res.error) {
        console.warn('useRetryOnAccessTokenRefresh: thunk had error. Will retry on next auth', res.error);
        setRetry(true);
      }
    }
    callThunk();
  }, dependencies);

  useVuplex({
    auth: () => {
      if (retry) {
        console.warn('useRetryOnAccessTokenRefresh: retrying thunk')
        _.defer(thunk);
        setRetry(false);
      }
    }
  }, [retry]);
};
