import {AbsoluteCenter} from '@chakra-ui/react';
import {AdvancedMarker, useAdvancedMarkerRef, useMap} from '@vis.gl/react-google-maps';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useInAppTooltip} from '../../../hooks/useInAppTooltip';
import {sendPanoSave, sendPanoUnload} from '../../../vuplex';
import PanoIcon from './PanoIcon';

const MinimapPanoMarker = ({onClick}) => {
  const [refCallback, marker] = useAdvancedMarkerRef();
  const map = useMap();

  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);

  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    setIsUnloading(false);
    if (panoId) {
      // sendPanoSave({
      //   lat: panoLocation.latitude,
      //   lon: panoLocation.longitude,
      //   panoId,
      // });
    }
  }, [panoId, panoLocation]);

  const tooltipProps = useInAppTooltip(`Currently loaded 360`);

  return panoLocation && panoId && !isUnloading && (
    <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
      onClick={(e) => {
        // a little hack to prevent blocking clicks on map within this rect
        if (e.domEvent.target.type === 'button') e.stop();
      }}
      position={{lat: panoLocation.latitude, lng: panoLocation.longitude}}
      title={`panoId: ${panoId}`}
      zIndex={1}
    >
      <AbsoluteCenter pointerEvents="none">
        <PanoIcon
          isActive
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onClick) onClick();
          }}
          size="5em"
        />
      </AbsoluteCenter>

      {/*<AbsoluteCenter top="4em" position="absolute">*/}
      {/*  <Flex>*/}
      {/*    <ButtonMinor*/}
      {/*      onClick={(e) => {*/}
      {/*        e.preventDefault();*/}
      {/*        e.stopPropagation();*/}
      {/*        sendPanoUnload();*/}
      {/*        setIsUnloading(true); // unload transition before updating us*/}
      {/*      }}*/}
      {/*      marginTop={6}*/}
      {/*      shadow="dark-lg"*/}
      {/*      size="sm"*/}
      {/*    >*/}
      {/*      Unload*/}
      {/*    </ButtonMinor>*/}

      {/*    <ButtonMinor*/}
      {/*      onClick={(e) => {*/}
      {/*        e.preventDefault();*/}
      {/*        e.stopPropagation();*/}
      {/*        sendPanoSave({*/}
      {/*          lat: panoLocation.latitude,*/}
      {/*          lon: panoLocation.longitude,*/}
      {/*          panoId,*/}
      {/*        });*/}
      {/*      }}*/}
      {/*      marginTop={6}*/}
      {/*      shadow="dark-lg"*/}
      {/*      size="sm"*/}
      {/*    >*/}
      {/*      Save*/}
      {/*    </ButtonMinor>*/}
      {/*  </Flex>*/}

      {/*</AbsoluteCenter>*/}

    </AdvancedMarker>
  );
};

export default MinimapPanoMarker;
