import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {InputGroup, InputLeftElement, InputRightElement} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag
} from '@choc-ui/chakra-autocomplete';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {placesListCreate, usePermissions} from '../../hooks/usePermissions';
import {foldersListUserRequest} from '../../reducers/pinsReducer';
import PermissionBadge from './PermissionBadge';
import UpgradeWrapper from './UpgradeWrapper';

const PinEditFoldersList = ({
  label,
  onFolderClick,
  selectedFolderId,
}) => {
  const {_id: worldId} = useSelector(s => s.inApp.world);
  const {response, isLoading} = useSelector(s => s.pins.foldersListUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!worldId) return;
    dispatch(foldersListUserRequest({worldId}));
  }, [worldId]);

  const canCreateFolder = usePermissions(placesListCreate);

  // show selected folder at front of list initially; don't sort when user selects a new folder
  const [selectedIdAtMount, setSelectIdAtMount] = useState();
  useEffect(() => {
    if (!selectedIdAtMount && selectedFolderId) setSelectIdAtMount(selectedFolderId);
  }, [selectedIdAtMount, selectedFolderId]);

  const folders = _.sortBy(response, (a, b) => a._id === selectedIdAtMount ? -1 : 0);

  const selectedFolder = _.find(folders, f => f._id === selectedFolderId);

  // in case we have selected some invalid folderId from localStorage for a folder that's since been deleted
  useEffect(() => {
    if (folders.length && selectedFolderId && !selectedFolder){
      onFolderClick(folders[0]);
    }
  }, [selectedFolder, selectedFolderId, folders]);

  const [listInputRef, setListInputRef] = useState(null);
// console.log(selectedFolderId)

  // https://github.com/anubra266/choc-autocomplete/issues/258
  const [disableFilter, setDisableFilter] = useState(false);

  // noinspection JSValidateTypes
  return (
    <AutoComplete // https://github.com/anubra266/choc-autocomplete
      // maxSuggestions={5}
      // prefocusFirstItem
      autoFocus={false}
      closeOnBlur
      creatable
      disableFilter={disableFilter}
      focusInputOnSelect={false}
      listAllValuesOnFocus={true}
      openOnFocus
      restoreOnBlurIfEmpty
      selectOnFocus
      suggestWhenEmpty

      onSelectOption={({item, selectMethod, isNewInput}) => {
        // console.log(item, selectMethod, isNewInput);
        onFolderClick(_.find(folders, (f) => f._id === item.value));
      }}
      value={response && selectedFolderId} // response && - to ensure selected item has a label
    >
      {({isOpen, onOpen, onClose}) => {

        return (
          <>
            <InputGroup>
              {selectedFolder && (
                <InputLeftElement>
                  <PermissionBadge
                    isOwnedByUser={selectedFolder.isOwnedByUser}
                    isPrivate={selectedFolder.isSolo}
                    isShared={selectedFolder.sharedInWorld === worldId}
                    ownerAlias={selectedFolder.ownerAlias}
                    size="2em"
                  />
                </InputLeftElement>)}

              <AutoCompleteInput
                onFocus={() => setDisableFilter(false)}
                ref={setListInputRef}
                variant="filled"
              >
                {({tags}) =>
                  tags.map((tag, tid) => (
                    <AutoCompleteTag
                      key={tid}
                      label={tag.label}
                      onRemove={tag.onRemove}
                    />
                  ))
                }
              </AutoCompleteInput>

              <InputRightElement>
                <ButtonMinor bg="none" onClick={() => {
                  if (isOpen) onClose();
                  else {
                    setDisableFilter(true);
                    onOpen();
                  }
                }}>
                  {isOpen
                    ? <ChevronUpIcon/>
                    : <ChevronDownIcon/>}
                </ButtonMinor>

              </InputRightElement>
            </InputGroup>

            <AutoCompleteList>
              {folders.map(({
                _id,
                isOwnedByUser,
                isSolo,
                label,
                ownerAlias,
                pinCount,
                presetName,
                sharedInWorld,
              }) => (
                <AutoCompleteItem
                  key={_id}
                  label={label}
                  fixed={!!presetName}
                  color="black24"
                  value={_id}
                  alignItems="center"
                >
                  <PermissionBadge
                    isOwnedByUser={isOwnedByUser}
                    isPrivate={isSolo}
                    isShared={sharedInWorld === worldId}
                    ownerAlias={ownerAlias}
                    size="2em"
                  />
                  &nbsp;
                  {label} ({pinCount || 0})
                </AutoCompleteItem>
              ))}
              <AutoCompleteCreatable
                disabled={!canCreateFolder}
                alignItems="center"
                _disabled={{
                  opacity: 1,
                }}
              >
                New Place List
                <UpgradeWrapper
                  descriptor="PinEditFoldersList"
                  permission={'placesListCreate'}
                  packageName={'explorer+'}
                  position="relative"
                  marginLeft={2}
                />
              </AutoCompleteCreatable>
            </AutoCompleteList>
          </>
        );
      }}
    </AutoComplete>
  );


};

export default PinEditFoldersList;
