import _ from 'lodash';
import {useSelector} from 'react-redux';

export const usePermissions = (permission) => {
  const permissions = useSelector(s => s.inApp.permissions);
  if (!permission) return true;
  return !!permissions[permission];
};

export const allowMapTiltExplore = 'allowMapTiltExplore';
export const allowMapTiltPlay = 'allowMapTiltPlay';
export const allowUnlinkExplore = 'allowUnlinkExplore';
export const allowUnlinkPlay = 'allowUnlinkPlay';
export const alphaAccess = 'alphaAccess';
export const avatarLighting = 'avatarLighting';
export const betaAccess = 'betaAccess';
export const devConsole = 'devConsole';
export const devPermissionToggle = 'devPermissionToggle';
export const devSettings = 'devSettings';
export const devUser = 'devUser';
export const devWorldAccess = 'devWorldAccess';
export const exploreGoogle = 'exploreGoogle';
export const exploreWiki = 'exploreWiki';
export const exploreWorldsDemo = 'exploreWorldsDemo';
export const exploreWorldsPrivate = 'exploreWorldsPrivate';
export const exploreWorldsPublic = 'exploreWorldsPublic';
export const exploreWorldsSolo = 'exploreWorldsSolo';
export const lobby = 'lobby';
export const lobbyDemo = 'lobbyDemo';
export const mapScaleSettings = 'mapScaleSettings';
export const mapStyles = 'mapStyles';
export const minimap360Layer = 'minimap360Layer';
export const minimapHeadingRotation = 'minimapHeadingRotation';
export const placesListCreate = 'placesListCreate';
export const playWorldsDemo = 'playWorldsDemo';
export const playWorldsPrivate = 'playWorldsPrivate';
export const playWorldsPublic = 'playWorldsPublic';
export const playWorldsSolo = 'playWorldsSolo';
export const timeline = 'timeline';
export const toursCreate = 'toursCreate';
export const toursCurated = 'toursCurated';
export const toursUserGenerated = 'toursUserGenerated';
export const witwCoop = 'witwCoop';
export const witwCreateBoostedGame = 'witwCreateBoostedGame';
export const witwCustomOptions = 'witwCustomOptions';
export const witwEU = 'witwEU';
export const witwLeaderboard = 'witwLeaderboard';
export const witwUSA = 'witwUSA';
export const witwVs = 'witwVs';

export const isPermissionPremium = (permission) => _.includes([
  allowMapTiltExplore,
  allowMapTiltPlay,
  allowUnlinkExplore,
  avatarLighting,
  exploreGoogle,
  exploreWorldsPrivate,
  exploreWorldsSolo,
  mapScaleSettings,
  mapStyles,
  minimap360Layer,
  minimapHeadingRotation,
  placesListCreate,
  playWorldsPrivate,
  playWorldsSolo,
  timeline,
  toursCreate,
  witwCreateBoostedGame,
  witwCustomOptions,
  witwEU,
  witwUSA,
  witwVs,
], permission);

const badges = [
  'alpha',
  'beta',
  'dev',
  'explorerPlus',
  'playerPlus',
  'ambassador',
];
