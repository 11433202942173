import React from 'react';
import {useNavigate} from 'react-router-dom';
import ButtonMinor from './ButtonMinor';

const BackButton = ({onClick = () => {}, others}) => {
  const navigate = useNavigate();

  return (
    <ButtonMinor
      onClick={() => {
        navigate(-1);
        onClick();
      }}
      {...others}
      // style={{
      //   fontSize: '1.5em',
      //   padding: '0.25em',
      //   position: 'absolute',
      //   top: '0.5em',
      //   right: '0.5em',
      // }}
    >
      Back
    </ButtonMinor>
  );
};

export default BackButton;
