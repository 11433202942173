import {Box, Card, CardBody, Flex, Heading, Text, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {exploreGoogle, usePermissions} from '../../hooks/usePermissions';
import {sendAudioClick} from '../../vuplex';

const PlaceAutoCompleteItem = ({
  description,
  place_id: placeId,
  structured_formatting: {
    main_text: mainText,
    secondary_text: secondaryText,
  }
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = `/wooorld/tablet/places/${placeId}`;
  const [isHovering, setIsHovering] = useState(false);

  const isStandard = !usePermissions(exploreGoogle);
  const autocompleteCredits = useSelector((s) => s.places.autoComplete.credits);
  const canAutocomplete = autocompleteCredits > 0;

  const toast = useToast();

  const handleCardClick = (e) => {
    sendAudioClick(e);

    if (!canAutocomplete) {
      toast({
        description: `Out of searches for today. Upgrade to explorer+ or try again tomorrow.`,
        status: 'error',
      });
      return;
    }

    if (isStandard) {
      toast({
        description: `${autocompleteCredits - 1} searches remaining today.`,
        status: 'info',
      });
    }

    navigate(pathname, {
      state: {description, mapNav: true, isAutocomplete: true},
    });
  };

  return (
    <Card
      borderRadius="2xl"
      overflow="hidden"
      shadow={isHovering ? `outline` : null}
      w="72%"
    >
      <CardBody p="none">
        <Flex
          onPointerEnter={() => {
            if (canAutocomplete) setIsHovering(true);
          }}
          onPointerLeave={() => {
            setTimeout(() => setIsHovering(false), 100);
          }}
        >
          <Box
            borderLeftRadius="md"
            flexGrow={1}
            onClick={handleCardClick}
            p="1em"
          >
            <Heading as="h2" display="inline" size="sm">📍 {mainText}</Heading>
            <Text
              fontSize="0.9em"
              noOfLines={1}
              display="inline"
              marginLeft="1em"
            >
              {secondaryText}
            </Text>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PlaceAutoCompleteItem;
