  export const AmbientVolume = 'AmbientVolume';
  export const ApplicationSpaceWarp = 'ApplicationSpaceWarp';
  export const AutoMapDetail = 'AutoMapDetail';
  export const CanBeFollowed = 'CanBeFollowed';
  export const CloseCurrentTool = 'CloseCurrentTool';
  export const DisplayFrequency = 'DisplayFrequency';
  export const EnvironmenLighting = 'EnvironmenLighting';
  export const FallbackAvatarIndex = 'FallbackAvatarIndex';
  export const FixedFoveatedRendering = 'FixedFoveatedRendering';
  export const GoBackToLobby = 'GoBackToLobby';
  export const HandHubGesture = 'HandHubGesture';
  export const Last5 = 'Last5';
  export const LocomotionClamping = 'LocomotionClamping';
  export const LocomotionDirection = 'LocomotionDirection';
  export const LocomotionIsPlanar = 'LocomotionIsPlanar';
  export const MapBorderLock = 'MapBorderLock';
  export const MapMaximize = 'MapMaximize';
  export const MapMinimize = 'MapMinimize';
  export const MapStyle = 'MapStyle';
  export const MapSync = 'MapSync';
  export const MapTabletop = 'MapTabletop';
  export const MapVisibility = 'MapVisibility';
  export const MicIsOn = 'MicIsOn';
  export const MusicVolume = 'MusicVolume';
  export const OculusEyeAndFaceTracking = 'OculusEyeAndFaceTracking';
  export const OculusLOD = 'OculusLOD';
  export const PanHandedness = 'PanHandedness';
  export const PassThrough = 'PassThrough';
  export const PersonalSpace = 'PersonalSpace';
  export const PrimaryActionIsLocomotion = 'PrimaryActionIsLocomotion';
  export const Profiler = 'Profiler';
  export const QualityMessages = 'QualityMessages';
  export const Quit = 'Quit';
  export const Random360 = 'Random360';
  export const RecordLogs = 'RecordLogs';
  export const RequestTilesAroundView = 'RequestTilesAroundView';
  export const ResetMap = 'ResetMap';
  export const ResetView = 'ResetView';
  export const ShowControllers = 'ShowControllers';
  export const ShowSyncUI = 'ShowSyncUI';
  export const ShuffleMusic = 'ShuffleMusic';
  export const SketchHandedness = 'SketchHandedness';
  export const SketchVisible = 'SketchVisible';
  export const SmoothRotate = 'SmoothRotate';
  export const SocialLobby = 'SocialLobby';
  export const StreetviewArrows = 'StreetviewArrows';
  export const StreetviewDots = 'StreetviewDots';
  export const StreetviewHeadControls = 'StreetviewHeadControls';
  export const TTS = 'TTS';
  export const Timeline = 'Timeline';
  export const TwentyFourHourClock = 'TwentyFourHourClock';
  export const ZoomMode = 'ZoomMode';
