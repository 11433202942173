import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Switch,
  useToast
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useEnterKey} from '../../hooks/useEnterKey';
import {folderEditFormSaveRequest, folderEditFormSet, folderEditFormUpdate} from '../../reducers/pinsReducer';
import {sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import PermissionBadge from './PermissionBadge';
import InAppTooltip from './UnityReactComponents/InAppTooltip';

const PinFolderEditForm = ({suppressSaveButton}) => {
  const {_id: worldId, rules} = useSelector(s => s.inApp.world);
  const { isPrivate: isPrivateWorld, isSolo: isSoloWorld} = rules || {};
  const folderEditForm = useSelector(s => s.pins.folderEditForm);
  const {response: folders} = useSelector(s => s.pins.foldersListUser);

  const navigate = useNavigate();

  const {
    _id,
    isSolo,
    label,
    sharedInWorld,
  } = folderEditForm;

  const dispatch = useDispatch();
  const toast = useToast();
  const isNew = !_id;

  const [prevLabel, setPrevLabel] = useState(label);

  useEffect(() => () => {
    // clear form on unmount (modal close)
    dispatch(folderEditFormSet({}));
  }, []);

  useEnterKey(/*blur text field*/);

  const close = () => {
    if (!suppressSaveButton) navigate(-1); // dismiss modal if not inside pin edit form
    setTimeout(() => { // clear form after modal is dismissed
      dispatch(folderEditFormSet({}));
    }, 1);
  };

  const canShareInWorld = isSolo && isPrivateWorld && !isSoloWorld;

  return (
    <Card
      bg="#54647C"
      borderRadius="2xl"
      color="#fff"
      flexShrink={0}
      overflow="hidden"
      p={2}
      w="full"
    >
      <CardHeader p={1}>
        <Flex spacing="1">
          <PermissionBadge isOwnedByUser isPrivate={isSolo} isShared={sharedInWorld === worldId} size="2em"/>
          &nbsp;
          <Heading
            color="white"
            size="sm"
            textAlign="center"
            w="full"
          >
            {isNew ? 'New Place List' : `Editing Place List: ${prevLabel}`}
          </Heading>
          <CloseButton onClick={close}/>
        </Flex>
      </CardHeader>

      <CardBody p={1}>
        <FormControl alignItems="baseline" display="flex" marginBottom={3}>
          <FormLabel>Label</FormLabel>
          <Input
            autoFocus={isNew}
            placeholder="New folder"
            size="sm"
            onChange={({target: {value}}) => {
              dispatch(folderEditFormUpdate({
                label: value,
              }));
            }}
            onPointerEnter={sendDragWithinPage}
            onPointerLeave={sendDragToScroll}
            value={label}
          />
          {/*<FormHelperText>say something about this place</FormHelperText>*/}
        </FormControl>

        <Flex justifyContent={canShareInWorld ? 'space-around' : 'left'} gap={3}>
          <FormControl display="flex" w="auto">
            <FormLabel>Private</FormLabel>
            <Switch
              onChange={({target: {checked}}) => {
                dispatch(folderEditFormUpdate({
                  isSolo: checked,
                  ...(!checked ? {sharedInWorld: null} : {}),
                }));
              }}
              isChecked={isSolo}
            />
            {/*<FormHelperText>Pins in private folders are only visible to you.</FormHelperText>*/}
          </FormControl>

          {canShareInWorld && (
            <InAppTooltip
              message={'Share this list and its contents in the current world'}
            >
              <FormControl display="flex" w="auto">
                <FormLabel>Share in current world</FormLabel>
                <Switch
                  onChange={({target: {checked}}) => {
                    dispatch(folderEditFormUpdate({
                      sharedInWorld: checked ? worldId : '',
                    }));
                  }}
                  isChecked={sharedInWorld === worldId}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </InAppTooltip>
          )}

        </Flex>
      </CardBody>

      <CardFooter p={1}>
        <Flex gap={4} justifyContent="center" w="full">
          {!suppressSaveButton && (
            <>
              <Button
                colorScheme="red"
                onClick={(e) => {
                  close();
                  e.preventDefault();
                  e.stopPropagation();
                }}
                // variant="solid"
              >
                Cancel
              </Button>
              <Button
                colorScheme="wooorldBlue"
                onClick={async () => {
                  const {error} = await dispatch(folderEditFormSaveRequest());
                  toast(error
                    ? {
                      description: `"${label}" could not be updated. Please try again.`,
                      status: 'error',
                      title: 'Failed to update folder.',
                    }
                    : {
                      description: `"${label}" has been updated.`,
                      status: 'success',
                      title: 'List updated.',
                    });
                  close();
                }}
                variant="solid"
              >
                Save
              </Button>
            </>
          )}


        </Flex>

      </CardFooter>
    </Card>
  );


};

export default PinFolderEditForm;
