import React from 'react';
import logo from '../assets/wooorld-color.png';
import { Link } from 'react-router-dom'
const WelcomePage = () => 
<div className="Welcome-Page">
    <header style={{marginBottom: "3em"}}>
        <p>
            Welcome to
        </p>
        {/* <br/>
        <p style={{marginBottom: "1.5em"}}>to</p> */}
    </header>
    <img src={logo} className="App-logo" alt="logo" />
    {/* <div id="welcome-page-links" className="flex-column" style={{marginTop: "6em"}}>
        <Link id="new-user-link" className="clickable" to="/registration">
            New User?
        </Link> */
        /* <Link id="forgot-password-link" className="clickable" to="/password-recovery-link-request">
            Forgot Password?
        </Link> */
    /* </div> */}
</div>

export default WelcomePage