import {
  AbsoluteCenter,
  Box,
  Card,
  CardBody,
  CardHeader,
  GridItem,
  SimpleGrid,
  Skeleton,
  useBreakpointValue
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import BottomErrorIndicator from './BottomErrorIndicator';
import BottomLoadingIndicator from './BottomLoadingIndicator';
import FireOnInView from './FireOnInView';

const InfiniteList = ({
  columns = 3,
  error,
  hasNextPage,
  isFiltered,
  isLoading,
  itemHeight = '11em',
  items,
  minHeight = '100vh',
  onFetchNextPage,
  page,
  renderItem,
}) => {

  const borderTopRadius = '24px';

  const columnsRendered = useBreakpointValue( // https://chakra-ui.com/docs/hooks/use-breakpoint-value
    {
      base: 1,
      md: columns, // app tool panel size
      lg: 4,
      xl: 5,
    },
  );

  return (
    <>
      <Box w="full" fontSize=".8rem">
        <Skeleton
          borderTopRadius={borderTopRadius}
          endColor="#D2C1EE"
          h={minHeight}
          isLoaded={!!page || !isLoading}
          startColor={isFiltered ? '#EAEFC5' : '#C5EAEF'}
        >
          <SimpleGrid
            autoRows="max-content"
            bgGradient={`linear(135deg, ${isFiltered ? '#EAEFC5' : '#C5EAEF'}, #D2C1EE)`}
            borderTopRadius={borderTopRadius}
            columns={columnsRendered}
            minH={minHeight}
            p={2}
            position="relative"
            rowGap={2}
            spacing={2}
          >
            {_.isEmpty(items) && (
              <GridItem colSpan={columnsRendered}>
                <Card bg="none" borderRadius="2xl" w="full">
                  <CardHeader color="grey" fontSize="lg" textAlign="center">Nothing to display.</CardHeader>
                </Card>
              </GridItem>
            )}

            {_.map(items, renderItem)}

            <FireOnInView // Request new items when scrolled into view.
              isEnabled={hasNextPage && !isLoading && !error}
              onInView={onFetchNextPage}
            />

            {!_.isEmpty(items) && !hasNextPage && (
              <GridItem colSpan={columnsRendered - items.length % columnsRendered}>
                <Card bg="none" borderRadius="2xl" h={itemHeight} w="full">
                  <CardBody color="grey" fontSize="lg">
                    <AbsoluteCenter>
                      End of the list.
                    </AbsoluteCenter>
                  </CardBody>
                </Card>
              </GridItem>
            )}
          </SimpleGrid>
        </Skeleton>

      </Box>

      <BottomErrorIndicator isError={error} onRetry={onFetchNextPage}/>
      <BottomLoadingIndicator isLoading={hasNextPage && isLoading}/>
    </>
  );
};

export default InfiniteList;
