import React from 'react';
import {MdUndo} from 'react-icons/md';
import {useSelector} from 'react-redux';
import ButtonMinor from '../../../../components/ButtonMinor';
import {sendPanoUnload} from '../../../../vuplex';

export const PanoUnloadButton = () => {
  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const isPanoLoaded = !!(panoId && panoLocation);

  return isPanoLoaded && (
    <ButtonMinor
      bg="#007AFF"
      borderRadius="50%"
      flexShrink={0}
      height="3em"
      onClick={sendPanoUnload}
      size="lg"
      tooltip="Go back to 3D"
      width="3em"
      p={0}
    >
      <MdUndo color="white" size="1.5em"/>
    </ButtonMinor>);
};
