import {SmallCloseIcon} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {MdQuestionMark} from 'react-icons/md';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import {sendAudioClick} from '../../vuplex';

const TourHelpButton = ({}) => {
  const {isOpen, onToggle, onClose} = useDisclosure();
  const [hasBeenHelped, setHasBeenHelped] = useLocalStorageState('hasBeenHelped', false);

  useEffect(() => {
    if (!hasBeenHelped && !isOpen) {
      onToggle();
      setHasBeenHelped(true);
    }
  }, [isOpen, hasBeenHelped]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box paddingRight={2}>
        <IconButton
          icon={<MdQuestionMark/>}
          isRound
          onClick={(evt) => {
            sendAudioClick(evt);
            onToggle();
          }}
          variant="buttonMinor"
          w="1em"
        />

        {isOpen && (
          <SmallCloseIcon
            background="red"
            borderRadius="full"
            color="white"
            pointerEvents="none"
            position="absolute"
            right="-0.2em"
            top="-0.2em"
          />
        )}

      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="lg"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(5px)"
        />

        <ModalContent
          bgGradient="conic-gradient(from 90deg at 50% 50%, rgba(254, 203, 137, 1) 0%, rgba(137, 175, 231, 1) 4%, rgba(154, 220, 205, 1) 10%, rgba(47, 167, 253, 1) 33%, rgba(249, 51, 254, 1) 47%, rgba(254, 51, 95, 1) 63%, rgba(254, 203, 137, 1) 100%);"
          borderRadius="xl"
          marginBottom="0.5rem"
          marginTop="1rem"
          maxHeight="calc(100% - 1.5rem)"
        >
          <ModalBody
            bg="#3D3D3D"
            borderRadius="xl"
            color="white"
            margin={3}
            p={5}
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.07)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.07)`,
              },
            }}
          >

            <Text>
              Hey there, Explorer! 🌍
            </Text>
            <br/>
            <Text>
              Meet Tours ⚡, your new AI sidekick! Give it a topic or a place, and voila – you'll get a vibrant,
              tailor-made tour! It'll find relevant info, pictures, reviews, and even Wiki articles, all while narrating
              your journey.
            </Text>
            <br/>
            <Text>
              Personalize your tour spots by adding 360 images or tweaking the locations – just remember, tours are
              public, so keep personal info to yourself.
            </Text>
            <br/>
            <Text>
              Found a bug? Let's sort it out together! Join our Discord
              community and let us know: wooorld.io
            </Text>
            <br/>
            <Text>
              So, ready to explore the world with Tours? 🚀
            </Text>

            <Center>
              <Button
                bg="white"
                color="black24"
                m={5}
                onClick={(evt) => {
                  sendAudioClick(evt);
                  onClose();
                }}
                size="lg"
                variant="solid"
              >
                Let's go!
              </Button>
            </Center>

          </ModalBody>

        </ModalContent>

      </Modal>


    </Popover>


  );
};

export default TourHelpButton;
