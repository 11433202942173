import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiPost} from '../api';
import {addRequestCases, requestDefaultState} from './reducerUtils';

const initialState = {
  autoComplete: {
    ...requestDefaultState,
    credits: 0,
  },
  autoCompleteBalanceRequest: requestDefaultState,
  autoCompleteSpendRequest: requestDefaultState,
  details: requestDefaultState,
  keywordFilter: '',
  nearby: {
    ...requestDefaultState,
    credits: 0,
  },
  placesNearbyBalanceRequest: requestDefaultState,
  isReadyToRefresh: false,
  typeFilter: '',
};


export const autoCompleteBalanceRequest = createAsyncThunk(
  'places/autoCompleteBalanceRequest',
  async () => {
    const {data} = await apiPost(`/geodata/autoCompleteBalance`, {});
    console.log(`autoCompleteBalance`, data);
    return data;
  },
);

export const autoCompleteSpendRequest = createAsyncThunk(
  'places/autoCompleteSpendRequest',
  async () => {
    const {data} = await apiPost(`/geodata/autoCompleteSpend`, {});
    console.log(`autoCompleteSpend`, data);
    return data;
  },
);

export const autoCompleteRequest = createAsyncThunk(
  'places/autoCompleteRequest',
  async ({
    input,
    latitude,
    longitude,
    radiusMeters, // used only to weight results to vicinity, doesn't exclude outside
  }) => {
    const {data} = await apiPost(`/geodata/autoComplete`, {
      input,
      latitude,
      longitude,
      radiusMeters, // used only to weight results to vicinity, doesn't exclude outside
    });

    console.log(`autocomplete`, data);

    return data;
  },
);

export const detailsRequest = createAsyncThunk(
  'places/detailsRequest',
  async ({
    placeId,
    isAutocomplete,
  }) => {
    const {data} = await apiPost(`/geodata/placeDetails`, {placeId, isAutocomplete});
    console.log(`details`, data);
    return data;
  },
);

export const googlePlacesNearbyRequest = createAsyncThunk(
  'places/nearbyRequest',
  async ({} = {}, {getState}) => {

    const {
      inApp: {
        mapLocation: {
          center: {
            latitude, longitude,
          },
          radiusKm,
        }
      },
      places: {
        keywordFilter,
        typeFilter,
      }
    } = getState();
    const {data} = await apiPost(`/geodata/placesNearby`, {
      keyword: keywordFilter,
      latitude,
      longitude,
      radiusMeters: Math.min(50000, Math.floor(radiusKm * 1000)),
      types: [typeFilter],
    });
    return data;
  },
);


export const placesNearbyBalanceRequest = createAsyncThunk(
  'places/placesNearbyBalanceRequest',
  async () => {
    const {data} = await apiPost(`/geodata/placesNearbyBalance`, {});
    console.log(`placesNearbyBalance`, data);
    return data;
  },
);

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    autoCompleteClear: ({autoComplete}) => {
      autoComplete.response = [];
    },
    detailsClear: ({details}) => {
      details.response = [];
      details.error = null;
    },
    isReadyToRefreshSet: (state, {payload: val}) => {
      state.isReadyToRefresh = val;
    },
    keywordFilterClear: (state) => {
      state.keywordFilter = '';
    },
    keywordFilterSet: (state, {payload: val}) => {
      state.keywordFilter = val;
    },
    typeFilterSet: (state, {payload: val}) => {
      state.typeFilter = val;
    }

  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    addRequestCases(builder, autoCompleteSpendRequest, 'autoCompleteSpendRequest', {
      fulfilledReducer: (state, action) => {
        state.autoCompleteSpendRequest.isLoading = false;
        state.autoCompleteSpendRequest.response = action.payload;
        state.autoComplete.credits = action.payload.autoCompleteBalance;
      }
    });
    addRequestCases(builder, autoCompleteBalanceRequest, 'autoCompleteBalanceRequest', {
      fulfilledReducer: (state, action) => {
        state.autoCompleteBalanceRequest.isLoading = false;
        state.autoCompleteBalanceRequest.response = action.payload;
        state.autoComplete.credits = action.payload.autoCompleteBalance;
      }
    });
    addRequestCases(builder, placesNearbyBalanceRequest, 'placesNearbyBalanceRequest', {
      fulfilledReducer: (state, action) => {
        state.autoCompleteBalanceRequest.isLoading = false;
        state.autoCompleteBalanceRequest.response = action.payload;
        state.nearby.credits = action.payload.placesNearbyBalance;
      }
    });
    addRequestCases(builder, autoCompleteRequest, 'autoComplete');
    addRequestCases(builder, detailsRequest, 'details', {
      fulfilledReducer: (state, action) => {
        state.details.isLoading = false;
        state.details.response = action.payload;
        state.nearby.credits = action.payload.placesNearbyBalance;
      }
    });
    addRequestCases(builder, googlePlacesNearbyRequest, 'nearby');
  }
});

export const {
  autoCompleteClear,
  detailsClear,
  isReadyToRefreshSet,
  keywordFilterClear,
  keywordFilterSet,
  typeFilterSet,
} = placesSlice.actions;

export default placesSlice.reducer;
