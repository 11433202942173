import {Box} from '@chakra-ui/react';
import {InView} from 'react-intersection-observer';

const FireOnInView = ({isEnabled, onInView}) => isEnabled && (
  <InView
    as="div"
    onChange={(inView, entry) => {
      if (inView) onInView();
    }}
  >
    <Box h="2em" w="10em"></Box>
  </InView>
);

export default FireOnInView;
