import {useEffect} from 'react';
import {useComponentId} from '../../../hooks/useComponentId';
import {sendWebBrowser, sendWebBrowserUnmount} from '../../../vuplex';

const InAppWebBrowser = ({
  color,
  executeJavascriptOnLoad,
  height,
  width,
  url,
}) => {
  const componentId = useComponentId();

  useEffect(() => {
    sendWebBrowser({
      color,
      componentId,
      executeJavascriptOnLoad,
      height,
      width,
      url,
    });
  }, [componentId, executeJavascriptOnLoad, height, width, url]);

  useEffect(() => {
    return () => sendWebBrowserUnmount(componentId);
  }, [componentId]);

  return null;
};

export default InAppWebBrowser;
