import {SmallCloseIcon} from '@chakra-ui/icons';
import {Alert, AlertIcon, Box, Button, Center, Flex, IconButton, StackDivider, useDisclosure} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {FaSearch} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import InfiniteList from '../../components/InfiniteList';
import {
  googlePlacesNearbyRequest,
  isReadyToRefreshSet,
  keywordFilterClear,
  typeFilterSet
} from '../../reducers/placesReducer';
import {textInputPlaceholderSet} from '../../reducers/uiReducer';
import {sendAudioClick} from '../../vuplex';
import placeTypes from '../GooglePlacesTypes';
import Filters from './Filters';
import GoogleAttribution from './GoogleAttribution';
import GooglePlaceCard from './GooglePlaceCard';

const GooglePlaces = () => {
  const keyword = useSelector((s) => s.places.keywordFilter);
  const typeFilter = useSelector((s) => s.places.typeFilter);
  const isToolOn = useSelector(s => s.inApp.isToolOn);
  const {
    center: {
      latitude, longitude,
    },
    radiusKm,
  } = useSelector(state => state.inApp.mapLocation);

  const dispatch = useDispatch();
  const {credits, error, isLoading, response} = useSelector(state => state.places.nearby);

  // refresh indicator on button in Nearby
  const [isInitialMount, setIsInitialMount] = useState(true);
  useEffect(() => {
    if (!isInitialMount) dispatch(isReadyToRefreshSet(true));
    else setIsInitialMount(false);
  }, [latitude, longitude, radiusKm]);

  // fetch initial results and fetch when typeFilter changes
  useEffect(() => {
    dispatch(googlePlacesNearbyRequest());
  }, [typeFilter]);


  useEffect(() => () => dispatch(isReadyToRefreshSet(false)));

  const {isOpen, onToggle, onClose} = useDisclosure();
  const handlePlaceTypeClick = (placeType) => (evt) => {
    // evt.target.blur();
    dispatch(typeFilterSet(typeFilter === placeType ? '' : placeType));
    _.delay(onClose, 333);
  };

  const places = _.get(response, 'places');

  return isToolOn && (
    <>
      {/* TODO: move to Nearby in a route*/}
      <Flex alignItems="center" position="absolute" right={2} top={0} gap={2} justifyContent="flex-end">
        {keyword && (
          <Box position="relative" display="inline-block">
            <IconButton
              colorScheme="red"
              icon={<FaSearch/>}
              isRound
              onClick={() => {
                dispatch(textInputPlaceholderSet({key: 'txtInputExplore', val: 'Search places'}));
                dispatch(keywordFilterClear());
                dispatch(googlePlacesNearbyRequest());
              }}
            />
            <SmallCloseIcon
              background="#555"
              borderRadius="full"
              color="white"
              pointerEvents="none"
              position="absolute"
              right={0}
            />
          </Box>
        )}

        {typeFilter && (
          <ButtonMinor
            alignItems="center"
            bg="wooorldBlue._"
            fontSize="1.5em"
            onClick={handlePlaceTypeClick(typeFilter)}
            position="relative"
            p={0}
            size="md"
          >
            {placeTypes.find(p => p.placeType === typeFilter).emoji}
            <SmallCloseIcon
              background="red"
              borderRadius="full"
              color="white"
              pointerEvents="none"
              position="absolute"
              bottom="-.25em"
              right="-.25em"
              fontSize=".75em"
            />
          </ButtonMinor>
        )}

        <Filters isActive={typeFilter || keyword}>
          {placeTypes.map(({placeType, label, isSeparator}, idx) => {
            const isSelected = typeFilter === placeType;
            return isSeparator
              ? <StackDivider border="solid grey" key={idx}/>
              : (
                <ButtonMinor
                  bg={isSelected ? 'wooorldBlue._' : null}
                  color={isSelected ? 'white' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handlePlaceTypeClick(placeType)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </ButtonMinor>
              );
          })}
        </Filters>
      </Flex>

      {credits <= 0 && (
        <Center>
          <Alert borderRadius="2xl" m={4} status="warning" w="30em">
            <AlertIcon/>
            You've run out of Google credits. Please continue with Wikipedia until tomorrow.
          </Alert>
        </Center>
      )}

      <InfiniteList
        {...{
          columns: 3,
          error: credits > 0 && error,
          isLoading,
          items: places,
          page: 1,
        }}
        onFetchNextPage={() => {
          dispatch(googlePlacesNearbyRequest());
        }}
        renderItem={(place) => (
          <GooglePlaceCard key={place.place_id} place={place}/>)}
      />
      {!isLoading && !_.isEmpty(places) && (
        <GoogleAttribution hideText/>
      )}
    </>
  );
};

export default GooglePlaces;
