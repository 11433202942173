import {Button, Card, Flex, Stack} from '@chakra-ui/react';
import React, {useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {sendPanoUnload} from '../../vuplex';
import MapNavAssignButton from './MapNavAssignButton';
import MapNavButton from './MapNavButton';
import PanoButton from './PanoButton';
import PanoSetButton from './PanoSetButton';

const GooglePlaceAttachments = ({}) => {
  const {error, isLoading, response: details} = useSelector(({places}) => places.details);
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);

  const {
    editorial_summary: {overview} = {},
    formatted_address: address,
    geometry: {
      location: {lat, lng},
      // viewport: {
      //   northeast: {
      //     lat: 48.86355484999999,
      //     lng: 2.342908599999999,
      //   },
      //   southwest: {
      //     lat: 48.85824504999999, lng: 2.330669800000001,
      //   },
      // }
    } = {location: {}},
    icon,
    icon_background_color,
    name,
    panoId,
    photos = [],
    reviews = [],
    types: [placeType, /* there may be more than 1 */] = [],
    types = [],
  }
    = details || {};

  return (
    <Card
      borderRadius="2xl"
      border="solid 2px white"
      bg="rgba(12, 27, 159, 0.15)"
      p={4}
    >
      <Stack>
        <Flex gap={6} justifyContent="center" w="full">
          <MapNavButton
            latitude={lat}
            longitude={lng}
            name={name}
            onClick={() => {
              if (panoId !== inAppPanoId) sendPanoUnload();
            }}
            size="lg"
          />

          {panoId && (
            <PanoButton
              panoId={panoId}
              size="lg"
              tooltip={`Load a 360 from this location`}
            />
          )}
        </Flex>
      </Stack>
    </Card>
  );
};

export default GooglePlaceAttachments;
