import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {apiPost} from '../api';
import {addRequestCases, requestDefaultState} from './reducerUtils';


/// helpStep
/// Steps:
/// 0- UNITY: Off, Web: No darkening or highlights
/// 1- UNITY: Start Talking with blockers: 0-14
/// 2- WEB: Highlight Both Create Buttons: 14-19
/// 3- WEB: Highlight Join Scroll View: 19-24
/// 4- WEB: Back to No Highlights: 24-26
/// 5- WEB: Highlight Exploration Create and Join Buttons: 26-34
/// 6- WEB: Highlight Game Create and Join Buttons: 34-41
/// 7- WEB: Open Create Exploration World panel: 41-47
/// 8- WEB: Switch to Solo, Private, Public (one sec each): 47-50
/// 9- WEB Close Create Exploration World panel: 56-57
/// 10- UNITY: Visible highlight without blockers: 57+

const initialState = {
  getByCodeRequest: requestDefaultState,
  helpStep: 0,
  userIdsBlockedByUser: {/*userId: userId*/},
  worlds: [],
};

export const getByCodeRequest = createAsyncThunk(
  'worlds/getByCodeRequest',
  async ({joinCode}, thunkAPI) => {
    const {data} = await apiPost(`/worlds/getByCode`, {joinCode});
    return data;
  }
)

export const worldsSlice = createSlice({
  name: 'inApp',
  initialState,
  reducers: {
    userIdsBlockedByUserSet: (state, {payload}) => {
      state.userIdsBlockedByUser = _.fromPairs(payload.map(id => [id, id]));
    },
    helpStepSet: (state, {payload}) => {
      state.helpStep = payload;
    },
    worldsSet: (state, {payload}) => {
      state.worlds = payload;
    },
  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    // addRequestCases(builder, voiceInputRequest, 'voiceInput');
    addRequestCases(builder, getByCodeRequest, 'getByCodeRequest');
  }
});

export const {
  helpStepSet,
  userIdsBlockedByUserSet,
  worldsSet,
} = worldsSlice.actions;

export default worldsSlice.reducer;
