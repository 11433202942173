import {
  Card,
  CardBody,
  CardHeader, FormControl, FormLabel,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {userTimeoutRequest} from '../../reducers/gooodReducer';
import SafetyButton from './SafetyButton';

const UserTimeout = ({
                       onSent = () => {
                       },
                       user: {_id, alias},
                     }) => {
  const [reason, setReason] = useState('<br>Harassment, reported by multiple users. <br><br>You can submit an appeal to hello@wooorld.io');
  const [hours, setHours] = useState(0.25);

  const dispatch = useDispatch();
  const toast = useToast();
  const {isLoading} = useSelector(s => s.goood.broadcast);

  const sendMsg = () => {
    dispatch(userTimeoutRequest({
      // issuedBy: <assigned on server>
      durationHours: parseFloat(hours),
      issuedTo: _id,
      reason,
    }))
      .then(({error}) => {
        toast(error
          ? {
            description: error.message,
            status: 'error',
            title: 'Timeout failed.',
          }
          : {
            description: `${hours} hours for ${reason}`,
            status: 'success',
            title: `Timeout issued to ${alias}.`,
          });
        onSent();
      });
  };

  return (
    <Card borderRadius="2xl">
      <CardHeader>
        <Heading size="sm">
          🪑 Issue a timeout to {alias}
        </Heading>
      </CardHeader>

      <CardBody>
        <Stack gap={2}>
          <FormControl>
            <FormLabel>hours</FormLabel>
            <NumberInput
              min={0.25}
              onChange={(value) => setHours(value)}
              precision={2}
              step={0.25}
              value={hours}
              maxWidth="10em"
            >
              <NumberInputField/>
              <NumberInputStepper>
                <NumberIncrementStepper/>
                <NumberDecrementStepper/>
              </NumberInputStepper>
            </NumberInput>
          </FormControl>

          <FormControl>
            <FormLabel>reason</FormLabel>
            <Input maxW="md" onChange={({target: {value}}) => setReason(value)} value={reason}/>
          </FormControl>

          <SafetyButton
            isLoading={isLoading}
            isDisabled={reason.length < 5}
            onClick={sendMsg}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default UserTimeout;
