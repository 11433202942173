import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';

//  https://redux-toolkit.js.org/usage/usage-guide#simplifying-store-setup-with-configurestore
export const store = configureStore({
  reducer: rootReducer,
});

// hot module replacement for reducers
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => store.replaceReducer(rootReducer));

  window.store = store;
}
