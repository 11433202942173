import {useSearchParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import MinimapMapTypes, {mapTypes} from './MinimapMapTypes';
import {ControlPosition, Map, MapControl, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {useVuplex} from '../../../hooks/useVuplex';
import {SketchVisible} from '../../../helpers/settingsIds';
import {sendAudioClick, sendNavTo, sendPanoLoad, sendPanoUnload, sendSettingToggle} from '../../../vuplex';
import {Box, Spacer} from '@chakra-ui/react';
import MinimapPinsAndClusters from './MinimapPinsAndClusters';
import MinimapPanoToast from './Toast/MinimapPanoToast';
import PanoNoneForLocationToast from './Toast/PanoNoneForLocationToast';
import MinimapClickActionToast from './Toast/MinimapClickActionToast';
import MinimapHeadingMarker from './MinimapHeadingMarker';
import MinimapLaserPointerMarker from './MinimapLaserPointerMarker';
import MinimapInWorldUsers from './MinimapInWorldUsers';
import MinimapActionMarker from './MinimapActionMarker';
import ButtonCircleIcon from '../../../components/ButtonCircleIcon';
import {RiSketching} from 'react-icons/ri';
import {FaMapPin} from 'react-icons/fa6';
import OpenStreetMapAutoComplete from '../OpenStreetMapAutoComplete';
import MinimapScaleControls from './MinimapScaleControls';
import MinimapControls from './MinimapControls';

const zoomOffset = 1;

export const MapRenderer = ({
  h = '100vh',
  w = '100vw',
}) => {
  const [searchParams] = useSearchParams();
  const isMinimal = searchParams.get('isMinimal');

  const [isStreetViewLayerOn, setIsStreetViewLayerOn] = useState(false);

  const mapNav = useRef({
    latitude: 0,
    longitude: 0,
    zoom: 0,
  });

  const mapPanningTimeoutId = useRef(null);
  const isMapPanning = useRef(false);
  const streetViewLayer = useRef();

  const actionMarkerTimeoutId = useRef(null);
  const [actionMarkerProps, setActionMarkerProps] = useState(null); // { latitude, longitude }
  const [isFixedNorth, setIsFixedNorth] = useState(true);
  const [isMapSynced, setIsMapSynced] = useState(true);
  const [mapTypeId, setMapTypeId] = useState(mapTypes.roadmap);
  const [showInAppPins, setShowInAppPins] = useState(true);
  const [showInAppSketches, setShowInAppSketches] = useState(false);
  const ignorePanFromApp = useRef(false);
  const ignoreZoomFromApp = useRef(false);

  const core = useMapsLibrary('core');
  const geometry = useMapsLibrary('geometry');
  const map = useMap();
  const streetViewLib = useMapsLibrary('streetView');

  const panoId = useSelector(s => s.inApp.panoId);
  const panoLocation = useSelector(s => s.inApp.panoLocation);
  const isPanoLoaded = panoId && panoLocation;

  // setup streetview coverage layer
  useEffect(() => {
    if (!map || !streetViewLib) return;
    // console.log("adding streetview coverage layer")
    streetViewLayer.current = new streetViewLib.StreetViewCoverageLayer();
    window.sv = streetViewLayer.current;
  }, [map, streetViewLib]);


  const tempIgnoreAppUpdates = () => {
    // don't respond to vuplex locationChange messages while map is panning
    isMapPanning.current = true;
    clearTimeout(mapPanningTimeoutId.current);
    mapPanningTimeoutId.current = setTimeout(() => isMapPanning.current = false, 333);
  };


  // https://stackoverflow.com/a/30541162
  const pixelToLatlng = (screenX, screenY) => {
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const projection = map.getProjection();
    const topRight = projection.fromLatLngToPoint(ne);
    const bottomLeft = projection.fromLatLngToPoint(sw);

    const tileSize = Math.pow(2, map.getZoom());
    const point = new core.Point(screenX / tileSize + bottomLeft.x, screenY / tileSize + topRight.y);

    return projection.fromPointToLatLng(point);
  };

  /**
   * debounced so that we have a single target per zoom gesture
   * @type {DebouncedFuncLeading<function(*, *): google.maps.LatLng>}
   */
  const getZoomTarget = _.debounce((xNorm, yNorm) =>
      pixelToLatlng(window.innerWidth * xNorm, window.innerHeight * yNorm),
    100,
    {leading: true, trailing: false});


  useVuplex({
    mapNav: (data) => { // using mapNav here to sync with headingMarker
      mapNav.current = data;
      if (!map || isMapPanning.current) return;

      const {menuHeadingDegrees, latitude, longitude, zoom} = data;
      if (!ignoreZoomFromApp.current) map.setZoom(Math.min(18, zoom + zoomOffset));
      if (!ignorePanFromApp.current) map.panTo({lat: latitude, lng: longitude});

      // if this is spammed, it's impossible to drag the map
      const heading = isFixedNorth ? 0 : (menuHeadingDegrees + 360) % 360;
      if (Math.abs(map.getHeading() - heading) > 0.5) map.setHeading(heading);
    },

    minimapZoom: (data) => {
      if (!map || !geometry || !core) return;
      const {
        amount,
        xNorm,
        yNorm,
      } = data;

      desyncFromApp();

      map.moveCamera({
        zoom: map.getZoom() + amount,
        center: geometry.spherical.interpolate(
          map.getCenter(),
          getZoomTarget(xNorm, yNorm),
          Math.abs(amount),
        ),
      });
    },
    settings: (data) => {
      if (_.has(data, SketchVisible)) {
        setShowInAppSketches(data[SketchVisible]);
      }
    },
  }, [
    core,
    geometry,
    isFixedNorth,
    map,
  ]);

  const handleMapClick = (e) => {
    sendAudioClick(e);
    // e.stop(); // prevent place popup TODO: find a way to get the popup content

    const {detail: {latLng, placeId}} = e;

    if (isStreetViewLayerOn) {
      sendPanoLoad({
        lat: latLng.lat,
        lon: latLng.lng,
      });
    } else {

      // clearTimeout(actionMarkerTimeoutId.current);
      setActionMarkerProps({
        isDisabled: false,
        latitude: latLng.lat,
        longitude: latLng.lng,
        placeId,
      });
      // actionMarkerTimeoutId.current = setTimeout(() => setActionMarkerProps(null), 3000);

    }
  };

  const handleActionMarkerClick = () => {
    ignorePanFromApp.current = false;
    ignoreZoomFromApp.current = false;
    setIsMapSynced(true);

    if (isPanoLoaded) sendPanoUnload();

    // tempIgnoreAppUpdates();
    // map.panTo(latLng);

    clearTimeout(actionMarkerTimeoutId.current);
    sendNavTo({
      lat: actionMarkerProps.latitude,
      lon: actionMarkerProps.longitude,
      // zoom: map.getZoom() - zoomOffset,
    });

    setActionMarkerProps({
      ...actionMarkerProps,
      isDisabled: true,
    });
    actionMarkerTimeoutId.current = setTimeout(() => setActionMarkerProps(null), 5000);
  };

  const desyncFromApp = () => {
    ignorePanFromApp.current = true;
    ignoreZoomFromApp.current = true;
    if (isMapSynced) setIsMapSynced(false);
    if (actionMarkerProps) setActionMarkerProps(null);
  };

  const handleMapDragStart = () => {
    // ignore pan and zoom updates from app when user drags the map
    desyncFromApp();
  };

  const handleMapScroll = (e) => {
    const diffX = e.clientX - window.innerWidth / 2;
    const diffY = e.clientY - window.innerHeight / 2;

    if (diffX ** 2 + diffY ** 2 > (window.innerHeight / 4) ** 2) {
      desyncFromApp();
    } else {
      // only ignore zoom updates from app when user zooms map
      // it may be they want to see more or less of the surroundings but still want the heading marker to stay centered
      ignoreZoomFromApp.current = true;
      tempIgnoreAppUpdates();
    }


    // TODO: this would zoom from center instead of pointer location but ends up jumping several zoom levels
    // map.setZoom(map.getZoom() + e.deltaY / -100);
    // e.stopPropagation();
  };


  const handleStreetViewLayerButtonClick = (e) => {
    streetViewLayer.current.setMap(isStreetViewLayerOn ? null : map);
    setIsStreetViewLayerOn(!isStreetViewLayerOn);
  };


  const handleFixedNorthButtonClick = (e) => {
    setIsFixedNorth(!isFixedNorth);
  };

  const handleLocateButtonClick = (e) => {
    ignorePanFromApp.current = false;
    if (isMapSynced) ignoreZoomFromApp.current = false; // only reset zoom on second click
    setIsMapSynced(true);
  };

  const handleOutOfViewUserClick = ({avatarLatitude, avatarLongitude, username}) => {
    desyncFromApp();
    map.panTo({
      lat: avatarLatitude,
      lng: avatarLongitude,
    });
  };

  const handleZoomInClick = (e) => {
    ignoreZoomFromApp.current = true;
    map.setZoom(map.getZoom() + 1);
  };

  const handleZoomOutClick = (e) => {
    ignoreZoomFromApp.current = true;
    map.setZoom(map.getZoom() - 1);
  };
  const handleZoomToGlobeClick = (e) => {
    ignoreZoomFromApp.current = true;
    map.setZoom(2);
  };


  return (
    <Box // This is only here to capture scroll. Map does not seem to have any way to distinguish between zoom change via scroll vs map.setZoom
      h={h}
      w={w}
      onWheel={handleMapScroll}
    >
      <Map // https://github.com/visgl/react-google-maps
        // props extend the MapOptions interface https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
        clickableIcons={false} // true causes a request for place data before click event fires when clicking on icons or labels
        defaultCenter={{lat: mapNav.current.latitude, lng: mapNav.current.longitude}}
        defaultZoom={mapNav.current.zoom}
        disableDefaultUI
        disableDoubleClickZoom
        gestureHandling="greedy"
        keyboardShortcuts={false}
        mapId="fd2bf1a173426a3d" // TODO: get from server
        mapTypeId={mapTypeId} // https://developers.google.com/maps/documentation/javascript/maptypes#BasicMapTypes
        onClick={handleMapClick}
        onDragstart={handleMapDragStart}
        scaleControl
      >
        <Box // block clicks on Google links
          // bg="pink"
          h="25px"
          w="100%"
          bottom={0}
          position="fixed"
          onClickCapture={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />

        <MinimapPinsAndClusters
          onClickCluster={() => {
            ignoreZoomFromApp.current = true;
            ignorePanFromApp.current = true;
          }}
          showInAppPins={showInAppPins}
        />


        <MinimapPanoToast/>
        <PanoNoneForLocationToast/>

        <MinimapClickActionToast
          {...actionMarkerProps}
          onClosedByUser={() => setActionMarkerProps(null)}
        />

        <MinimapHeadingMarker isFixedNorth={isFixedNorth}/>

        <MinimapLaserPointerMarker/>
        <MinimapInWorldUsers onProxyClick={handleOutOfViewUserClick}/>
        {actionMarkerProps && (
          <MinimapActionMarker {...actionMarkerProps} onClick={handleActionMarkerClick}/>
        )}

        {!isMinimal && (
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <MinimapMapTypes
              mapTypeId={mapTypeId}
              onMapTypeIdChange={setMapTypeId}
            >
              <ButtonCircleIcon
                icon={<RiSketching size="1.5em"/>}
                isSelected={showInAppSketches}
                onClick={() => sendSettingToggle(SketchVisible)}
                shadow="dark-lg"
                tooltip={!showInAppSketches ? 'Show sketches on 3D Earth' : 'Hide sketches on 3D Earth'}
              />
              <ButtonCircleIcon
                icon={<FaMapPin size="1.5em"/>}
                isSelected={showInAppPins}
                onClick={() => setShowInAppPins(!showInAppPins)}
                shadow="dark-lg"
                tooltip={!showInAppPins ? 'Show pins on 3D Earth' : 'Hide pins on 3D Earth'}
              />
              <Spacer/>
            </MinimapMapTypes>
          </MapControl>
        )}

        {!isMinimal && (
          <MapControl position={ControlPosition.TOP_LEFT}>
            <OpenStreetMapAutoComplete
              onSelect={({lat, lon, name, display_name}) => {
                desyncFromApp();
                map.panTo({lat, lng: lon});
                setActionMarkerProps({
                  isDisabled: false,
                  latitude: lat,
                  longitude: lon,
                });
              }}
              m={3}
            />
          </MapControl>
        )}


        {!isMinimal && (
          <MapControl position={ControlPosition.LEFT_BOTTOM}>
            <MinimapScaleControls/>
          </MapControl>
        )}

        {!isMinimal && (
          <MapControl position={ControlPosition.RIGHT_BOTTOM}>
            <MinimapControls
              isFixedNorth={isFixedNorth}
              isMapSynced={isMapSynced}
              isStreetViewLayerOn={isStreetViewLayerOn}
              onCompassClick={handleFixedNorthButtonClick}
              onLocateClick={handleLocateButtonClick}
              onStreetViewClick={handleStreetViewLayerButtonClick}
              onZoomInClick={handleZoomInClick}
              onZoomOutClick={handleZoomOutClick}
              onZoomToGlobeClick={handleZoomToGlobeClick}
            />
          </MapControl>
        )}

      </Map>
    </Box>
  );

};