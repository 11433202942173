import {Box, Button, Heading, Modal, ModalContent, ModalOverlay, StackDivider, Text} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import InfiniteList from '../../components/InfiniteList';
import SearchInput from '../../components/SearchInput';
import {
  toursAdminFilterSet,
  toursAdminListRequest,
  toursAdminSearchSet,
  toursAdminSortSet
} from '../../reducers/gooodReducer';
import Filters from '../WooorldInApp/Filters';
import PageTitle from '../WooorldInApp/PageTitle';
import Tour from '../WooorldInApp/Tour';
import TourPromptAdminCard from './TourPromptAdminCard';


const ToursAdmin = ({}) => {
  const {
    error,
    filter,
    filterOptions,
    isLoading,
    response,
    search,
    sort,
    sortOptions,
  } = useSelector(s => s.goood.toursAdmin);

  const {hasNextPage, page, tourPrompts} = response || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (!response && !isLoading && !error) {
      dispatch(toursAdminListRequest({}));
    }
  }, [response, isLoading]);

  const handleFilterClick = (filterClicked) => () => {
    dispatch(toursAdminFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(toursAdminSortSet(sortClicked));
  };

  const fetchNextPage = () => dispatch(toursAdminListRequest({}));
  const isFiltered = filter || sort !== 'likes';

  const {promptId} = useParams();
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="Tours - Goood"/>
      <Box w="full">
        <Heading
          align="center"
          as="h2"
          fontWeight={400}
          margin="1.5rem"
          position="relative"
          size="md"
        >
          <SearchInput
            onChangeDebounced={val => dispatch(toursAdminSearchSet(val))}
            value={search}
          />

          <Box position="absolute" right={0} top={0}>
            <Filters>
              <Text color="lightgrey">Filter</Text>
              {filterOptions.map(({value, label, isSeparator}, idx) => {
                const isSelected = filter === value;
                return (
                  <Button
                    colorScheme={isSelected ? 'wooorldBlue' : null}
                    key={idx}
                    justifyContent="left"
                    onClick={handleFilterClick(value)}
                    position="relative"
                    size="sm"
                  >
                    {label}
                  </Button>
                );
              })}
              <StackDivider border="solid grey"/>
              <Text color="lightgrey">Sort by</Text>
              {sortOptions.map(({value, label, isSeparator}, idx) => {
                const isSelected = sort === value;
                return (
                  <Button
                    colorScheme={isSelected ? 'wooorldBlue' : null}
                    key={idx}
                    justifyContent="left"
                    onClick={handleSortClick(value)}
                    position="relative"
                    size="sm"
                  >
                    {label}
                  </Button>
                );
              })}
            </Filters>

          </Box>
        </Heading>

        <InfiniteList
          columns={3}
          error={error}
          hasNextPage={hasNextPage}
          isFiltered={isFiltered || !!search}
          isLoading={isLoading}
          itemHeight="11.5em"
          items={tourPrompts}
          onFetchNextPage={fetchNextPage}
          page={page}
          renderItem={(tourPrompt, idx) =>
            <TourPromptAdminCard
              key={idx}
              {...tourPrompt}
            />}
        />

        <Modal
          isOpen={!!promptId}
          onClose={() => {
            navigate(-1);
          }}
          size="2xl"
        >
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(3px)"
          />
          <ModalContent
            bg="bg"
            borderRadius="3xl"
          >
            <Tour  />
          </ModalContent>
        </Modal>
      </Box>
    </>
  );


};

export default ToursAdmin;
