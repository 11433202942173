// import _ from 'lodash';
import {Box, Button, Heading, StackDivider, Text} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import InfiniteList from '../../components/InfiniteList';
import SearchInput from '../../components/SearchInput';
import {
  rolesListRequest,
  usersAdminFilterSet,
  usersAdminListRequest,
  usersAdminSearchSet,
  usersAdminSortSet
} from '../../reducers/gooodReducer';
import Filters from '../WooorldInApp/Filters';
import PageTitle from '../WooorldInApp/PageTitle';
import Broadcast from './Broadcast';
import User from './User';

const UsersList = () => {
  const {
    error,
    filter,
    filterOptions,
    isLoading,
    response,
    search,
    sort,
    sortOptions,
  } = useSelector(s => s.goood.usersAdmin);
  const {hasNextPage, page, users} = response || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (!response && !isLoading && !error) {
      dispatch(usersAdminListRequest({}));
    }
  }, [response, isLoading, error]);

  // so we can have roles badges on user cards
  useEffect(() => {
    dispatch(rolesListRequest());
  }, []);

  const handleFilterClick = (filterClicked) => () => {
    dispatch(usersAdminFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(usersAdminSortSet(sortClicked));
  };

  const fetchNextPage = () => dispatch(usersAdminListRequest({}));
  const isFiltered = filter !== 'online' || sort !== 'updated';

  const {userId} = useParams();
  const navigate = useNavigate();

  // const testUsers = _.range(10).map(() => ({
  //   _id: _.random(10000000000000000),
  //     activityStatus: _.sample(['online', 'offline']),
  //     aiMonthlyBudget: _.sample([undefined, 500000, 150000]),
  //     alias: _.sample(['pinkie', 'mr-green', 'wet-tv', 'stinsel', 'Zucky', 'Bozzo']),
  //     buildNumber: _.sample([906, 900, 801, 208]),
  //     email: _.sample(['poo@ppe.com', 'yo@hotmail.com', 'a.dad@doodoodada.org']),
  //     // dismissedHelpTypes: _.sample([]),
  //     isAiDisabled: _.sample([true, false]),
  //     isAmbassador: _.sample([true, false]),
  //     isDev: _.sample([true, false]),
  //     isVIP: _.sample([true, false]),
  //     profilePhotoURL: _.sample([]),
  //     reportedCount: _.sample([0, 3, 5, 200]),
  //     // reportedUsers: _.sample([]),
  //     requiresVerification: _.sample([true, false]),
  //     timeoutCount: _.sample([0, 2, 10, 50]),
  //     worldIsPrivate: _.sample([true, false]),
  //     worldIsSolo: _.sample([true, false]),
  //     worldIsWitw: _.sample([true, false]),
  //     worldName: _.sample(['the stinking grey marmots', 'the collapsing pink horses']),
  // }))

  return (<>
    <PageTitle title="Users - Goood"/>

    <Box w="full">
      <Heading
        align="center"
        as="h2"
        fontWeight={400}
        margin="1.5rem"
        position="relative"
        size="md"
      >
        <SearchInput
          allowPeriod
          onChangeDebounced={val => dispatch(usersAdminSearchSet(val))}
          value={search}
        />

        <Box position="absolute" right={0} top={0}>
          <Filters>
            <Text color="lightgrey">Filter</Text>
            {filterOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = filter === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleFilterClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
            <StackDivider border="solid grey"/>
            <Text color="lightgrey">Sort by</Text>
            {sortOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = sort === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleSortClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
          </Filters>

        </Box>

        <br/>
        <Broadcast/>

      </Heading>

      <InfiniteList
        columns={3}
        error={error}
        hasNextPage={hasNextPage}
        isFiltered={isFiltered || !!search}
        isLoading={isLoading}
        itemHeight="11.5em"
        items={users}
        onFetchNextPage={fetchNextPage}
        page={page}
        renderItem={(user, idx) => <User key={idx} user={user}/>}
      />
    </Box>
  </>);

  // return (
  //   <Skeleton isLoaded={!isLoading && response} w="full">
  //     <Broadcast/>
  //
  //     <Flex
  //       bgGradient="linear(to-b, #DADADA, wooorldLavender.100)"
  //       borderRadius="lg"
  //       flexWrap="wrap"
  //       gap={1}
  //       padding={2}
  //       position="relative"
  //       w="full"
  //     >
  //       {_.isEmpty(users) && <Text>No users currently online.</Text>}
  //       {users.map((u) => <User key={u._id} user={u}/>)}
  //       {/*{testUsers.map((u) =>  <User key={u._id} user={u} />)}*/}
  //     </Flex>
  //   </Skeleton>
  // );

  // return (
  //   <Item
  //     className="usersList"
  //     renderTitle={() => <>List users &nbsp; <button disabled={isLoading} onClick={refresh}>refresh</button></>}
  //     renderBody={() => <>
  //       <Broadcast gooodPost={gooodPost} userCount={users.filter(({activityStatus}) => activityStatus === 'online').length}/>
  //       {users.map((u) =>  <User gooodPost={gooodPost} key={u._id} user={u} />)}
  //     </> }
  //   />)
};

export default UsersList;
