import {
  Center,
  Flex,
  Heading,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {MdDialpad} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {getByCodeRequest} from '../../reducers/worldsReducer';
import {sendDragToScroll, sendDragWithinPage, sendWorldJoin} from '../../vuplex';
import WorldCardBlank from './WorldCardBlank';
import {useEnterKey} from '../../hooks/useEnterKey';

const WorldJoinWithCode = () => {
  const currentWorld = useSelector(s => s.inApp.world) || {};
  const isLoading = useSelector(s => s.worlds.getByCodeRequest.isLoading);
  const {isOpen, onToggle, onClose} = useDisclosure();
  const [joinCode, setJoinCode] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();

  const disabledReason = joinCode.length !== 6
    ? 'Invite code must be 6 characters'
    : currentWorld.joinCode === joinCode
      ? 'This invite code is for the current world'
      : '';


  const handleConfirmClick = async () => {
    if (disabledReason) return;

    await dispatch(getByCodeRequest({joinCode}))
      .then(({error, payload}) => {
        if (error) {
          toast({
            description: 'Please check that you have the correct invite code and try again.',
            status: 'error',
            title: 'No world found',
          });
          return;
        }
        sendWorldJoin({worldId: payload._id});
        setJoinCode('');
        onClose();
      });
  };

  useEnterKey(handleConfirmClick);


  return (<>
    <ButtonMinor
      isRound
      leftIcon={<MdDialpad/>}
      onClick={() => {
        onToggle();
      }}
    >
      Join with Invite Code
    </ButtonMinor>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
        borderRadius="2xl"
      />

      <ModalContent
        bg="none"
        borderRadius="2xl"
        marginBottom="0.5rem"
        marginTop="8rem"
        // maxHeight="calc(100% - 1.8rem)"
      >
        <WorldCardBlank
          headerContent={
            <Heading
              color="black.24"
              p={2}
              size="md"
              textAlign="center"
              // textShadow={`0px 2px 4px #00000077`}
            >
              Enter invite code to join World
            </Heading>
          }
          footerContent={
            <Flex gap={4} justifyContent="center">
              <ButtonMinor
                disabledReason={disabledReason}
                isDisabled={disabledReason}
                isLoading={isLoading}
                h="3em"
                onClick={handleConfirmClick}
                tooltip={`Join world.`}
              >
                ✔ Confirm
              </ButtonMinor>

              <ButtonMinor
                h="3em"
                onClick={() => {
                  setJoinCode('');
                  onClose();
                }}
              >
                ❌ Cancel
              </ButtonMinor>
            </Flex>
          }
          height="14em"
          landscapeTop="100px"
          landscapeHeight={150}
          world={{
            name: `Join with invite Code`, // just for generating landscape graphics
            isLobby: true,
          }}
        >
          <Center>
            <Input
              bg="white"
              fontSize="1.5em"
              placeholder="XXXXXX"
              maxLength={6}
              spellCheck={false}
              onChange={({currentTarget}) => setJoinCode(currentTarget.value.toUpperCase())}
              // onPointerEnter={sendDragWithinPage}
              // onPointerLeave={sendDragToScroll}
              value={joinCode}
              size="lg"
              textAlign="center"
              w="6em"
            />
          </Center>
        </WorldCardBlank>
      </ModalContent>

    </Modal>
  </>);
};

export default WorldJoinWithCode;
