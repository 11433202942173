export const getServerIp = () => ({
  'development.wooorld.io': 'https://devapi.wooorld.io',
  'support.wooorld.io': 'https://api.wooorld.io',
  'localhost': 'http://localhost:5000',
}[window.location.hostname]);

export const mixpanelEventsUrl = distinctId => {
  const url = window.location.hostname === 'localhost'
    ? 'https://mixpanel.com/project/2794408/view/3329028'
    : 'https://mixpanel.com/project/2793731/view/3328359';

  return `${url}/app/profile#distinct_id=${distinctId}`;
};
