import {CloseIcon, ExternalLinkIcon} from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Input, NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {TbBrowserPlus} from 'react-icons/tb';
import {useEnterKey} from '../../hooks/useEnterKey';
import {sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import InAppWebBrowser from './UnityReactComponents/InAppWebBrowser';

const WebBrowserLauncher = ({
  onClose,
}) => {
  const [height, setHeight] = useState(1080);
  const [width, setWidth] = useState(1920);
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEnterKey(() => {
    setUrl(text);
    if (!isOpen) setIsOpen(true);
  });

  return (
    <Flex gap={1}>
      <Input
        bg="white"
        maxW="lg"
        onChange={({currentTarget}) => setText(currentTarget.value)}
        onPointerEnter={sendDragWithinPage}
        onPointerLeave={sendDragToScroll}
        value={text}
      />

      W:
      <NumberInput
        allowMouseWheel
        max={3000}
        min={100}
        onChange={(str, num) => setWidth(num)}
        step={100}
        value={width}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      H:
      <NumberInput
        allowMouseWheel
        max={3000}
        min={100}
        onChange={(str, num) => setHeight(num)}
        step={100}
        value={height}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>



      {isOpen && (
        <IconButton
          colorScheme={'wooorldBlue'}
          onClick={() => {
            setUrl(text);
          }}
          icon={<ExternalLinkIcon size="2em"/>}
          isRound
        />)}

      {!isOpen
        ? (
          <IconButton
            colorScheme={'wooorldBlue'}
            icon={<TbBrowserPlus size="2em"/>}
            isRound
            onClick={() => {
              setUrl(text);
              setIsOpen(true);
            }}
          />
        )
        : (
          <IconButton
            colorScheme={'red'}
            icon={<CloseIcon size="2em"/>}
            isRound
            onClick={() => {
              onClose();
            }}/>
        )}

      {isOpen && (
        <InAppWebBrowser
          height={height}
          url={url}
          width={width}
        />
      )}
    </Flex>
  );
};

export default WebBrowserLauncher;
