import {Box} from '@chakra-ui/react';
import {usePermissions} from '../../hooks/usePermissions';
import UpgradeButton from './UpgradeButton';

const UpgradeWrapper = ({children, descriptor = 'UpgradeWrapper', packageName, permission, ...others}) => {
  const hasPermission = usePermissions(permission);

  return hasPermission
    ? children
    : (
      <>
        {children}
        <Box position="absolute" bottom={0} {...others}>
          <UpgradeButton descriptor={descriptor} packageName={packageName}/>
        </Box>
      </>
    );
};

export default UpgradeWrapper;
