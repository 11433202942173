import {AbsoluteCenter, Avatar, Box, Center, Flex, Heading, IconButton, Tag, Text} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {MdOutlineThumbDown, MdOutlineThumbUp, MdThumbDown, MdThumbUp} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import {apiPost} from '../../api';
import BackButton from '../../components/BackButton';
import {pinRatingCreateRequest, pinRatingDeleteRequest} from '../../reducers/pinsReducer';
import {tourGetRequest, tourListRequest, tourReactRequest} from '../../reducers/toursReducer';
import {sendAudioClick, sendTextToSpeech} from '../../vuplex';
import MapNavButton from './MapNavButton';

const TourHeader = () => {
  const {aiCompletionId = undefined, promptId} = useParams();
  const {
    prompt: {
      isLoading: promptIsLoading,
      response: currentPrompt,
    },
  } = useSelector(state => state.tours);

  const {
    completion: {
      json: {
        prompt,
        places = [],
      } = {},
      rawText: completionText,
      tokensCompletion,
      tokensPrompt,
      tokensTotal,
    },
    tourPrompt: {
      alias,
      createdOn,
      gptPrompt,
      id: tourPromptId,
      isDislikedByUser,
      isLikedByUser,
      isOwnedByUser,
      isUserDev,
      likesCount,
      ownerId,
      profilePhotoURL,
      prompt: promptOriginal,
      promptInterpreted,
    },
  } = currentPrompt || {completion: {}, tourPrompt: {}};

  const dispatch = useDispatch();

  return (
    <Flex justifyItems="space-evenly" w="full">
      <Flex w="33%">
        <BackButton/>
      </Flex>

      <Flex alignItems="center" gap={2} justifyContent="center" grow={1}>
        <Avatar
          borderColor={isOwnedByUser ? 'wooorldBlue._' : '#FFD8E8'}
          name={alias}
          showBorder
          size="sm"
          src={profilePhotoURL}
        />
        <Text fontSize="md">{alias}</Text>
      </Flex>

      <Flex alignItems="center" justifyContent="flex-end" w="30%">
        <Text fontSize="md" noOfLines={1}>{likesCount || 0} {likesCount === 1 ? 'like' : 'likes'}</Text>

        <Flex gap={0}>

          {/* 👍 */}
          {!isDislikedByUser && !isOwnedByUser && (
            <IconButton
              color={`${isLikedByUser ? 'wooorldBlue._' : 'black24'} !important`}
              icon={<MdOutlineThumbUp size="1.5em"/>}
              onClick={async (e) => {
                sendAudioClick(e);
                e.preventDefault();
                e.stopPropagation();

                dispatch(tourReactRequest({promptId, reaction: '👍'}));

                //
                // const {error} = await dispatch(
                //   isLikedByUser
                //     ? pinRatingDeleteRequest({
                //       pinId: pin._id,
                //     })
                //     : pinRatingCreateRequest({
                //       pinId: pin._id,
                //       vote: 1,
                //     }));
                //
                // if (error)
                //   toast({
                //     description: `error`,
                //     status: 'error',
                //     title: 'Failed do like pin.',
                //   });
                // dispatch(tourReactRequest({promptId, reaction: '👍'}));
              }}
              size="md"
              variant="buttonDiminished"
            />
          )}


          {/* 👎 */}
          {!isLikedByUser && !isOwnedByUser && (
            <IconButton
              color={`${isDislikedByUser ? 'red' : 'black24'} !important`}

              icon={<MdOutlineThumbDown size="1.5em"/>}
              onClick={async (e) => {
                sendAudioClick(e);
                e.preventDefault();
                e.stopPropagation();

                dispatch(tourReactRequest({promptId, reaction: '👎'}));

                // const {error} = await dispatch(
                //   isDislikedByUser
                //     ? pinRatingDeleteRequest({
                //       pinId: pin._id,
                //     })
                //     : pinRatingCreateRequest({
                //       pinId: pin._id,
                //       vote: -1,
                //     }));
                //
                // if (error)
                //   toast({
                //     description: `error`,
                //     status: 'error',
                //     title: 'Failed to dislike pin.',
                //   });
                // dispatch(tourReactRequest({promptId, reaction: '👎'}));
              }}
              size="md"
              variant="buttonDiminished"
            />
          )}
        </Flex>
      </Flex>
    </Flex>

  );

  return (
    <>
      <AbsoluteCenter top={0}>
        <Avatar
          borderColor={isOwnedByUser ? 'wooorldBlue._' : '#FFD8E8'}
          name={alias}
          showBorder
          size="sm"
          src={profilePhotoURL}
        />
      </AbsoluteCenter>
      <Center>
        <Tag color="black24" variant="ghost">created by {alias} {new Date(createdOn).toDateString()}</Tag>
      </Center>

    </>

  );
};

export default TourHeader;
