import {AbsoluteCenter, Spinner} from '@chakra-ui/react';
import React from 'react';

const PlacesLoadingIndicator = ({isLoading}) =>
  isLoading
    ?(
      <AbsoluteCenter>
        <Spinner
          color="wooorldBlue._"
          emptyColor="gray.200"
          size="xl"
          speed="0.65s"
          thickness="3px"
        />
      </AbsoluteCenter>)
    : null;

export default PlacesLoadingIndicator;
