import {CloseIcon, SearchIcon, SmallCloseIcon} from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  StackDivider,
  Text
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {GiElectric} from 'react-icons/gi';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import BottomErrorIndicator from '../../components/BottomErrorIndicator';
import BottomLoadingIndicator from '../../components/BottomLoadingIndicator';
import ButtonMinor from '../../components/ButtonMinor';
import ContentSeparatorCta from '../../components/ContentSeparatorCta';
import HasPermission from '../../components/HasPermission';
import InfiniteList from '../../components/InfiniteList';
import {Whoosh} from '../../helpers/audioClips';
import {toursCreate} from '../../hooks/usePermissions';
import {useRetryOnAccessTokenRefresh} from '../../hooks/useRetryOnAccessTokenRefresh';
import {
  tourCreateBalanceRequest,
  tourCreateRequest,
  tourListRequest,
  toursFilterSet,
  toursSearchSet,
  toursSortSet
} from '../../reducers/toursReducer';
import {textInputFieldClear, textInputPlaceholderSet} from '../../reducers/uiReducer';
import {sendAudioClick, sendAudioClip, sendAudioHover, sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import CreateCompletionButton from './CreateCompletionButton';
import Filters from './Filters';
import InputBar from './InputBar';
import PageTitle from './PageTitle';
import TourHelpButton from './TourHelpButton';
import TourPromptCard from './TourPromptCard';
import UpgradeButton from './UpgradeButton';

const updateSearchDebounced = _.debounce((value, dispatch) => dispatch(toursSearchSet(value)), 800);


const Tours = ({}) => {
  const {
    error: tourListError,
    filter,
    filterOptions,
    isLoading: tourListIsLoading,
    response: tourListResponse,
    sort,
    sortOptions,
  } = useSelector(s => s.tours.prompts);

  const tourRequestState = useSelector(s => s.tours.prompt);
  const {
    error,
    isLoading: promptIsLoading,
    response: currentPrompt,
  } = tourRequestState;

  const {hasNextPage, page, tourPrompts} = tourListResponse || {};

  const {voiceInput} = useSelector(({inApp}) => inApp);
  const {textInputField} = useSelector(({ui}) => ui.txtInputTours);
  const [goToTourWhenLoaded, setGoToTourWhenLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => { // todo: useInputPlaceholder
    if (!voiceInput.isLoading)
      dispatch(textInputPlaceholderSet({key: 'txtInputTours', val: 'Describe your dream tour'}));

  }, [voiceInput.isLoading]);

  const handleCreateButtonClick = () => {
    setGoToTourWhenLoaded(true);
    return dispatch(tourCreateRequest({prompt: textInputField})); // for "done animation in createCompletionButton"
  };

  useRetryOnAccessTokenRefresh(async () => {
    if (textInputField) {
      return dispatch(tourCreateBalanceRequest({}));
    }
  }, [!!textInputField]);

  useEffect(() => {
    if (!tourListResponse && !tourListIsLoading && !tourListError) dispatch(tourListRequest({}));
  }, [tourListResponse, tourListIsLoading, tourListError]);

  const {
    tourPrompt: {
      _id,
    },
  } = currentPrompt || {completion: {}, tourPrompt: {}};

  const navigate = useNavigate();
  useEffect(() => {
    if (!promptIsLoading && !error && goToTourWhenLoaded && _id) {
      setTimeout(() => {
        sendAudioClip({
          clientX: 0,
          clientY: 0,
          clip: Whoosh,
        });
        dispatch(textInputFieldClear({key: 'txtInputTours'}));
        setGoToTourWhenLoaded(false);
        navigate(`/wooorld/tablet/tours/tour/${_id}`);
      }, 1000);
    }
  }, [goToTourWhenLoaded, _id, promptIsLoading]);

  const handleFilterClick = (filterClicked) => () => {
    dispatch(toursFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(toursSortSet(sortClicked));
  };

  const fetchNextPage = () => dispatch(tourListRequest({}));

  // TODO: virtualized list via react-virtualized or react-window, react-window-scroller, react-window-infiniteLoader
  // lots of moving parts to get virtualized window (html element) scrollable for existing sticky content and infiniteLoader

  // const {
  //   tourCreateBalance,
  // } = useSelector(s => s.tours.tourCreateBalance.response) || {};
  // const isDisabled = tourCreateBalance === 0;

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const isFiltered = filter || sort !== 'likes';
  const search = useSelector(s => s.tours.prompts.search);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    if (searchInput) updateSearchDebounced(searchInput, dispatch);
    else {
      updateSearchDebounced.cancel();
      dispatch(toursSearchSet(''));
    }
  }, [searchInput]);

  return (
    <>
      <PageTitle title="Tours - Wooorld"/>

      <InputBar
        contentRight={
          <>
            <HasPermission
              permission={toursCreate}
              fallbackContent={
                <AbsoluteCenter>
                  <UpgradeButton descriptor="Tours InputBar" packageName={'explorer+'}/>
                </AbsoluteCenter>
              }
            />
            <TourHelpButton/>
          </>
        }
        isAi
        iconLeft={<GiElectric size="1.5em"/>}
        permission={toursCreate}
        stateKey="txtInputTours"
      />

      {textInputField
        ? (
          <CreateCompletionButton
            label="Create Tour"
            onClick={handleCreateButtonClick}
            requestState={tourRequestState}
          />

        )
        : (
          <Box>
            <Flex p={2} paddingTop={4}>
              <Flex alignItems="center" gap={2} w="33%">
                <InputGroup
                  transition="width 0.3s ease"
                  display="flex"
                  width={isSearchExpanded || !!search ? '11em' : '4em'}
                >
                  <InputLeftElement color="black24" pointerEvents="none">
                    <SearchIcon/>
                  </InputLeftElement>

                  <Input
                    _disabled={{
                      opacity: .7,
                    }}
                    _placeholder={{
                      color: 'black24',
                      fontStyle: 'italic',
                      opacity: '1',
                    }}
                    bg="white"
                    borderRadius="3xl"
                    colorScheme="wooorldBlue"
                    onFocus={(e) => {
                      sendAudioClick(e);
                      setIsSearchExpanded(true);
                    }}
                    onBlur={() => setIsSearchExpanded(false)}
                    onChange={({target: {value}}) => {
                      setSearchInput(_.replace(value, '.', ''));
                    }}
                    onPointerEnter={(e) => {
                      sendAudioHover(e);
                      sendDragWithinPage();
                    }}
                    onPointerLeave={sendDragToScroll}
                    value={searchInput}
                  />

                  {searchInput && (
                    <InputRightElement>

                      <IconButton
                        borderRadius="full"
                        colorScheme="red"
                        icon={<CloseIcon/>}
                        onClick={(evt) => {
                          setSearchInput('');
                        }}
                        variant="ghost"
                      />
                    </InputRightElement>
                  )}

                </InputGroup>
              </Flex>

              <ContentSeparatorCta>
                or try these tours
              </ContentSeparatorCta>

              <Flex alignItems="center" justifyContent="flex-end" gap={2} w="33%">
                {filter && (
                  <ButtonMinor
                    alignItems="center"
                    bg="wooorldBlue._"
                    fontSize="1.5em"
                    onClick={handleFilterClick(null)}
                    position="relative"
                    p={0}
                    size="md"
                  >
                    {filterOptions.find(p => p.value === filter).emoji}
                    <SmallCloseIcon
                      background="red"
                      borderRadius="full"
                      color="white"
                      pointerEvents="none"
                      position="absolute"
                      bottom="-.25em"
                      right="-.25em"
                      fontSize=".75em"
                    />
                  </ButtonMinor>
                )}

                <Filters isActive={isFiltered}>
                  <Text color="lightgrey">Filter</Text>
                  {filterOptions.map(({value, label, isSeparator}, idx) => {
                    const isSelected = filter === value;
                    return (
                      <ButtonMinor
                        bg={isSelected ? 'wooorldBlue._' : null}
                        color={isSelected ? 'white' : null}
                        key={idx}
                        justifyContent="left"
                        onClick={handleFilterClick(value)}
                        position="relative"
                        size="sm"
                      >
                        {label}
                      </ButtonMinor>
                    );
                  })}
                  <StackDivider border="solid 1px grey"/>
                  <Text color="lightgrey">Sort by</Text>
                  {sortOptions.map(({value, label, isSeparator}, idx) => {
                    const isSelected = sort === value;
                    return (
                      <ButtonMinor
                        bg={isSelected ? 'wooorldBlue._' : null}
                        color={isSelected ? 'white' : null}
                        key={idx}
                        justifyContent="left"
                        onClick={handleSortClick(value)}
                        position="relative"
                        size="sm"
                      >
                        {label}
                      </ButtonMinor>
                    );
                  })}
                </Filters>
              </Flex>
            </Flex>


            <InfiniteList
              {...{
                columns: 3,
                error: tourListError,
                isFiltered: isFiltered || !!search,
                isLoading: tourListIsLoading,
                hasNextPage,
                page,
                items: tourPrompts,
                itemHeight: '11.5em',
              }}
              onFetchNextPage={fetchNextPage}
              renderItem={(tourPrompt, idx) =>
                <TourPromptCard
                  key={idx}
                  {...tourPrompt}
                />}
            />
          </Box>
        )}
    </>
  );


};

export default Tours;
