import {Box, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate, useRoutes} from 'react-router-dom';
import BackButton from '../../components/BackButton';
import {textInputFieldClear} from '../../reducers/uiReducer';
import {sendTextToSpeechStop} from '../../vuplex';
import GooglePlace from './GooglePlace';
import GooglePlaceHeader from './GooglePlaceHeader';
import GooglePlacesAutoComplete from './GooglePlacesAutoComplete';
import InputBar from './InputBar';
import PinEditForm from './PinEditForm';
import PinFolderEditForm from './PinFolderEditForm';
import Tour from './Tour';
import TourHeader from './TourHeader';
import TourStopDetails from './TourStopDetails';
import TourStopHeader from './TourStopHeader';
import Trip from './TripPlanner/Trip';
import TripHeader from './TripPlanner/TripHeader';
import WikiDetails from './WikiDetails';

const ModalView = () => {
  const {textInputField} = useSelector(({ui}) => ui.txtInputExplore);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const isTextInputDirty = !_.isEmpty(textInputField);

  // This is a bit of a hack, misusing `useRoutes` to determine if the modal should be open based on the current url
  const isModalOpen = useRoutes([
    {
      path: 'pins/:view/edit',
      element: true,
    },
    {
      path: 'pins/:view/folderEdit',
      element: true,
    },
    {
      path: 'tripPlanner/trip/:promptId',
      element: true,
    },
    {
      path: 'tours/tour/:promptId/*',
      element: true,
    },
    {
      path: 'tours/wikipedia/:wikiPageId',
      element: true,
    },
    {
      path: 'explore/places/:placeId',
      element: true,
    },
    {
      path: 'places/:placeId',
      element: true,
    },
    {
      path: 'explore/wikipedia/:wikiPageId',
      element: true,
    },
    ...(isTextInputDirty
      ? [
        {
          path: '/',
          element: true,
        },
        {
          path: '/explore/*',
          element: true,
        }
      ] : []),
  ]);

  const noBgGradient = useRoutes([
    {
      path: 'explore/wikipedia/:wikiPageId',
      element: true,
    },
    ...(isTextInputDirty
      ? [
        {
          path: '/',
          element: true,
        },
        {
          path: '/explore/*',
          element: true,
        }
      ] : []),
  ]);

  const dispatch = useDispatch();

  return (
    <Modal
      autoFocus={false}
      isOpen={isModalOpen}
      onClose={() => {
        navigate(-1);
        sendTextToSpeechStop();
      }}
      scrollBehavior="inside"
      size="3xl"
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(3px)"
      />

      <ModalContent
        bg="bg"
        bgGradient={noBgGradient ? 'none' : 'linear(90deg, #C5EAEF, #D2C1EE)'}
        borderRadius="3xl"
        h="100vh"
        margin={0}
        maxHeight="unset"
        maxWidth="unset"
        w="100vw"

        // https://github.com/chakra-ui/chakra-ui/issues/7588#issuecomment-2446304424
        // fixes scroll in folder list when editing pin from folder drawer
        onWheel={(e) => {
          e.stopPropagation();
        }}
        onTouchMove={(e) => {
          // e.preventDefault();
          e.stopPropagation();
        }}
        onScroll={(e) => {
          // e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ModalHeader
          display="flex"
          p={2}
          position="relative"
          alignItems="center"
          w="full"
        >
          <Routes>
            <Route path="explore/places/:placeId" element={<GooglePlaceHeader/>}/>
            <Route path="explore/wikipedia/:wikiPageId" element={<BackButton/>}/>
            <Route path="pins/:view/edit" element={null}/>
            <Route path="pins/:view/folderEdit" element={null}/>
            <Route path="places/:placeId/*" element={<GooglePlaceHeader/>}/>
            <Route path="tours/tour/:promptId" element={<TourHeader/>}/>
            <Route path="tours/tour/:promptId/:placeIndex" element={<TourStopHeader/>}/>
            <Route path="tours/tour/:promptId/:placeIndex/wikipedia/:wikiPageId" element={<BackButton/>}/>
            <Route path="tripPlanner/trip/:promptId" element={<TripHeader/>}/>
            {isTextInputDirty && (
              <Route
                path="explore/*"
                element={<Box w="full">
                  <InputBar
                    contentLeft={
                      <BackButton
                        onClick={() => {
                          dispatch(textInputFieldClear({key: 'txtInputExplore'}));
                        }}
                      />
                    }
                    isAutofocus
                    stateKey="txtInputExplore"
                  />
                </Box>}/>
            )}
          </Routes>
        </ModalHeader>

        <ModalBody
          key={pathname} // hack to scroll to top on route change
          paddingTop={3}
          p={0}
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.07)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.07)`,
            },
          }}
        >
          <Routes>
            <Route path="explore/places/:placeId" element={<GooglePlace/>}/>
            <Route path="explore/wikipedia/:wikiPageId" element={<WikiDetails/>}/>
            <Route path="pins/:view/edit" element={<PinEditForm/>}/>
            <Route path="pins/:view/folderEdit" element={<PinFolderEditForm/>}/>
            <Route path="places/:placeId" element={<GooglePlace/>}/> {/*for autocomplete results*/}
            <Route path="tours/tour/:promptId" element={<Tour/>}/>
            <Route path="tours/tour/:promptId/:placeIndex" element={<TourStopDetails/>}/>
            <Route path="tours/tour/:promptId/:placeIndex/wikipedia/:wikiPageId" element={<WikiDetails/>}/>
            <Route path="tripPlanner/trip/:promptId" element={<Trip/>}/>
            {isTextInputDirty && <Route path="explore/*" element={<GooglePlacesAutoComplete/>}/>}
          </Routes>
        </ModalBody>

        <ModalFooter position="relative" p={0}>
          {/*<Routes>*/}
          {/*  <Route path="pins/:view/edit" element={null}/>*/}
          {/*  <Route path="pins/:view/folderEdit" element={null}/>*/}
          {/*  <Route path="*" element={*/}
          {/*    <Box p={2} w="full">*/}
          {/*      <TextToSpeechButton/>*/}
          {/*    </Box>*/}
          {/*  }/>}*/}
          {/*</Routes>*/}


          <Routes>
            {/*<Route path="tours/tour/:promptId" element={<TourFooter/>}/>*/}
            {/*<Route path="tours/tour/:promptId/:placeIndex" element={<TourStopFooter/>}/>*/}
            {/*<Route path="explore/places/:placeId" element={<PlaceFooter/>}/>*/}
            {/*<Route path="places/:placeId" element={<PlaceFooter/>}/>*/}
            <Route path="pins/:view/edit" element={null}/>
            <Route path="pins/:view/folderEdit" element={null}/>
            {/*<Route path="*" element={<PlaceAutoCompleteFooter/>}/>*/}
          </Routes>


        </ModalFooter>

      </ModalContent>

    </Modal>
  );
};

export default ModalView;
