import React from 'react';
import {useSelector} from 'react-redux';
import {
  pinsSearchFilterSet,
  pinsSearchRequest,
  pinsSearchResultsClear,
  pinsSearchSortSet
} from '../../reducers/pinsReducer';
import PinsList from './PinsList';
import PinsListController from './PinsListController';
import PinsListFilters from './PinsListFilters';

const PinsView = ({}) => {
  const isViewingCluster = !!useSelector(s => s.pins.pinsListSearch.ids);
  const isUserPinsOnly = useSelector(s => s.pins.pinsListSearch.filter) === 'createdByUser';
  const isSearching = !!useSelector(s => s.ui.txtInputPlacesLists.textInputField);

  return (
    <>
      <PinsListFilters
        stateKey="pinsListSearch"
        textInputStateKey="txtInputPlacesLists"
        onFilterSet={pinsSearchFilterSet}
        onSortSet={pinsSearchSortSet}
      />

      <PinsListController
        onClearPins={pinsSearchResultsClear}
        onRequestPins={pinsSearchRequest}
        stateKey="pinsListSearch"
        textInputStateKey="txtInputPlacesLists"
      />

      <PinsList
        columns={2}
        isFiltered={isSearching || isUserPinsOnly || isViewingCluster}
        onRequestPins={pinsSearchRequest}
        stateKey="pinsListSearch"
        textInputStateKey="txtInputPlacesLists"
      />
    </>
  );


};

export default PinsView;
