import axios from 'axios';
import {setAccessToken as setApiAccessToken} from './api';
import {getServerIp} from './helpers/params';
import {authClear} from './reducers/gooodReducer';


const server = getServerIp();
let store;

export const setStore = (reduxStore) => {
  store = reduxStore;
}

const setSessionStorage = (key, value) => {
  if (value === undefined) window.sessionStorage.removeItem(key);
  else window.sessionStorage.setItem(key, JSON.stringify(value));
}

export const clearTokens = () => {
  setSessionStorage('accessToken', undefined);
  setSessionStorage('refreshToken', undefined);
  if (store) store.dispatch(authClear());
}
export const setAccessToken = (val) => {
  setSessionStorage('accessToken', val);
  setApiAccessToken(val);
}

export const setBingMapsApiKey = (val) => setSessionStorage('bingMapsApiKey', val);
export const setDemoWorldId = (val) => setSessionStorage('demoWorldId', val);
export const setRefreshToken = (val) => setSessionStorage('refreshToken', val);

const getFromSessionStorage = (key) => {
  const value = window.sessionStorage.getItem(key);
  return value && JSON.parse(value);
}

setApiAccessToken(getFromSessionStorage('accessToken'));

export const getAccessToken = () => getFromSessionStorage('accessToken');
export const getBingMapsApiKey = () => getFromSessionStorage('bingMapsApiKey');
export const getDemoWorldId = () => getFromSessionStorage('demoWorldId');
export const getRefreshToken = () => getFromSessionStorage('refreshToken');

export const gooodPost = async (route, payload, accessToken = getAccessToken(), refreshToken = getRefreshToken()) => {
  return axios.post(`${server}/goood${route}`, payload, accessToken && {
    headers: {Authorization: `Bearer ${accessToken}`},
  }).catch(async (e) => {
    console.error(route, 'data:', e.response.data, 'status:', e.response.status, 'statusText:', e.response.statusText);

    if (e.response.status === 401) {
      if (refreshToken) {
        console.log('refreshing token');

        const {data: {accessToken}} = await axios.post(`${server}/goood/refresh-token`, {refreshToken})
          .catch((e) => {
            console.error(`couldn't refresh token. clearing state`);
            clearTokens();
          });

        console.log('saving token, retrying request', accessToken);
        setAccessToken(accessToken);

        // retry initial post now that we've refreshed our access token
        // calling with accessToken, otherwise I don't know how to ensure we use the updated state. setState callbacks not a thing in useState.
        return gooodPost(route, payload);
      } else {
        console.error(`no refresh token. clearing state`);
        clearTokens();
      }
    } else {
      throw(e.response.data);
    }
  });
};
