export const Bump = 'Bump';
export const Clap = 'Clap';
export const Click = 'Click';
export const Confirm = 'Confirm';
export const CriticalError = 'CriticalError';
export const Decline = 'Decline';
export const Drum1 = 'Drum1';
export const Drum2 = 'Drum2';
export const Error = 'Error';
export const Game60SecondTimer = 'Game60SecondTimer';
export const GameCorrect = 'GameCorrect';
export const GameDamage = 'GameDamage';
export const GameEndNeutral = 'GameEndNeutral';
export const GameIncorrect = 'GameIncorrect';
export const GameLose = 'GameLose';
export const GameRoundNeutral = 'GameRoundNeutral';
export const GameTransition = 'GameTransition';
export const GameWin = 'GameWin';
export const Grab = 'Grab';
export const HeartbeatTimer = 'HeartbeatTimer';
export const Hover = 'Hover';
export const KnockKnock = 'KnockKnock';
export const Login = 'Login';
export const LookHere = 'LookHere';
export const Magic = 'Magic';
export const MouthPop = 'MouthPop';
export const None = 'None';
export const Notification = 'Notification';
export const PopUp = 'PopUp';
export const Release = 'Release';
export const Scroll = 'Scroll';
export const Sketch = 'Sketch';
export const Tap = 'Tap';
export const Warning = 'Warning';
export const Welcome = 'Welcome';
export const Whoosh = 'Whoosh';
export const Whoosh2 = 'Whoosh2';
