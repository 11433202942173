import React from 'react';
import {useSelector} from 'react-redux';
import {
  foldersSearchFilterSet,
  foldersSearchRequest,
  foldersSearchResultsClear,
  foldersSearchSortSet
} from '../../reducers/pinsReducer';
import PinFolderDrawer from './PinFolderDrawer';
import PinFoldersSearchList from './PinFoldersSearchList';
import PinsListController from './PinsListController';
import PinsListFilters from './PinsListFilters';

const FoldersView = ({}) => {
  return (
    <>
      <PinsListFilters
        stateKey="foldersListSearch"
        textInputStateKey="txtInputPlacesLists"
        onFilterSet={foldersSearchFilterSet}
        onSortSet={foldersSearchSortSet}
      />

      <PinsListController
        onClearPins={foldersSearchResultsClear}
        onRequestPins={foldersSearchRequest}
        stateKey="foldersListSearch"
        textInputStateKey="txtInputPlacesLists"
      />

      <PinFoldersSearchList
        onRequestItems={foldersSearchRequest}
        stateKey="foldersListSearch"
      />

      <PinFolderDrawer/>
    </>
  );


};

export default FoldersView;
