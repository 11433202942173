import {ChevronDownIcon} from '@chakra-ui/icons';
import {Center, Heading, IconButton, Stack} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {tripPlannerFormUpdate} from '../../../reducers/toursReducer';
import {textInputFieldClear, textInputFieldSet} from '../../../reducers/uiReducer';
import InputBar from '../InputBar';

const Step1 = () => {
  const {textInputField} = useSelector(({ui}) => ui.txtInputTours);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {location} = useSelector(({tours}) => tours.tripPlanner.form);

  useEffect(() => {
    if (location) dispatch(textInputFieldSet({key: 'txtInputTours', val: location}));
    return () => dispatch(textInputFieldClear({key: 'txtInputTours'}));
  }, []);

  const onClickNext = () => {
    dispatch(tripPlannerFormUpdate({place: textInputField}));
    navigate('../2');
  };

  useEffect(() => {
    const onKeydown = ({key}) => {
      if (key === 'Enter') {
        onClickNext();
        document.activeElement.blur();
      }
    };
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [textInputField]);

  return (
    <Stack
      bgGradient="linear(to-b, #FFE5CD, #FCDDEC)"
      borderRadius="lg"
      p={5}
      spacing={6}
      textAlign="center"
    >
      <Heading p={4} size="md">Step 1/3 Where to?</Heading>

      <InputBar isBgTransparent stateKey="txtInputTours"/>

      <Center>
        <IconButton
          colorScheme="wooorldBlue"
          icon={<ChevronDownIcon boxSize="2em"/>}
          isDisabled={!textInputField}
          isRound
          onClick={onClickNext}
        />
      </Center>

    </Stack>);
};

export default Step1;
