import {useEffect} from 'react';
import {getVuplexMessageHandlers} from '../vuplex';

/**
 * Wires up listeners to handle incoming Vuplex messages from Unity.
 * Removes and re-adds listeners when deps change.
 * @param messageHandlers
 * @param deps
 */
export const useVuplex = (messageHandlers, deps = []) => {
  useEffect(() => {
    if (!window.vuplex) return; // for embedding app components in Goood

    const handleVuplexMessage = getVuplexMessageHandlers(messageHandlers);
    window.vuplex.addEventListener('message', handleVuplexMessage);
    return () => window.vuplex.removeEventListener('message', handleVuplexMessage);
  }, deps);
};
