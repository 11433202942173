import {Box, Button, Code, Heading, StackDivider, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SearchInput from '../../../components/SearchInput';
import {
  userRolesListRequest,
  userRolesUpdateRequest,
  usersPermissionsAdminFilterSet,
  usersPermissionsAdminSearchSet,
  usersPermissionsAdminSortSet
} from '../../../reducers/gooodReducer';
import Filters from '../../WooorldInApp/Filters';
import TableEditor from '../TableEditor';

const UserRolesTable = () => {
  const {
    error,
    filter,
    filterOptions,
    isLoading,
    response,
    search,
    sort,
    sortOptions,
  } = useSelector(s => s.goood.permissionsAdmin.users);

  const items = response || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!response && !isLoading && !error) {
      dispatch(userRolesListRequest({}));
    }
  }, [response, isLoading, error]);

  const {isLoading: isRolesLoading, response: rolesResponse} = useSelector(s => s.goood.permissionsAdmin.roles);
  const roles = rolesResponse || [];
  const rolesById = _.fromPairs(roles.map(p => [p._id, p.name]));
  const roleValuesToLabels = values => values && values.map(val => rolesById[val] || val);

  const rolesOptions = roles.map(p => ({
    label: p.name,
    value: p._id,
  }));

  const handleFilterClick = (filterClicked) => () => {
    dispatch(usersPermissionsAdminFilterSet(filterClicked));
  };

  const handleSortClick = (sortClicked) => () => {
    dispatch(usersPermissionsAdminSortSet(sortClicked));
  };

  return (
    <>
      <Heading
        align="center"
        as="h2"
        fontWeight={400}
        margin="1.5rem"
        position="relative"
        size="md"
      >
        <SearchInput
          allowPeriod
          onChangeDebounced={val => dispatch(usersPermissionsAdminSearchSet(val))}
          value={search}
        />

        <Box position="absolute" right={0} top={0}>
          <Filters>
            <Text color="lightgrey">Filter</Text>
            {filterOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = filter === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleFilterClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
            <StackDivider border="solid grey"/>
            <Text color="lightgrey">Sort by</Text>
            {sortOptions.map(({value, label, isSeparator}, idx) => {
              const isSelected = sort === value;
              return (
                <Button
                  colorScheme={isSelected ? 'wooorldBlue' : null}
                  key={idx}
                  justifyContent="left"
                  onClick={handleSortClick(value)}
                  position="relative"
                  size="sm"
                >
                  {label}
                </Button>
              );
            })}
          </Filters>

        </Box>
      </Heading>

      <TableEditor
        error={error}
        fields={[
          {
            isReadOnly: true,
            key: 'alias',
          },
          {
            inputType: 'multiselect',
            key: 'roles',
            options: rolesOptions,
            valToLabel: roleValuesToLabels,
          },
        ]}
        isLoading={isLoading || isRolesLoading}
        items={items}
        onCreateOrUpdate={userRolesUpdateRequest}
        title="User Roles"
      />
    </>
  );
};

export default UserRolesTable;
