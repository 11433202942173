import {Box} from '@chakra-ui/react';
import React from 'react';
import {useParams} from 'react-router-dom';

const WikiDetails = ({}) => {
  const {wikiPageId} = useParams();

  return (
    <Box
      borderRadius="xl"
      h="full"
      overflow="hidden"
    >
      <meta httpEquiv="Content-Security-Policy" content="child-src https://wikipedia.org/"/>
      <iframe
        className="iframe"
        height="100%"
        width="100%"
        src={`https://en.m.wikipedia.org/wiki?curid=${wikiPageId}`}
      />
    </Box>
  );
};

export default WikiDetails;
