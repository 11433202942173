import {Badge, Flex, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import ButtonMinor from '../../components/ButtonMinor';
import {useLobbyHelp} from '../../hooks/useLobbyHelp';
import {sendAudioClick, sendWorldJoin} from '../../vuplex';
import InAppTooltip from './UnityReactComponents/InAppTooltip';
import WorldCardBlank from './WorldCardBlank';


const WorldBadge = ({children, color = 'white', tooltip}) => (
  <InAppTooltip message={tooltip}>
    <Badge
      bg="blackAlpha.300"
      borderRadius="2xl"
      color={color}
      fontWeight={100}
      paddingX={2}
      paddingY="0.1em"
      textTransform="none"
    >
      {children}
    </Badge>
  </InAppTooltip>
);

const WorldCard = ({world}) => {
  const {
    _id,
    blockedUserIds = [],
    isFull,
    isLobby,
    joinCode,
    rules,
    name,
    ownerId,
    participants, // {_id: alias, ...}
    userCount,
  } = world;

  const {
    isPrivate,
    isSolo,
    isWitw
  } = rules || {};
  const currentWorld = useSelector(s => s.inApp.world) || {};
  const userId = useSelector(s => s.inApp.userId);
  const userIdsBlockedByUser = useSelector(s => s.worlds.userIdsBlockedByUser);

  const hasBlockedUser = _.some(_.keys(participants), p => userIdsBlockedByUser[p]);
  const isBlocked = _.includes(blockedUserIds, userId);
  const isCurrentWorld = currentWorld._id === _id;
  const isDisabled = isCurrentWorld || isFull || isBlocked;
  const isHelpStepExploreWitw = useLobbyHelp(isWitw ? 6 : 5) && !isLobby;

  const handleClick = (evt) => {
    if (isDisabled) return;

    sendWorldJoin({
      worldId: _id,
    });

    sendAudioClick(evt);
  };

  const footerContent = (
    <Flex justifyContent="center" w="full">

      {isCurrentWorld
        ? (
          <ButtonMinor
            bg="lightgreen!important"
            isDisabled
            tooltip="You're already in this world"
          >
            🚩 You're here
          </ButtonMinor>
        ) : isBlocked
          ? (
            <ButtonMinor
              bg="darkgrey!important"
              isDisabled
              tooltip="The owner of this world blocked you.<br>Try creating or joining another world."
            >
              🚫 Blocked
            </ButtonMinor>
          ) : isFull
            ? (
              <ButtonMinor
                bg="darkgrey!important"
                isDisabled
                tooltip="This world full.<br>Try creating or joining another world."
              >
                ⛔ World is Full
              </ButtonMinor>
            )
            : (
              <ButtonMinor
                tooltip="Click to join this world"
              >
                Join World
              </ButtonMinor>
            )}
    </Flex>
  );

  const headerContent = (
    <Flex gap={1} justifyContent="right">
      {hasBlockedUser && (
        <WorldBadge tooltip="Someone you blocked is in this world">
          ⚠
        </WorldBadge>
      )}

      <WorldBadge color={isFull ? '#fcd' : 'white'}>
        {isFull ? '🙅‍' : '🙋‍'} {userCount}/8
      </WorldBadge>

      {!isLobby && (
        <WorldBadge
          tooltip={
            isSolo
              ? `This is a Solo World, only for you`
              : isPrivate
                ? `This is a Private World, open to invited users only`
                : `This is a Public World, open to anyone`
          }
        >
          {`${isSolo ? '🔴 Solo' : isPrivate ? '🟠 Private' : '🟢 Public'}`}
        </WorldBadge>
      )}

      {joinCode && (
        <WorldBadge tooltip="Invite code">
          {joinCode}
        </WorldBadge>
      )}
    </Flex>
  );

  const hoverContent = !isLobby && (<>
    <Text
      align="left"
      bg="black24"
      color="white"
      fontSize="xs"
      noOfLines={3}
      borderRadius="xl"
      p={2}
      h="70px"
    >
      {_.toPairs(participants)// {id: alias, ...} => [[id, alias], ...]
        .sort(([idA, aliasA], [idB, aliasB]) =>
          idA === ownerId
            ? -1
            : aliasA > aliasB
              ? 1
              : 0)
        .map(([id, alias], idx, coll) => {
          const isBlockedByUser = userIdsBlockedByUser[id];

          return (
            <Text
              as="span" color={isBlockedByUser ? '#FFB3AE' : 'white'}
              key={id}
              marginRight={1}
              whiteSpace="nowrap"
            >
              {ownerId === id && '👑'}
              {isBlockedByUser && '🚫'}
              {alias}
              {(idx < coll.length - 1) && ','}
              &nbsp;
            </Text>
          );
        })}
    </Text>
  </>);

  const splitName = (name || '').split(/ (.*World)/, 2);
  const nameOnTwoLines = !isLobby && [splitName[0], <br key="br" />, splitName[1]];

  return (
    <WorldCardBlank
      contentHeight={"full"}
      footerContent={footerContent}
      headerContent={headerContent}
      height="11em"
      hoverContent={hoverContent}
      isHelpHighlighted={isHelpStepExploreWitw}
      landscapeHeight={110}
      landscapeTop="80px"
      onClick={handleClick}
      world={world}
    >
      <Text
        align="center"
        color="black.24"
        fontWeight="bold"
        h="full"
        noOfLines={2}
        paddingTop={2}
        w="full"
      >
        {isLobby ? 'The Lobby' : nameOnTwoLines}
      </Text>
    </WorldCardBlank>
  );
};

export default WorldCard;
