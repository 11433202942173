import {useToast} from '@chakra-ui/react';
import React, {useRef} from 'react';
import {MdOutlinePanoramaPhotosphere} from 'react-icons/md';
import {useVuplex} from '../../../../hooks/useVuplex';


const PanoNoneForLocationToast = ({onClose}) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const closeCurrentToast = () => toast.close(toastIdRef.current);

  useVuplex({
    noPanoForLocation: () => {
      toast.closeAll();

      toastIdRef.current = toast({
        description: `We could not find a 360 near this location.`,
        duration: 1500,
        icon: <MdOutlinePanoramaPhotosphere color="#fff" size="1.5em"/>,
        onCloseComplete: onClose,
        position: 'bottom',
        status: 'error',
        title: 'No 360 found',
      });
    },
  })

  return null;
};

export default PanoNoneForLocationToast;
