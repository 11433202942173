import {Box, Card, CardBody, Heading, Skeleton, Stack, Tag, Text} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {tripIsSpokenSet} from '../../../reducers/toursReducer';
import {textToSpeechStatusSet} from '../../../reducers/uiReducer';
import {sendTextToSpeech, sendTextToSpeechStop} from '../../../vuplex';

import TripDay from './TripDay';

const Trip = () => {
  const {promptId} = useParams();
  const {
    trip: {
      isLoading,
      isSpoken,
      response,
    },
  } = useSelector(({tours}) => tours.tripPlanner);

  const {
    completion: {
      json: {
        prompt,
        days = [],
      } = {},
      rawText: completionText,
      tokensCompletion,
      tokensPrompt,
      tokensTotal,
    },
    tripPrompt: {
      _id: tripPromptId,
      alias,
      createdOn,
      gptPrompt,
      isOwnedByUser,
      ownerId,
      profilePhotoURL,
      promptInterpreted,
      tags,
    },
  } = response || {completion: {}, tripPrompt: {}};

  const dispatch = useDispatch();
  // useEffect(() => {
  //   // console.log('p', tourPromptId, promptId, promptIsLoading);
  //   if (tourPromptId === promptId) {
  //     console.log(`already got prompt ${promptId}`);
  //     return;
  //   }
  //
  //   dispatch(tourGetRequest({promptId}));
  // }, []);

  const speak = () => {
    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeech(`${promptInterpreted}`);
  };

  useEffect(() => {
    if (!isSpoken) {
      speak();
      dispatch(tripIsSpokenSet());
    }
  }, [tripPromptId, promptId]);

  return (
    <Skeleton isLoaded={!isLoading}>
      {response && (
        <Box
          bgGradient="linear(to-b, #DADADA, wooorldLavender.100)"
          borderRadius="lg"
          padding={3}
        >
          <Stack spacing={3}>
            <Card>
              <CardBody>
                <Heading color={isOwnedByUser ? 'wooorldBlue._' : 'grey'} size="md">
                  {alias}
                  &nbsp;
                  <Tag>created {createdOn}</Tag>
                </Heading>
                {tags.map((t, idx) => <Tag key={idx}>{t}</Tag>)}
                {/*<p>💲 prompt: {tokensPrompt} + completion: {tokensCompletion} = total {tokensTotal}</p>*/}
                {/*<Text>{promptInterpreted}</Text>*/}
              </CardBody>
            </Card>

            <Text onClick={speak}>{promptInterpreted}</Text>
            {days.map((day, idx) => (
              <TripDay
                {...day}
                isOwnedByUser={isOwnedByUser}
                dayIndex={idx}
                promptId={tripPromptId}
                key={idx}
              />))}
          </Stack>

          {/*{JSON.stringify(places, null, 2)}*/}

        </Box>)}

    </Skeleton>);
};

export default Trip;
