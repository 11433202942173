import {Box, Card, CardBody, CardFooter, Center, Flex, Link, Stack, Text} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import GoogleAttribution from './GoogleAttribution';
import PlacePhotos from './PlacePhotos';
import PlaceReviews from './PlaceReviews';
import TourStopAttachments from './TourStopAttachments';
import TourStopCard from './TourStopCard';
import TourStopFooter from './TourStopFooter';

const TourStopDetails = ({}) => {
  const {promptId, placeIndex: placeIndexParam} = useParams();
  const placeIndex = parseInt(placeIndexParam, 10);

  const {
    completion,
    tourPrompt,
  } = useSelector(({tours}) => tours.prompt.response) || {};

  const tourStop = _.get(completion, `json.places.${placeIndex}`, {});

  const {
    // address,
    alias,
    // coordinates: [{lat, lon}] = [{}],
    description,
    googlePlace,
    isOwnedByUser,
    // name,
    panoId,
    wikiPageId,
    wikiSearch,
    wikiSummary,
    wikiThumbnail,
  } = tourStop;

  const {
    editorial_summary: {overview} = {},
    formatted_address: address,
    geometry: {
      location: {lat, lng},
      // viewport: {
      //   northeast: {
      //     lat: 48.86355484999999,
      //     lng: 2.342908599999999,
      //   },
      //   southwest: {
      //     lat: 48.85824504999999, lng: 2.330669800000001,
      //   },
      // }
    } = {location: {}},
    icon,
    name,
    place_id: placeIdInStore,
    photos = [],
    reviews = [],
    types: [placeType, /* there may be more than 1 */] = [],
    types = [],
  } = googlePlace || {};

  const googleThumbnail = _.get(googlePlace, 'photos.0.photoUrl');
  const thumbnail = _.get(wikiThumbnail, 'source') || googleThumbnail || '';

  return (
    <>
      <Flex p={2} gap={4} minH="100vh">
        <Flex direction="column" gap={4} shrink={0}>
          <PlacePhotos
            firstPhotoOverride={thumbnail}
            photos={photos}
          />

          <TourStopAttachments/>

        </Flex>

        <Stack flexGrow={2} overflow="hidden">
          <TourStopCard
            {...tourStop}
            ownerAlias={alias}
            placeIndex={placeIndex}
            isCurrentStop
          />

          {wikiSummary && (
            <Card borderRadius="2xl">
              <CardBody>
                <Text>{wikiSummary}</Text>
              </CardBody>
              <CardFooter>
                <Center w="full">
                  <Link
                    as={NavLink}
                    borderTop="solid 1px"
                    fontSize="xl"
                    paddingX="1em"
                    state={{
                      // description: name,
                      // mapNav: false,
                    }}
                    to={`wikipedia/${wikiPageId}`}
                  >
                    Learn more on Wikipedia
                  </Link>
                </Center>
              </CardFooter>
            </Card>)}

          <PlaceReviews reviews={reviews}/>

        </Stack>
      </Flex>
      <Box>
        <GoogleAttribution/>
      </Box>
      <TourStopFooter/>
    </>
  );
};

export default TourStopDetails;
