import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {apiPost} from '../api';
import {addRequestCases, requestDefaultState} from './reducerUtils';

const initialTripPlannerForm = {
  budgetDollars: undefined,
  days: undefined,
  includedItems: undefined,
  place: '',
  travelerCount: undefined,
};

const initialTrip = {
  ...requestDefaultState,
  isSpoken: false,
};

const initialToursState = {
  ...requestDefaultState,
  filter: null,
  filterOptions: [
    {emoji: '♾️', label: '♾️ All', value: null},
    {emoji: '😎', label: '😎 My Tours', value: 'createdByUser'},
    {emoji: '💖', label: '💖 My Favorites', value: 'likedByUser'},
  ],
  search: '',
  sort: 'likes',
  sortOptions: [
    // {label: 'Recommended', value: 'recommended'},
    {emoji: '💞', label: '💞 Most Liked', value: 'likes'},
    {emoji: '🕒', label: '🕒 Newest', value: 'newest'},
  ],
};

const initialState = {
  prompt: { // TODO: rename to tour
    ...requestDefaultState,
    isSpoken: false,
  },
  prompts: initialToursState,
  reaction: requestDefaultState,
  tourCreateBalance: requestDefaultState,
  tourSetPanoId: requestDefaultState,
  tourStopCoordinatesSet: requestDefaultState,
  tripPlanner: {
    form: initialTripPlannerForm,
    trip: initialTrip,
  },
};

export const tourCreateRequest = createAsyncThunk(
  'tours/tourCreateRequest',
  async ({
    extraInstructions = '',
    language = 'English',
    place = '',
    prompt = '',
    topic = '',
  }, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourCreate`, {
      extraInstructions,
      language,
      place,
      prompt,
      topic,
    });
    return data;
  },
);

export const tourDeleteRequest = createAsyncThunk(
  'tours/tourDeleteRequest',
  async ({promptId}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourDelete`, {promptId});
    return data;
  },
);

export const tourGetRequest = createAsyncThunk(
  'tours/tourGetRequest',
  async ({promptId}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourGet`, {promptId});
    return data;
  },
);

export const tourReactRequest = createAsyncThunk(
  'tours/tourReactRequest',
  async ({promptId, reaction}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourReact`, {promptId, reaction});
    return data;
  },
);

export const tourSetPanoIdRequest = createAsyncThunk(
  'tours/tourSetPanoId',
  async ({promptId, placeIndex, panoId}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourSetPanoId`, {promptId, placeIndex, panoId});
    return data;
  },
);

export const tourStopCoordinatesSetRequest = createAsyncThunk(
  'tours/tourStopCoordinatesSet',
  async ({promptId, placeIndex, pinCoordinates}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourStopCoordinatesSet`, {promptId, placeIndex, pinCoordinates});
    return data;
  },
);

export const tourListRequest = createAsyncThunk(
  'tours/tourListRequest',
  async ({}, {getState}) => {
    const {tours: {prompts}} = getState();
    const {filter, search, sort} = prompts;
    const {data} = await apiPost(`/ai/tourList`, {
      filter,
      limit: 9,
      page: _.get(prompts, 'response.nextPage') || 1,
      search,
      sort,
    });
    return data;
  },
);

export const tourCreateBalanceRequest = createAsyncThunk(
  'tours/tourCreateBalanceRequest',
  async ({}, thunkAPI) => {
    const {data} = await apiPost(`/ai/tourCreateBalance`, {});
    return data;
  },
);

export const tripCreateRequest = createAsyncThunk(
  'tours/tripCreateRequest',
  async ({}, {getState}) => {
    console.log('createTrip');
    const {form} = getState().tours.tripPlanner;
    console.log('create trip', form);
    const {data} = await apiPost(`/ai/tripCreate`, form);
    return data;
  },
);


export const toursSlice = createSlice({
  name: 'inApp',
  initialState,
  reducers: {
    tripPlannerTripClear: (state, {payload: partialForm}) => {
      state.tripPlanner.trip = initialTrip;
    },
    tripPlannerFormClear: (state, {payload: partialForm}) => {
      state.tripPlanner.form = initialTripPlannerForm;
    },
    tripPlannerFormUpdate: (state, {payload: partialForm}) => {
      Object.assign(state.tripPlanner.form, partialForm);
    },
    toursFilterSet: (state, {payload}) => {
      state.prompts.filter = payload;
      state.prompts.response = null;
    },
    toursSearchSet: (state, {payload}) => {
      state.prompts.search = payload;
      state.prompts.response = null;
    },
    toursSortSet: (state, {payload}) => {
      state.prompts.sort = payload;
      state.prompts.response = null;
    },
    tourIsSpokenSet: (state) => {
      state.prompt.isSpoken = true;
    },
    tripIsSpokenSet: (state) => {
      state.tripPlanner.trip.isSpoken = true;
    },
  },
  extraReducers: (builder) => { // https://redux-toolkit.js.org/api/createAsyncThunk#examples
    addRequestCases(builder, tourCreateBalanceRequest, 'tourCreateBalance');
    addRequestCases(builder, tourCreateRequest, 'prompt', {
      fulfilledReducer: (state, action) => {
        const {prompt} = state;
        prompt.isLoading = false;
        prompt.response = action.payload;
        prompt.isSpoken = false;

        state.prompts = initialToursState;
      }
    });
    addRequestCases(builder, tourDeleteRequest, 'prompt', {
      fulfilledReducer: (state, action) => {
        state.prompt = requestDefaultState;

        const {promptId} = action.meta.arg;
        state.prompts.response.tourPrompts = state.prompts.response.tourPrompts.filter(p => p._id !== promptId);
      }
    });
    addRequestCases(builder, tourGetRequest, 'prompt', {
      fulfilledReducer: (state, action) => {
        const {prompt} = state;
        prompt.isLoading = false;
        prompt.response = action.payload;
        prompt.isSpoken = false;
      }
    });
    addRequestCases(builder, tourListRequest, 'prompts', {
      fulfilledReducer: (state, action) => {
        const {prompts} = state;
        prompts.isLoading = false;

        // response is return val from paginateAggregate with `docs` aliased as `tourPrompts`
        // https://github.com/aravindnc/mongoose-aggregate-paginate-v2#modelaggregatepaginateaggregatequery-options-callback
        prompts.response = {
          ...action.payload,
          tourPrompts: [
            ...action.payload.page === 1 ? [] : _.get(prompts, 'response.tourPrompts', []),
            ...action.payload.tourPrompts,
          ],
        };
      }
    });
    addRequestCases(builder, tourReactRequest, 'reaction', {
      fulfilledReducer: (state, action) => {
        const {reaction} = state;
        reaction.isLoading = false;
        reaction.response = action.payload;

        const {tourPrompt: payloadTourPrompt} = action.payload;

        const idx = state.prompts.response.tourPrompts.findIndex(p => p._id === payloadTourPrompt._id);
        if (idx > -1) {
          state.prompts.response.tourPrompts[idx] = payloadTourPrompt;
        }

        if (state.prompt.response) {
          const {tourPrompt: currentTourPrompt} = state.prompt.response;
          if (currentTourPrompt._id === payloadTourPrompt._id) {
            state.prompt.response.tourPrompt = payloadTourPrompt;
          }
        }
      }
    });
    addRequestCases(builder, tourSetPanoIdRequest, 'tourSetPanoId', {
      fulfilledReducer: (state, action) => {
        state.tourSetPanoId.isLoading = false;
        state.tourSetPanoId.response = action.payload;
        state.prompt.response.completion = action.payload.completion; // TODO: check if we haven't loaded a different one
      }
    });
    addRequestCases(builder, tourStopCoordinatesSetRequest, 'tourStopCoordinatesSet', {
      fulfilledReducer: (state, action) => {
        state.tourStopCoordinatesSet.isLoading = false;
        state.tourStopCoordinatesSet.response = action.payload;
        state.prompt.response.completion = action.payload.completion; // TODO: check if we haven't loaded a different one
      }
    });
    addRequestCases(builder, tripCreateRequest, 'tripPlanner.trip', {
      fulfilledReducer: (state, action) => {
        const {trip} = state.tripPlanner;
        trip.isLoading = false;
        trip.response = action.payload;
        trip.isSpoken = false;
      }
    });
  }
});

export const {
  tourIsSpokenSet,
  toursFilterSet,
  toursSearchSet,
  toursSortSet,
  tripIsSpokenSet,
  tripPlannerFormClear,
  tripPlannerFormUpdate,
  tripPlannerTripClear,
} = toursSlice.actions;

export default toursSlice.reducer;
