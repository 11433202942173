import React from 'react';
import {Route, Routes} from 'react-router-dom';
import GooglePlaceDetails from './GooglePlaceDetails';
import PlacePhotos from './PlacePhotos';

const GooglePlace = () => (
  <Routes>
    <Route path="photos" element={<PlacePhotos/>}/> {/*for autocomplete results*/}
    <Route path="*" element={<GooglePlaceDetails/>}/> {/*for autocomplete results*/}
  </Routes>
);

export default GooglePlace;
