import {createSlice} from '@reduxjs/toolkit';

const textInputDefaultState = {
  textInputField: '',
  textInputPlaceholder: '',
}

export const ttsStatus = {
  idle: 'idle',
  pending: 'pending',
  speaking: 'speaking',
};

const initialState = {
  textToSpeechStatus: ttsStatus.idle,
  txtInputDevConsole: textInputDefaultState,
  txtInputExplore: textInputDefaultState,
  txtInputPlacesInList: textInputDefaultState,
  txtInputPlacesLists: textInputDefaultState,
  txtInputTours: textInputDefaultState,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    textInputFieldClear: (state, {payload: {key}}) => {
      state[key].textInputField = '';
    },
    textInputFieldSet: (state, {payload: {key, val}}) => {
      state[key].textInputField = val;
    },
    textInputPlaceholderSet: (state, {payload: {key, val}}) => {
      state[key].textInputPlaceholder = val;
    },
    textToSpeechStatusSet: (state, {payload}) => {
      state.textToSpeechStatus = payload;
    },
  },
});

export const {
  textInputFieldClear,
  textInputFieldSet,
  textInputPlaceholderSet,
  textToSpeechStatusSet,
} = uiSlice.actions;

export default uiSlice.reducer;
