import {Stack} from '@chakra-ui/react';
import React, {useState} from 'react';
import {BiChild} from 'react-icons/bi';
import {FaChildReaching} from 'react-icons/fa6';
import {GiGiant} from 'react-icons/gi';
import {TbRelationOneToOne} from 'react-icons/tb';
import {useSelector} from 'react-redux';
import ButtonCircleIcon from '../../../components/ButtonCircleIcon';
import {mapScaleSettings, usePermissions} from '../../../hooks/usePermissions';
import {useVuplex} from '../../../hooks/useVuplex';
import {sendMapSnapScale} from '../../../vuplex';
import MinimapScaleSlider from './MinimapScaleSlider';

const MinimapScaleControls = () => {

  const [isTabletop, setIsTabletop] = useState(true);
  const [scaleSnapIndex, setScaleSnapIndex] = useState(-1);

  useVuplex({
    mapNav: ({
      isTabletop,
    }) => {
      setIsTabletop(isTabletop);
    },
    mapScale: ({snapIndex}) => {
      setScaleSnapIndex(snapIndex);
    },
  }, []);

  const isPanoLoaded = !!useSelector(state => state.inApp.panoId);


  return isTabletop
    ? null
    : !isPanoLoaded && (
    <Stack marginX={3} marginY={4}>

      <ButtonCircleIcon
        icon={<GiGiant size="1.5em"/>}
        isSelected={scaleSnapIndex === 3}
        onClick={(e) => {
          sendMapSnapScale({scaleStep: 3});
        }}
        permission={mapScaleSettings}
        shadow="dark-lg"
        tooltip="Scale the Earth 1:500"
      >
      </ButtonCircleIcon>

      <ButtonCircleIcon
        icon={<FaChildReaching size="1.5em"/>}
        isSelected={scaleSnapIndex === 2}
        onClick={(e) => {
          sendMapSnapScale({scaleStep: 2});
        }}
        shadow="dark-lg"
        tooltip="Scale the Earth 1:50"
      />

      <ButtonCircleIcon
        icon={<BiChild size="1.5em"/>}
        isSelected={scaleSnapIndex === 1}
        onClick={(e) => {
          sendMapSnapScale({scaleStep: 1});
        }}
        permission={mapScaleSettings}
        shadow="dark-lg"
        tooltip="Scale the Earth 1:20"
      />

      <ButtonCircleIcon
        icon={<TbRelationOneToOne size="1.5em"/>}
        isSelected={scaleSnapIndex === 0}
        onClick={(e) => {
          sendMapSnapScale({scaleStep: 0});
        }}
        permission={mapScaleSettings}
        shadow="dark-lg"
        tooltip="Scale the Earth 1:1"
      />
    </Stack>
  );

};

export default MinimapScaleControls;
