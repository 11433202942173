import {Button, ButtonGroup, Flex, Menu, MenuButton, MenuItem, MenuList, Text} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import React, {useEffect, useState} from 'react';
import {useMap} from '@vis.gl/react-google-maps';
import {gooodPost} from '../../gooodApi';


// TODO: use this to set the `getClusters` fn that MinimapPinsAndClusters uses
//  ... or replace that module with GooodMapPinsAndClusters


const GooodMapControls = ({demoWorldId, setPins}) => {

  const [activeButton, setActiveButton] = useState('');
  const [mapBounds, setMapBounds] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [currentWITWMapFilter, setCurrentWITWMapFilter] = useState('USA');

  const map = useMap();

  useEffect(() => {
    if (map) {
      if (activeButton === 'publicPins') {
        getPublicPins(map);
      }
      if (activeButton === 'allPins') {
        getAllPins(map);
      }
      if (activeButton === 'witwUSA') {
        getWitwUSAPins(map);
      }
      if (activeButton === 'witwEU') {
        getWitwEUPins(map);
      }
      if (activeButton === 'witwALL') {
        getWitwALLPins(map);
      }
    }
  }, [map, mapBounds, activeButton]);

  const getPublicPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting public pins');
    gooodPost('/pins/clusters', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]}, topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      }, worldId: demoWorldId, //FIXME: Remove need for World ID for Goood API
      zoom: map.getZoom(),
    })
      .then(({data}) => {
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const getAllPins = (map) => {
    //TODO: Get all pins
    setPins([]); //FIXME: Remove this line when getAllPins is implemented
  };

  const getWitwUSAPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting USA witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]}, topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      }, zoom: map.getZoom(), map: 'USA',
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const getWitwEUPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting EU witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]}, topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      }, zoom: map.getZoom(), map: 'EU',
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const getWitwALLPins = (map) => {
    const {bounds} = map.getBounds();
    setIsLoading(true);
    console.log('Getting ALL witw pins');
    gooodPost('/witw-pins', {
      bounds: {
        bottomLeft: {Latitude: bounds[2], Longitude: bounds[3]}, topRight: {Latitude: bounds[0], Longitude: bounds[1]},
      }, zoom: map.getZoom(), map: 'ALL',
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const getRandomWITWPins = () => {
    setIsLoading(true);
    console.log('Getting random witw pins');
    gooodPost('/witw-pins/random', {
      map: currentWITWMapFilter,
    })
      .then(({data}) => {
        console.log(`Got ${data.length} pins`);
        setPins(data);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClick = (button) => {
    if (button === activeButton) {
      setActiveButton('');
      return;
    }
    if (button === 'witwRandom') {
      setActiveButton('');
      getRandomWITWPins();
      return;
    }
    setActiveButton(button);
    console.log(`Active button is now: ${button}`);
  };

  const handleMenuChange = (option) => {
    setCurrentWITWMapFilter(option);
  };


  return (
    <Flex wrap="wrap" justify="space-between">
      <Flex gap={2}>
        <Flex gap={2} alignItems="center" p={2}>
          <Text fontSize="lg" color="blackAlpha.800">Pins </Text>
          <ButtonGroup isDisabled size="sm" isAttached>
            <Button
              colorScheme="blue"
              isActive={activeButton === 'publicPins'}
              onClick={() => handleClick('publicPins')}
            >
              Public
            </Button>
            <Button
              colorScheme="blue"
              isActive={activeButton === 'allPins'}
              onClick={() => handleClick('allPins')}
            >
              All
            </Button>
          </ButtonGroup>
        </Flex>

        <Flex gap={2} alignItems="center" p={2}>
          <Text fontSize="lg" color="blackAlpha.800">WITW </Text>
          <ButtonGroup isDisabled size="sm" isAttached>
            <Button
              colorScheme="pink"
              isActive={activeButton === 'witwUSA'}
              onClick={() => handleClick('witwUSA')}
            >
              USA
            </Button>
            <Button
              colorScheme="pink"
              isActive={activeButton === 'witwEU'}
              onClick={() => handleClick('witwEU')}
            >
              EU
            </Button>
            <Button
              colorScheme="pink"
              isActive={activeButton === 'witwALL'}
              onClick={() => handleClick('witwALL')}
            >
              All
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>

      <Flex gap={2} alignItems="center" p={2}>
        <Text fontSize="lg" color="blackAlpha.800">Random WITW </Text>
        <ButtonGroup isDisabled size="sm" isAttached>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon/>}>
              {currentWITWMapFilter}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleMenuChange('USA')}>USA</MenuItem>
              <MenuItem onClick={() => handleMenuChange('EU')}>EU</MenuItem>
              <MenuItem onClick={() => handleMenuChange('World')}>World</MenuItem>
            </MenuList>
          </Menu>
          <Button colorScheme="pink" isActive={activeButton === 'witwRandom'}
                  onClick={() => handleClick('witwRandom')}>
            WITW Random - {currentWITWMapFilter} Point
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}


export default GooodMapControls;