import {Box, Image, Text} from '@chakra-ui/react';
import React from 'react';

// https://developers.google.com/maps/documentation/places/web-service/policies#logo
const GoogleAttribution = ({hideText = false}) => (
  <Box marginY={4}>
    <Image marginRight={4} src="google_on_white.png" display="inline"/>
    {!hideText && (
      <Text
        as="span"
        fontSize="16px"
        position="relative"
        top="-4px"
      >
        Reviews, some images and place descriptions from Google.
      </Text>)}
  </Box>
);

export default GoogleAttribution;

