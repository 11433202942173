import {Flex} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageTitle from '../WooorldInApp/PageTitle';
import {APIProvider} from '@vis.gl/react-google-maps';
import {MapRenderer} from '../WooorldInApp/Minimap/MapRenderer';
import GooodMapControls from './GooodMapControls';
import {worldSet} from '../../reducers/inAppReducer';


const GooodMap = () => {
  const {googleMapsWooorld, demoWorldId} = useSelector(s => s.goood.auth.response);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!demoWorldId) return;
    dispatch(worldSet({_id: demoWorldId}));
  }, [demoWorldId]);


  const [pins, setPins] = useState([]);

  return (
    <Flex
      w="full"
      h="80vh"
      direction="column"
    >
      <PageTitle title="Map Data - Goood"/>

      <GooodMapControls
        demoWorldId={demoWorldId}
        setPins={setPins}
      />

      <Flex bg="blue" grow={1}>
        <APIProvider apiKey={googleMapsWooorld}>
          <MapRenderer h="full" w="full" />
        </APIProvider>
      </Flex>

      <Flex id="pano" bg="black" w="full" grow={0.5}>

      </Flex>

    </Flex>);
};

export default GooodMap;
