import axios from 'axios';
import {getServerIp} from './helpers/params';
import {sendAuthRequest, sendRefreshTokenRequest} from './vuplex';

const server = getServerIp();
let accessTokenStored;

export const setAccessToken = accessToken => {
  accessTokenStored = accessToken;
};

export const apiPost = async (route, payload, accessToken = accessTokenStored) => {
  if (!accessToken) {
    sendAuthRequest();
    throw new Error('apiPost has no accessToken');
  }
  return axios.post(`${server}/api${route}`, payload, accessToken && {
    headers: {Authorization: `Bearer ${accessToken}`},
  }).catch(async (e) => {
    // console.error(route, 'data:', e.response.data, 'status:', e.response.status, 'statusText:', e.response.statusText);

    sendAuthRequest();

    if (e.response.status === 401) {
      sendRefreshTokenRequest();
      throw new Error(`accessToken expired. refreshing now.`)

      // TODO: auto-retry after token updated
      // const {data: {accessToken}} = await axios.post(`${server}/goood/refresh-token`, {refreshToken})
      //   .catch((e) => {
      //     console.error(`couldn't refresh token.`);
      //     setAccessToken(undefined);
      //   });
      //
      // console.log('saving token, retrying request', accessToken);
      // setAccessToken(accessToken);
      //
      // // retry initial post now that we've refreshed our access token
      // // calling with accessToken, otherwise I don't know how to ensure we use the updated state. setState callbacks not a thing in useState.
      // return gooodPost(route, payload, accessToken);
    }
  });
};
