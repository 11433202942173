import {Button, Card, Flex, Stack} from '@chakra-ui/react';
import React, {useState} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import {sendPanoUnload} from '../../vuplex';
import MapNavAssignButton from './MapNavAssignButton';
import MapNavButton from './MapNavButton';
import PanoButton from './PanoButton';
import PanoSetButton from './PanoSetButton';

const TourStopAttachments = ({}) => {
  const [isEditing, setIsEditing] = useState(false);
  const {promptId, placeIndex: placeIndexParam} = useParams();
  const placeIndex = parseInt(placeIndexParam, 10);
  const {panoId: inAppPanoId} = useSelector(state => state.inApp);

  const {
    completion,
    tourPrompt,
  } = useSelector(({tours}) => tours.prompt.response) || {};
  const tourStop = _.get(completion, `json.places.${placeIndex}`) || {};

  const {
    address,
    coordinates: [{lat, lon}] = [{}],
    description,
    googleCoordinates,
    googlePlace,
    isPanoIdSetByAi,
    isPinCoordinatesSetByAi,
    name,
    panoId,
    pinCoordinates,
    wikiCoordinates,
    wikiPageId,
    wikiSearch,
    wikiSummary,
    wikiThumbnail,
  } = tourStop || {};
  const {isOwnedByUser} = tourPrompt || {};

  return (isOwnedByUser || panoId || pinCoordinates) && (
    <Card
      borderRadius="2xl"
      border="solid 2px white"
      bg="rgba(12, 27, 159, 0.15)"
      p={4}
    >
      <Stack>
        <Flex gap={6} justifyContent="center" w="full">
          {isEditing || (isOwnedByUser && !pinCoordinates)
            ? (
              <MapNavAssignButton
                isEditing={isEditing}
                googleCoordinates={googleCoordinates}
                onSaved={() => setIsEditing(false)}
                pinCoordinates={pinCoordinates}
                placeIndex={placeIndex}
                promptId={promptId}
                wikiCoordinates={wikiCoordinates}
              />)
            : pinCoordinates && (
            <MapNavButton
              isPinCoordinatesSetByAi={isPinCoordinatesSetByAi}
              isPinCoordinatesSetByUser={!isPinCoordinatesSetByAi} // for blue outline
              latitude={pinCoordinates.lat}
              longitude={pinCoordinates.lon}
              name={name}
              onClick={() => {
                if (panoId !== inAppPanoId) sendPanoUnload();
              }}
              size={isOwnedByUser ? 'md' : 'lg'}
              zoom={pinCoordinates.zoom}
            />
          )}

          {isEditing || (isOwnedByUser && !panoId)
            ? (
              <PanoSetButton
                isEditing={isEditing}
                onSaved={() => setIsEditing(false)}
                panoId={panoId}
                placeIndex={placeIndex}
                promptId={promptId}
              />)
            : (
              <PanoButton
                disallowUnload
                isPanoIdSetByAi={isPanoIdSetByAi}
                panoId={panoId}
                size={isOwnedByUser ? 'md' : 'lg'}
                tooltip={`Load this stop's 360`}
              />
            )}
        </Flex>

        {isOwnedByUser && (
          <ButtonMinor
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </ButtonMinor>
        )}
      </Stack>
    </Card>
  );
};

export default TourStopAttachments;
