import {Center} from '@chakra-ui/react';
import React from 'react';
import {FiRefreshCw} from 'react-icons/fi';
import NavButtons from './NavButtons';

const PinsFoldersToggle = () => {

  return (
    <Center p={1}>
      <NavButtons
        linkConfigs={[
          {
            label: 'Places',
            to: 'pins',
          },
          {
            label: 'Lists',
            to: 'folders',
          },
        ]}
      />
    </Center>
  );
};

export default PinsFoldersToggle;
