import {
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Switch
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnterKey} from '../../hooks/useEnterKey';
import {folderEditFormSet, folderEditFormUpdate} from '../../reducers/pinsReducer';
import {sendDragToScroll, sendDragWithinPage} from '../../vuplex';
import PermissionBadge from './PermissionBadge';
import InAppTooltip from './UnityReactComponents/InAppTooltip';

const PinFolderCreateForm = ({}) => {
  const {_id: worldId, rules} = useSelector(s => s.inApp.world);
  const { isPrivate: isPrivateWorld, isSolo: isSoloWorld} = rules || {};
  const folderEditForm = useSelector(s => s.pins.folderEditForm);

  const {
    _id,
    isSolo,
    label,
    sharedInWorld,
  } = folderEditForm;

  const dispatch = useDispatch();

  useEffect(() => () => {
    // clear form on unmount
    dispatch(folderEditFormSet({}));
  }, []);

  useEnterKey(/*blur text field*/);

  const handleCancelClick = () => {
    dispatch(folderEditFormSet({}));
  };

  const canShareInWorld = isSolo && isPrivateWorld && !isSoloWorld;

  return (
    <Stack
      flexShrink={0}
      overflow="hidden"
      p={0}
      w="full"
    >
      <Flex>
        <InputGroup>
          <InputLeftElement>
            <PermissionBadge
              isOwnedByUser
              isPrivate={isSolo}
              isShared={sharedInWorld === worldId}
              size="2em"
            />
          </InputLeftElement>

          <Input
            autoFocus={false}
            placeholder="New folder"
            size="md"
            onChange={({target: {value}}) => {
              dispatch(folderEditFormUpdate({
                label: value,
              }));
            }}
            onPointerEnter={sendDragWithinPage}
            onPointerLeave={sendDragToScroll}
            value={label}
          />
        </InputGroup>
        <CloseButton onClick={handleCancelClick}/>
      </Flex>


      <Flex justifyContent="space-between">
        <FormControl w="auto">
          <FormLabel>Private</FormLabel>
          <Switch
            onChange={({target: {checked}}) => {
              dispatch(folderEditFormUpdate({
                isSolo: checked,
                ...(!checked ? {sharedInWorld: null} : {}),
              }));
            }}
            isChecked={isSolo}
          />
        </FormControl>

        {canShareInWorld && (
          <InAppTooltip
            message={'Share this list and its contents in the current world'}
          >
            <FormControl w="auto">
              <FormLabel>Share in world</FormLabel>
              <Switch
                onChange={({target: {checked}}) => {
                  dispatch(folderEditFormUpdate({
                    sharedInWorld: checked ? worldId : '',
                  }));
                }}
                isChecked={sharedInWorld === worldId}
              />
            </FormControl>
          </InAppTooltip>
        )}
      </Flex>



    </Stack>
  );


};

export default PinFolderCreateForm;
