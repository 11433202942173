import {ButtonGroup, useBreakpointValue} from '@chakra-ui/react';
import React from 'react';
import {useLocation, useNavigate, useResolvedPath} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';

// TODO: https://framerbook.com/animation/example-animations/34-swapping-elements/
const NavButton = ({
  activeBg = 'wooorldBlue._',
  activeBgGradient,
  child,
  label,
  onClick,
  permission,
  to,
  tooltip,
  w = '6em',
  ...others
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let path = useResolvedPath(to, {relative: others.relative});

  /*
ReactRouter's NavLink provides isActive and isPending but wrapping Button interferes
with style props from ButtonGroup. So we'll just snag some logic from NavLink
https://github.com/remix-run/react-router/blob/7ce38dc49ee997706902ac2d033ba1fd683cfed0/packages/react-router-dom/index.tsx#L664C19-L664C19
*/

  // let routerState = useContext(DataRouterStateContext);
  // let { navigator } = useContext(NavigationContext);
  let toPathname = path.pathname;
  // let toPathname = navigator.encodeLocation
  //   ? navigator.encodeLocation(path).pathname
  //   : path.pathname;
  let locationPathname = location.pathname;
  // let nextLocationPathname =
  //   routerState && routerState.navigation && routerState.navigation.location
  //     ? routerState.navigation.location.pathname
  //     : null;

  if (!others.caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    // nextLocationPathname = nextLocationPathname
    //   ? nextLocationPathname.toLowerCase()
    //   : null;
    toPathname = toPathname.toLowerCase();
  }

  let isActive =
    locationPathname === toPathname ||
    (!others.end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/');

  const handleClick = () => {
    navigate(to);
    onClick && onClick();
  };

  return (
    <ButtonMinor
      bg={isActive ? activeBg : 'inactiveGrey'}
      bgGradient={isActive ? activeBgGradient : undefined}
      border={isActive ? `solid 2px #7A7A7A` : 'solid 2px transparent'} // transparent to eliminate a white border flash when clicking
      color="white!important"
      isRound
      onClick={handleClick}
      permission={permission}
      tooltip={tooltip}
      w={w}
    >
      {label}
      {child}
    </ButtonMinor>

    // <Link
    //   as={NavLink}
    //   key={idx}
    //   textDecoration="none!important"
    //   to={to}
    // >
    //   {({isActive}) => (
    //
    //   )}
    // </Link>;
  );

};

const NavButtons = ({linkConfigs = []}) => {
  return (
    <ButtonGroup // https://chakra-ui.com/docs/components/button/props#button-group-props
      bg="inactiveGrey"
      borderRadius="3xl"
      flexWrap="wrap"
    >
      {linkConfigs.map((linkConfig, idx) => (
        <NavButton key={idx} {...linkConfig}/>
      ))}
    </ButtonGroup>
  );
};

export default NavButtons;
