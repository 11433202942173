import _ from 'lodash';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const requestItemsDebounced = _.debounce((dispatch, onRequestPins, searchString) => {
  dispatch(onRequestPins({isNew: true, searchString}));
}, 750, {leading: true});


// noinspection JSCheckFunctionSignatures
const PinsListController = ({
  onClearPins,
  onRequestPins,
  stateKey,
  textInputStateKey,
}) => {
  const dispatch = useDispatch();

  const {textInputField} = useSelector(s => s.ui[textInputStateKey]) || {};

  const {
    error,
    isLoading,
    response: searchResponse,
  } = useSelector(s => s.pins[stateKey]);

  // Fetch results with current filter/sort settings anytime results are empty
  useEffect(() => {
    if (!searchResponse && !isLoading && !error) {
      dispatch(onRequestPins({
        isNew: true,
        searchString: textInputField
      }));
    }
  }, [searchResponse, textInputField, isLoading, error]);

  // Fetch results while typing only after results are non-empty
  useEffect(() => { // TODO: useDebounce
    if (textInputField === undefined) return;
    if (!searchResponse) return;

    if (textInputField.length > 0) {
      requestItemsDebounced(dispatch, onRequestPins, textInputField);
    } else {
      requestItemsDebounced.cancel();
      dispatch(onClearPins());
    }
  }, [textInputField]);

  return null;
};

export default PinsListController;
