import {Avatar, Flex} from '@chakra-ui/react';
import {ControlPosition, MapControl} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useVuplex} from '../../../hooks/useVuplex';
import {sendAudioClick, sendInWorldUsersListRequest} from '../../../vuplex';
import InAppTooltip from '../UnityReactComponents/InAppTooltip';
import MinimapInWorldUserMarker from './MinimapInWorldUserMarker';
import MinimapInWorldUserToast from './Toast/MinimapInWorldUserToast';

const MinimapInWorldUsers = ({onProxyClick}) => {

  const [selectedUser, setSelectedUser] = useState(null);
  const [inWorldUsers, setInWorldUsers] = useState([]);
  const [userInViewStatuses, setUserInViewStatuses] = useState({});
  const realtimeUserData = useRef({});

  useEffect(() => {
    sendInWorldUsersListRequest();
    console.log('inWorldUsersListRequest');
  }, []);

  useVuplex({
    inWorldUser: (data) => {
      realtimeUserData.current[data.userId] = data;
    },
    inWorldUsersList: (data) => {
      setInWorldUsers(data);
      realtimeUserData.current = _.fromPairs(data.map(u => [u.userId, u]));
      console.log('current', realtimeUserData.current);
    },
  }, []);

  // TODO: this may be inaccurate at first as it only handles InViewChange and maybe not initial state
  const handleInViewChange = (userId) => (isInView, inViewEntry) => {
    const {rootBounds, boundingClientRect} = inViewEntry;
    const {left, right, top, bottom} = rootBounds || {};
    const {x, y} = boundingClientRect || {};


    setUserInViewStatuses(prevOutOfViewUsers => ({
      ...prevOutOfViewUsers,
      [userId]: {isInView, mapPos: {left, right, top, bottom, x, y}},
    }));
  };

  return (
    <>
      {inWorldUsers.map(inWorldUser => (
        <MinimapInWorldUserMarker
          key={inWorldUser.userId}
          onInViewChange={handleInViewChange(inWorldUser.userId)}
          {...inWorldUser}
          onClick={() => {
            setSelectedUser({
              ...realtimeUserData.current[inWorldUser.userId],
              isInView: _.get(userInViewStatuses[inWorldUser.userId], 'isInView'),
            });
          }}
        />
      ))}
      <MapControl position={ControlPosition.TOP_CENTER}>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          justifyContent="center"
          p={3}
          w="35vw"
        >
          {inWorldUsers.filter(u => userInViewStatuses[u.userId] && !userInViewStatuses[u.userId].isInView)
            .map(u => (
              <InAppTooltip key={u.userId} message={u.username}>
                <Avatar
                  bg={u.color}
                  border="solid white 2px"
                  color="white"
                  key={u.userId}
                  name={u.username}
                  onClick={(e) => {
                    sendAudioClick(e);
                    // onProxyClick(realtimeUserData.current[u.userId]);
                    setSelectedUser(realtimeUserData.current[u.userId]);
                  }}
                  shadow="dark-lg"
                  size="sm"
                />
              </InAppTooltip>)
            )}
        </Flex>

      </MapControl>


      <MinimapInWorldUserToast
        {...selectedUser}
        onClose={() => setSelectedUser(null)}
        onCenterMapClick={() => onProxyClick(realtimeUserData.current[selectedUser.userId])}
      />
    </>

  );
};

export default MinimapInWorldUsers;
