import {Badge, Center} from '@chakra-ui/react';
import NavButtons from './NavButtons';

const TourTripToggle = () => {
  return (
    <Center p={4} marginTop={1}>
      <NavButtons linkConfigs={[
        {
          label: 'Create a tour',
          to: '/wooorld/tablet/tours',
        },
        {
          child: (
            <Badge
              bg="betaGrey"
              position="absolute"
              right="0.5em"
              top="-1.4em"
              variant="solid"
            >
              coming soon
            </Badge>),
          isDisabled: true,
          label: 'Plan a trip',
          to: '/wooorld/tablet/tripPlanner',
        },
      ]}/>
    </Center>);
};

export default TourTripToggle;
