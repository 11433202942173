import {AbsoluteCenter, Badge, Box, Center} from '@chakra-ui/react';
import {AdvancedMarker, useAdvancedMarkerRef} from '@vis.gl/react-google-maps';
import React from 'react';
import {MdLocationPin} from 'react-icons/md';
import InAppWebPinActionMarker from '../../../assets/InAppWebPinActionMarker.png';
import {sendActionPanelRequest, sendAudioClick} from '../../../vuplex';
import InAppMapPin from '../UnityReactComponents/InAppMapPin';

const MinimapActionMarker = ({isDisabled, latitude, longitude, onClick, placeId}) => {
  const [refCallback, marker] = useAdvancedMarkerRef();

  const color = '#D20';

  return (
    <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
      position={{lat: latitude, lng: longitude}}
      // onClick={(e) => {
      //   if (isDisabled) return;
      //   sendAudioClick(e);
      //   onClick();
      // }}
      ref={refCallback}
    >
      <Box
        position="relative"
        height={0}
        width={0}
        // bg={'magenta'}
        // left=".1em"
        top="-1.15em"
      >
        <AbsoluteCenter>
          <MdLocationPin
            color={color}
            size="3em"
          />
        </AbsoluteCenter>

        {/*{!isDisabled && (*/}
        {/*  <Center>*/}
        {/*    <Badge*/}
        {/*      color={color}*/}
        {/*      position="absolute"*/}
        {/*      top="1em"*/}
        {/*    >*/}
        {/*      Go here*/}
        {/*    </Badge>*/}
        {/*  </Center>*/}
        {/*)}*/}
      </Box>

      <InAppMapPin
        color={color}
        label=""
        latitude={latitude}
        longitude={longitude}
        iconUrl={`${window.location.origin}${InAppWebPinActionMarker}`}
        onClick={() => {
          sendActionPanelRequest({
            latitude,
            longitude,
            placeId,
            skipReverseGeocode: true,
          })
        }}
      />
    </AdvancedMarker>
  );
};

export default MinimapActionMarker;
