import {AbsoluteCenter, Badge, IconButton} from '@chakra-ui/react';
import React from 'react';

const PanoClusterIconSvg = ({onClick, size}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}  viewBox="0 0 48 48">
    <defs>
      <linearGradient
        id="linear-gradient-pano-cluster-icon"
        x1="24"
        x2="24"
        y1="17.25"
        y2="0.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" stopOpacity="0.9"></stop>
        <stop offset="1" stopColor="#fff" stopOpacity="0.1"></stop>
      </linearGradient>
      <linearGradient
        id="linear-gradient-pano-cluster-icon-2"
        x1="24"
        x2="24"
        y1="15.9"
        y2="2.36"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#666" stopOpacity="0.9"></stop>
        <stop offset="1" stopColor="#666" stopOpacity="0.1"></stop>
      </linearGradient>
    </defs>
    <ellipse cx="24" cy="24" opacity="0.2" rx="2.25" ry="0.75"></ellipse>
    <path
      fill="url(#linear-gradient-pano-cluster-icon)"
      d="M24 17.25c-2.71 0-5.16-1.28-6.7-3.5a8.206 8.206 0 010-9.33c1.55-2.23 3.99-3.5 6.7-3.5s5.16 1.28 6.71 3.5a8.206 8.206 0 010 9.33c-1.55 2.23-3.99 3.5-6.71 3.5z"
    ></path>
    <path
      fill="#666"
      d="M24 1.27c2.6 0 4.93 1.22 6.42 3.35a7.843 7.843 0 010 8.93C28.94 15.68 26.6 16.9 24 16.9s-4.93-1.22-6.42-3.35a7.843 7.843 0 010-8.93C19.06 2.49 21.4 1.27 24 1.27m0-.7c-2.65 0-5.3 1.22-6.99 3.65a8.518 8.518 0 000 9.72c1.69 2.44 4.34 3.65 6.99 3.65s5.3-1.22 6.99-3.65a8.518 8.518 0 000-9.72C29.3 1.78 26.65.57 24 .57z"
    ></path>
    <circle
      cx="24"
      cy="9.13"
      r="6.77"
      fill="url(#linear-gradient-pano-cluster-icon-2)"
      onClick={onClick}
      pointerEvents="all"
    ></circle>
    <g fill="#fff">
      <path d="M24.77 6.55l-2.81 4.87c-.25.44.06.98.57.98h5.63c.5 0 .82-.55.57-.98l-2.81-4.87a.657.657 0 00-1.14 0z"></path>
      <path d="M21.04 8.68l-1.79 3.1c-.16.28.04.63.36.63h3.58c.32 0 .52-.35.36-.63l-1.79-3.1a.414.414 0 00-.72 0z"></path>
    </g>
    <circle cx="20.81" cy="5.88" r="0.6" fill="#fff"></circle>
  </svg>
);

const PanoClusterIcon = ({count, onClick, size}) => (
  <>
    <IconButton
      _hover={{bg: 'none'}}
      bg="none"
      icon={<PanoClusterIconSvg onClick={onClick} size={size}/>}
    />
    <AbsoluteCenter top="0.85em" pointerEvents="none">
      <Badge bg="#666" borderRadius="full" color="#fff" fontSize="1em" fontWeight="normal" paddingX={2} lineHeight="1.2em">
        {count}
      </Badge>
    </AbsoluteCenter>
  </>);

export default PanoClusterIcon;
