import {AbsoluteCenter, Avatar, Box} from '@chakra-ui/react';
import {AdvancedMarker, useAdvancedMarkerRef, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import _ from 'lodash';
import React, {useRef, useState} from 'react';
import {GoDotFill} from 'react-icons/go';
import {InView} from 'react-intersection-observer';
import {useSelector} from 'react-redux';
import {useVuplex} from '../../../hooks/useVuplex';
import {sendAudioClick, sendNavToUser} from '../../../vuplex';
import InAppTooltip from '../UnityReactComponents/InAppTooltip';

const MinimapInWorldUserMarker = ({
  color,
  onClick,
  onInViewChange,
  userId,
  username,
}) => {
  const [refCallback, marker] = useAdvancedMarkerRef();
  const [icon, setIcon] = useState(null);
  const [panoId, setPanoId] = useState(null);
  const localPanoId = useSelector(s => s.inApp.panoId);
  const map = useMap();
  const realtimeUserData = useRef({});

  const [laserRefCallback, laserMarker] = useAdvancedMarkerRef();
  const [showLaserMarker, setShowLaserMarker] = useState(false);
  const maps = useMapsLibrary('maps');
  const beamRef = useRef();
  const timeoutId = useRef();


  useVuplex({
    inWorldUser: (data) => {
      if (data.userId !== userId) return;

      if (panoId !== data.panoId) {
        setPanoId(data.panoId);
      }

      realtimeUserData.current = data;

      const {
        avatarLatitude,
        avatarLongitude,

        laserIsOn,
        laserLatitude,
        laserLongitude,
      } = data;

      if (!marker || !laserMarker || !map || !maps) return;


      // console.log(marker.position);
      marker.position = {
        lat: avatarLatitude,
        lng: avatarLongitude,
      };

      // const style = _.get(icon, 'firstChild.style');
      // if (!style) {
      //   console.warn('unexpected marker dom structure');
      //   return;
      // }
      // style.color = color;
      //
      // style.transform = `rotate(${-90 + headingDegrees}deg)`;




      if (laserIsOn) {

        if (!timeoutId.current) {
          setShowLaserMarker(true);
          if (beamRef.current) beamRef.current.setMap(map);
        }
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
          setShowLaserMarker(false);
          timeoutId.current = null;
          if (beamRef.current) beamRef.current.setMap(null);
        }, 1000);

        const laserPosition = {lat: laserLatitude, lng: laserLongitude};
        laserMarker.position = laserPosition;

        if (!beamRef.current) {
          beamRef.current = new maps.Polyline({
            strokeColor: color,
            strokeOpacity: 0.5,
            strokeWeight: 2,
            geodesic: true,
            map: map,
          });
        }

        beamRef.current.setPath([
          {
            lat: avatarLatitude,
            lng: avatarLongitude,
          },
          laserPosition,
        ]);

        beamRef.current.strokeColor = color;

        const style = _.get(laserMarker, 'element.firstChild.firstChild.style');
        if (!style) {
          console.warn('unexpected marker dom structure');
          return;
        }
        style.color = color;
      }



    }
  }, [
    icon,
    laserMarker,
    map,
    maps,
    marker,
    panoId,
  ]);

  const [isSelected, setIsSelected] = useState(false);

  const isViewingDifferentPano = panoId && localPanoId !== panoId;

  return (
    <>
      <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
        onClick={(e) => {
          sendAudioClick(e);
          e.stop();
          onClick();
          console.log(realtimeUserData.current)
        }}
        ref={refCallback}
      >
        <InView onChange={onInViewChange}>
          <AbsoluteCenter>
            <InAppTooltip message={username}>
              <Avatar
                bg={color}
                border="solid white 2px"
                color="white"
                name={username}
                shadow="dark-lg"
                size="sm"
              />
            </InAppTooltip>
          </AbsoluteCenter>
        </InView>

        {/*<AbsoluteCenter>*/}
        {/*  <ButtonMinor*/}
        {/*    color={color}*/}
        {/*    h={8}*/}
        {/*    p={2}*/}
        {/*    position="relative"*/}
        {/*    top="3em"*/}
        {/*  >*/}
        {/*    {isSelected*/}
        {/*      ? isViewingDifferentPano*/}
        {/*        ? 'Go to their 360'*/}
        {/*        : 'Go to them'*/}
        {/*      : username}*/}
        {/*    {isViewingDifferentPano && <MdVrpano size="2em"/>}*/}
        {/*  </ButtonMinor>*/}
        {/*</AbsoluteCenter>*/}
      </AdvancedMarker>


      {/* Ripped from MinimapLaserPointerMarker */}
      <AdvancedMarker // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
        onClick={(e) => {}}
        ref={laserRefCallback}
      >
        <AbsoluteCenter>
          {showLaserMarker && <GoDotFill size="2em"/>}
        </AbsoluteCenter>
      </AdvancedMarker>

    </>
  );
};

export default MinimapInWorldUserMarker;
