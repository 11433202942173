import {AbsoluteCenter, Avatar, Heading} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

const TripHeader = () => {
  const {promptId} = useParams();
  const {
    trip: {
      isLoading,
      response,
    },
  } = useSelector(({tours}) => tours.tripPlanner);

  const {
    completion: {
      json: {
        prompt,
        days = [],
      } = {},
      rawText: completionText,
      tokensCompletion,
      tokensPrompt,
      tokensTotal,
    },
    tripPrompt: {
      alias,
      createdOn,
      gptPrompt,
      _id: tripPromptId,
      promptInterpreted,
      ownerId,
      profilePhotoURL,
      tags,
    },
  } = response || {completion: {}, tripPrompt: {}};

  return (
    <>
      <AbsoluteCenter top={0}>
        <Avatar
          name={alias}
          src={profilePhotoURL}
          size="sm"
        />
      </AbsoluteCenter>
      <Heading
        align="center"
        as="h2"
        fontWeight={400}
        size="sm"
        marginTop="1rem"
      >
        ⚡ Your itinerary is ready! 🎉
      </Heading>
    </>

  );
};

export default TripHeader;
