import {Center, Flex, Spacer, StackDivider, Tag, Text} from '@chakra-ui/react';
import React from 'react';
import GenericCard from './GenericCard';

const GooglePlaceCard = ({place}) => {
  const {
    geometry: {
      location: {lat, lng},
      // viewport: { northeast: {lat,lng}, southwest: {lat,lng} }
    },
    icon,
    icon_background_color,
    name,
    photoUrl, // added by Cloooud API
    place_id: placeId,
    types,
    types: [placeType],
    user_ratings_total,
    vicinity,
  } = place;

  return (
    <GenericCard
      backgroundColor="whiteAlpha.600"
      backgroundColorHeader={icon_background_color}
      description={<>
        <Text fontSize="xs">{vicinity}</Text>
        {/*<Flex direction="row" wrap="wrap">*/}
        {/*  {types.map(t => <Tag fontSize="0.5em" p="0.5em" m="0.25em" key={t} variant="subtle">{t.replaceAll('_', ' ')}</Tag>)}*/}
        {/*</Flex>*/}
      </>}
      detailsPath={`./${placeId}`}
      iconUrl={icon}
      imageFit="contain"
      latitude={lat}
      longitude={lng}
      name={name}
      photoUrl={icon/*photoUrl*/}
      pinTooltip="Google Pin <br> Only visible to you"
      placeType={placeType}
    />
  );
};

export default GooglePlaceCard;
