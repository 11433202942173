import {Stack} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEnterKey} from '../../hooks/useEnterKey';
import {autoCompleteClear, autoCompleteRequest} from '../../reducers/placesReducer';
import PlaceAutoCompleteItem from './PlaceAutoCompleteItem';

const autoCompleteDebounced = _.debounce(({
  dispatch,
  input,
  latitude,
  longitude,
  radiusMeters,
}) => {
  dispatch(autoCompleteRequest({
    input,
    latitude,
    longitude,
    radiusMeters, // used only to weight results to vicinity, doesn't exclude outside
  }));
}, 500);

const GooglePlacesAutoComplete = ({}) => {
  const autoCompleteResults = useSelector(({places}) => places.autoComplete.response) || [];
  const dispatch = useDispatch();
  const keyword = useSelector(({ui}) => ui.txtInputExplore.textInputField);

  const {
    center: {
      latitude, longitude,
    },
    radiusKm,
  } = useSelector(state => state.inApp.mapLocation);

  useEffect(() => {
    if (_.isEmpty(keyword)) {
      dispatch(autoCompleteClear());
      autoCompleteDebounced.cancel();
      return;
    }

    autoCompleteDebounced({
      dispatch,
      input: keyword,
      latitude,
      longitude,
      radiusMeters: Math.min(50000, Math.floor(radiusKm * 1000)),
    });
  }, [keyword]);

  useEnterKey(/*blur textInput*/); // TODO: nav to first result?

  const missingLocation = radiusKm === 0 && latitude === 0 && longitude === 0;
  const canExplore = !missingLocation && radiusKm <= 50;

  return (
    <Stack alignItems="center">
      {autoCompleteResults.map((autoCompleteItem, idx) =>
        <PlaceAutoCompleteItem {...autoCompleteItem} key={idx}/>)}
    </Stack>
  );

};

export default GooglePlacesAutoComplete;
