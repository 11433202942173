import {noop} from 'lodash';
import {useEffect} from 'react';

export const useEnterKey = (callback = noop) => {
  useEffect(() => {
    const onKeydown = ({key}) => {
      if (key === 'Enter') {
        callback();
        document.activeElement.blur();
      }
    };
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [callback]);
};
