import {Button, Flex, Text, useToast} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useResolvedPath} from 'react-router-dom';
import ButtonMinor from '../../components/ButtonMinor';
import OptionsMenu from '../../components/OptionsMenu';
import {
  folderDeleteRequest,
  folderEditFormSaveRequest,
  folderEditFormSet,
  folderEditFormUpdate
} from '../../reducers/pinsReducer';
import {sendAudioClick, sendAudioHover} from '../../vuplex';

const PinFolderOptionsMenu = ({
  folder,
})=> {
  const {
    _id,
    isSolo,
    label,
    sharedInWorld,
  } = folder;
  const {_id: worldId, rules} = useSelector(s => s.inApp.world) || {};
  const {isPrivate: isPrivateWorld, isSolo: isSoloWorld} = rules || {};

  const {pathname} = useResolvedPath('.');
  const isPinEditFormOpen = pathname.endsWith('/edit'); // FIXME hack - not guaranteed to work everywhere
  const [isDeleteMenuOpen, setIsDeleteMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const shareInWorld = async () => {
    dispatch(folderEditFormSet(folder));
    dispatch(folderEditFormUpdate({
      sharedInWorld: worldId,
    }));

    const {error} = await dispatch(folderEditFormSaveRequest());
    toast(error
      ? {
        description: `"${label}" could not be shared. Please try again.`,
        status: 'error',
        title: 'Failed to share list.',
      }
      : {
        description: `"${label}" has been shared in the current world.`,
        status: 'success',
        title: 'List shared.',
      });

    dispatch(folderEditFormSet({}));
  };

  return (
    <Flex p={1}>
      {isDeleteMenuOpen && (
        <OptionsMenu isOpenPermanently>
          <Text>Delete "{label}"</Text>
          <ButtonMinor
            colorScheme="wooorldBlue"
            justifyContent="left"
            onClick={async () => {
              const res = await dispatch(folderDeleteRequest({
                folderId: _id,
                deletePins: false,
              }));

              if (res.error)
                toast({
                  status: 'error',
                  description: 'Failed to delete folder'
                });
              else
                toast({
                  status: 'success',
                  description: `Deleted folder and moved pins to "${res.payload.label}"`
                });
            }}
            position="relative"
            size="sm"
          >
            📍🤗 delete folder but keep pins
          </ButtonMinor>

          <ButtonMinor
            colorScheme="red"
            justifyContent="left"
            onClick={async () => {
              const res = await dispatch(folderDeleteRequest({
                folderId: _id,
                deletePins: true,
              }));

              if (res.error)
                toast({
                  status: 'error',
                  description: 'Failed to delete folder'
                });
              else
                toast({
                  status: 'success',
                  description: 'Deleted folder and pins'
                });
            }}
            position="relative"
            size="sm"
          >
            📍❌ delete folder and pins
          </ButtonMinor>
          <ButtonMinor
            justifyContent="left"
            onClick={() => {
              setIsDeleteMenuOpen(false);
            }}
            position="relative"
            size="sm"
          >
            cancel
          </ButtonMinor>
        </OptionsMenu>
      )}

      {!isDeleteMenuOpen && (
        <OptionsMenu>
          {isSolo && sharedInWorld !== worldId && isPrivateWorld && !isSoloWorld && (
            <ButtonMinor
              justifyContent="left"
              onClick={() => {
                shareInWorld();
              }}
              position="relative"
              size="sm"
            >
              🤝 share in current world
            </ButtonMinor>
          )}
          <ButtonMinor
            justifyContent="left"
            onClick={(e) => {
              dispatch(folderEditFormSet(folder));
              if (!isPinEditFormOpen) navigate('folderEdit');
            }}
            position="relative"
            size="sm"
          >
            🖊️ edit
          </ButtonMinor>
          <ButtonMinor
            justifyContent="left"
            onClick={() => {
              setIsDeleteMenuOpen(true);
            }}
            position="relative"
            size="sm"
          >
            ❌ delete
          </ButtonMinor>
        </OptionsMenu>)}
    </Flex>
  )
}

export default PinFolderOptionsMenu;
