import {Heading} from '@chakra-ui/react';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BackButton from '../../components/BackButton';
import {textToSpeechStatusSet} from '../../reducers/uiReducer';
import {sendTextToSpeech} from '../../vuplex';

const GooglePlaceHeader = ({}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {response: details, isLoading} = useSelector(({places}) => places.details);
  const dispatch = useDispatch();

  const speak = (text) => {
    dispatch(textToSpeechStatusSet('pending'));
    sendTextToSpeech(text);
  };

  useEffect(() => {
    _.defer(() => {
      if (!isLoading) setIsLoaded(true);
    });
  }, [isLoading]);

  const {
    editorial_summary: {overview} = {},
    formatted_address: address,
    geometry: {
      location: {lat, lng},
      // viewport: {
      //   northeast: {
      //     lat: 48.86355484999999,
      //     lng: 2.342908599999999,
      //   },
      //   southwest: {
      //     lat: 48.85824504999999, lng: 2.330669800000001,
      //   },
      // }
    } = {location: {}},
    icon,
    icon_background_color,
    name,
    photos = [],
    reviews = [],
    types: [placeType, /* there may be more than 1 */] = [],
    types = [],
  }
    = details || {};

  return !isLoading && (
    <>
      <BackButton/>
      <Heading p={2} size="sm" onClick={() => speak(name)}>Google Search / {name}</Heading>
    </>

  );
};

export default GooglePlaceHeader;
