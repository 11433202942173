import {
  Alert,
  AlertIcon,
  Button,
  chakra,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  shouldForwardProp,
  Stack
} from '@chakra-ui/react';
import _ from 'lodash';
import {isValidMotionProp, motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import {HiBolt} from 'react-icons/hi2';
import {useSelector} from 'react-redux';
import {Magic} from '../../helpers/audioClips';
import {useEnterKey} from '../../hooks/useEnterKey';
import {sendAudioClip} from '../../vuplex';
import {Jelly, JellyTriangle, LeapFrog, NewtonsCradle, Pulsar, ThreeBody} from '@uiball/loaders';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});


const CreateCompletionButton = ({
  label,
  onClick,
  requestState,
}) => {

  const {error, isLoading, response} = requestState;
  // const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false);

  const Loader = _.sample([Jelly, JellyTriangle, LeapFrog, NewtonsCradle, Pulsar, ThreeBody]);

  const {
    tourCreateBalance = 0,
  } = useSelector(s => s.tours.tourCreateBalance.response) || {};

  const isDisabled = tourCreateBalance <= 0;

  const handleClick = ({clientX, clientY} = {}) => {
    if (isDisabled) return;

    sendAudioClip({
      clientX, clientY,
      clip: Magic,
    });

    onClick().then(({error}) => {
      if (!error) setIsDone(true);
    });
  };

  useEnterKey(handleClick);

  return (
    <Stack alignItems="center" w="full">
      <Alert borderRadius="2xl" colorScheme="gray" m={4} status="warning" w="30em">
        <AlertIcon/>
        Remember, tours are public. Don't include personal information or inappropriate prompts.
      </Alert>

      <FormControl textAlign="center" isInvalid={!!error}>
        <Container display="flex" alignItems="center" justifyContent="center">
          <ChakraBox
            // onClick={() =>                 setIsLoading(!isLoading)
            // }
            border={isLoading ? '3px solid' : 'none'}
            animate={isLoading ? {
              borderColor: ['hsl(180, 50, 90)', 'hsl(350, 50, 90)', 'hsl(180, 50, 90)', 'hsl(0, 50, 90)', 'hsl(180, 50, 90)'],
              background: ['linear-gradient(90deg, #33bbff, #FF85A2)', 'linear-gradient(90deg, #FF85A2, #33bbff)', 'linear-gradient(90deg, #33bbff, #FF85A2)'],
              scale: [1, 1.1, 1, 1, 1.2, 1],
              borderRadius: ['1.5rem', '1.8rem', '1.5rem'],
            } : {}}
            transition={isLoading ? {
              duration: 10,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'mirror',
            } : {}}
            p={0}
            marginBottom={4}
            overflow="hidden"
            zIndex="sticky"
            // bgGradient="linear(to-l, #7928CA, #FF0080)"
            // justifyContent="center"
            // width="100px"
            // height="100px"
          >
            <Button
              bg={isLoading ? 'none' : isDone ? '#FF85A2' : null}
              borderRadius={isLoading ? 0 : '3xl'}
              colorScheme="wooorldBlue"
              isDisabled={isDisabled}
              isLoading={isLoading}
              spinner={
                <Loader
                  size={60}
                  speed={3}
                  color="white"
                />
              }
              fontSize="3em"
              p="1em"
              leftIcon={<HiBolt/>}
              onClick={handleClick}
              variant="solid"
            >
              {isDone ? 'Here we go!' : label}
            </Button>
          </ChakraBox>
        </Container>

        <FormErrorMessage justifyContent="center">{error && 'Something went wrong. Please try again.'}</FormErrorMessage>
        {isLoading && <FormHelperText>This may take up to 30 seconds.</FormHelperText>}
        <FormHelperText textColor={tourCreateBalance === 0 ? 'darkred' : 'grey'}>
          {tourCreateBalance} tours remaining this month
        </FormHelperText>
      </FormControl>
    </Stack>
  );
};

export default CreateCompletionButton;
