import {Box, Heading} from '@chakra-ui/react';
import React from 'react';
import GooglePlaces from './GooglePlaces';
import Item from '../../components/Item';

const BigScreen = ({}) => {

  return (
    <Item
      renderTitle={() => `Shared Content`}
      renderBody={() => (
        <div className="broadcast">
          <GooglePlaces />
        </div>)}
    />);
};

export default BigScreen;
